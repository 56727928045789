import React, { useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../models/User";
import Icon from "../Icon";
import { useAuth } from "../../contexts/AuthContext";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import FailureModal from "../FailureModal";
import ActionModal from "../ActionModal";
import CommonContainer from "../Common/CommonContainer";
import { CommonCard } from "../Common/CommonCard";
import { CommonTypedConfirmationModal } from "../Common/CommonTypedConfirmationModal";
import { useDebounce } from "../../utils/Debounce";
import { formatDate, formatTime } from "../../utils/DateUtils";

interface EditProfileProps {
  currentUser: User | undefined;
}

const EditProfile: React.FC<EditProfileProps> = ({ currentUser }) => {
  const { teamId } = useParams<{ teamId: string }>();

  const auth = useAuth();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [savePasswordState, setSavePasswordState] =
    useState<AnimationState>("ready");
  const [signOutAnimationState, setSignOutAnimationState] =
    useState<AnimationState>("ready");

  const [error, setError] = useState("");
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [deletionModalButtonState, setDeletionModalButtonState] =
    useState<AnimationState>("ready");
  const [showSignOut, setShowSignOut] = useState(false);

  const [validPasswordLength, setValidPasswordLength] = useState(true);
  const [validPasswordUppercase, setValidPasswordUppercase] = useState(true);
  const [validPasswordLowercase, setValidPasswordLowercase] = useState(true);
  const [validPasswordCharacter, setValidPasswordCharacter] = useState(true);
  const [validPasswordConfirmed, setValidPasswordConfirmed] = useState(true);

  const [user, setUser] = useState<User | undefined>(currentUser);

  const validatePassword = async (
    newPasswordIn?: string,
    confirmNewPasswordIn?: string
  ) => {
    const newPasswordResult = newPasswordIn ?? newPassword;
    const confirmNewPasswordResult = confirmNewPasswordIn ?? confirmNewPassword;

    setValidPasswordLength(true);
    setValidPasswordUppercase(true);
    setValidPasswordLowercase(true);
    setValidPasswordCharacter(true);
    setValidPasswordConfirmed(newPasswordResult == confirmNewPasswordResult);

    const result = await auth.validatePassword(newPasswordResult);

    result.forEach((result) => {
      if (result.includes("uppercase")) {
        setValidPasswordUppercase(false);
      } else if (result.includes("lowercase")) {
        setValidPasswordLowercase(false);
      } else if (result.includes("Password too")) {
        setValidPasswordLength(false);
      } else if (result.includes("special character")) {
        setValidPasswordCharacter(false);
      }
    });
  };

  const handleUpdateNewPassword = async (newPassword: string) => {
    setNewPassword(newPassword);
    await validatePassword(newPassword, undefined);
  };

  const handleUpdateConfirmNewPassword = async (confirmNewPassword: string) => {
    setConfirmNewPassword(confirmNewPassword);
    await validatePassword(undefined, confirmNewPassword);
  };

  const handleSavePassword = async () => {
    try {
      setSavePasswordState("loading");
      await auth.changePassword(currentPassword, newPassword);
      setSavePasswordState("success");
    } catch (e) {
      setSavePasswordState("ready");

      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  const handleDeleteAccount = async (password?: string) => {
    setDeletionModalButtonState("loading");
    try {
      if (password) {
        await auth.signIn(currentUser?.email ?? "", password);
        await auth.deleteAccount(password);
        setDeletionModalButtonState("ready");
      } else {
        await auth.deleteAccount(password);
      }
      navigate("/bye");
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
      setDeletionModalButtonState("error");
    }
  };

  const handleSetUser = (user: User) => {
    const updatedUser: User = { ...user, modifiedAt: new Date() };
    setUser(updatedUser);
    handleSave(updatedUser);
  };

  const handleSave = useDebounce(async (user: Partial<User>) => {
    try {
      await auth.userRepo.update(user, auth.userPath(), auth.userId!);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("failed to save");
      }
    }
  }, 2000);

  const handleSignOut = async () => {
    try {
      setSignOutAnimationState("loading");
      await auth.signOut();
      navigate("/bye");

      setSignOutAnimationState("success");
    } catch (e) {
      setSignOutAnimationState("ready");

      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <CommonContainer>
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to Edit Profile"}
        shows={error != ""}
        closed={() => setError("")}
      />
      <CommonTypedConfirmationModal
        title="Are you sure you want to delete your account?"
        isOpen={showDeleteAccount}
        message={`This is forever, no backsies!`}
        onCancel={() => setShowDeleteAccount(false)}
        onConfirm={(password) => handleDeleteAccount(password)}
        confirmStyle="destructive"
        confirmationPhrase="password"
        requireMatch={false}
        isSecure={true}
        hideText={currentUser?.authProvider != "email"}
        confirmationState={deletionModalButtonState}
      />
      <ActionModal
        title={"Are you sure you wanna sign out?"}
        message={`We'll miss you dearly`}
        backButtonTitle={"Back to Safety"}
        shows={showSignOut}
        closed={() => setShowSignOut(false)}
        action={() => handleSignOut()}
        iconType="close"
        actionTitle="Sign Out"
        style="normal"
      />
      <CommonHeader
        title={`Your Profile`}
        subtitle="Don't be too funny, your team will see your name."
        sections={[{ name: "Profile", link: "/" }]}
        teamId={teamId!}
        actions={[]}
      />
      <div className="flex flex-col gap-4 pt-4">
        <CommonCard padding="sm">
          <InputSegment
            title="Name"
            onChange={(name) => handleSetUser({ ...user!, name })}
            value={user?.name ?? ""}
            placeholder="Your Name"
          />
          <InputSegment
            title="Email"
            onChange={() => console.log()}
            value={user?.email ?? ""}
            disabled={true}
            placeholder="Your Email"
          />
          <div className="flex flex-row text-xs text-gray-400 gap-1 justify-end">
            <span>Last saved</span>
            <span>{formatDate(user?.modifiedAt ?? user?.createdAt)}</span>
            <span>{formatTime(user?.modifiedAt ?? user?.createdAt)}</span>
          </div>{" "}
        </CommonCard>

        {currentUser?.authProvider == "email" ? (
          <CommonCard padding="sm">
            <div className="w-full flex flex-row gap-4">
              <div className="w-full gap-3 flex flex-col">
                <div className="gap-3">
                  <div className="text-gray-700 font-medium text-lg font-gooper">
                    Password
                  </div>
                  <div className="text-gray-700 font-medium text-sm">
                    Enter your current password
                  </div>
                </div>

                <InputSegment
                  title="Current Password"
                  onChange={setCurrentPassword}
                  value={currentPassword}
                  type="password"
                  placeholder="••••••"
                />
                <InputSegment
                  title="New Password"
                  onChange={handleUpdateNewPassword}
                  value={newPassword}
                  type="password"
                  placeholder="••••••"
                />
                <InputSegment
                  title="Confirm New Password"
                  onChange={handleUpdateConfirmNewPassword}
                  value={confirmNewPassword}
                  type="password"
                  placeholder="••••••"
                />
              </div>
              <div className="bg-gray-50 rounded-lg p-3 w-full gap-2 flex flex-col">
                <div className="text-gray-700 font-medium text-sm">
                  Password Requirements
                </div>
                <div className="text-gray-700 font-normal text-sm">
                  Ensure that your password,
                </div>
                <SuccessLabel
                  title={"At least 8 characters (and up to 100 characters)"}
                  success={validPasswordLength}
                />
                <SuccessLabel
                  title={"At least one lowercase character"}
                  success={validPasswordLowercase}
                />
                <SuccessLabel
                  title={"At least one uppercase character"}
                  success={validPasswordUppercase}
                />
                <SuccessLabel
                  title={
                    "Inclusion of at least one special character, e.g., ! @ # ?"
                  }
                  success={validPasswordCharacter}
                />
                <SuccessLabel
                  title={"Confirmed password matches"}
                  success={validPasswordConfirmed}
                />
              </div>
            </div>
            <div className="flex-1 border-t-2 border-gray-200"></div>
            <div className="flex flex-row w-full justify-end">
              <AnimatedButton
                title="Save Password"
                onClick={() => handleSavePassword()}
                buttonState={savePasswordState}
                setButtonState={setSavePasswordState}
                style="action"
              />
            </div>
          </CommonCard>
        ) : (
          <CommonCard padding="sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="57"
              height="24"
              viewBox="0 0 57 24"
              fill="none"
            >
              <g clipPath="url(#clip0_1334_17827)">
                <path
                  d="M24.6188 12.8251C24.6188 15.4867 22.5366 17.448 19.9813 17.448C17.4259 17.448 15.3438 15.4867 15.3438 12.8251C15.3438 10.1447 17.4259 8.20215 19.9813 8.20215C22.5366 8.20215 24.6188 10.1447 24.6188 12.8251ZM22.5887 12.8251C22.5887 11.1618 21.3819 10.0238 19.9813 10.0238C18.5806 10.0238 17.3738 11.1618 17.3738 12.8251C17.3738 14.4716 18.5806 15.6263 19.9813 15.6263C21.3819 15.6263 22.5887 14.4696 22.5887 12.8251Z"
                  fill="#E83151"
                />
                <path
                  d="M34.6237 12.8251C34.6237 15.4867 32.5415 17.448 29.9861 17.448C27.4308 17.448 25.3486 15.4867 25.3486 12.8251C25.3486 10.1468 27.4308 8.20215 29.9861 8.20215C32.5415 8.20215 34.6237 10.1447 34.6237 12.8251ZM32.5936 12.8251C32.5936 11.1618 31.3868 10.0238 29.9861 10.0238C28.5855 10.0238 27.3787 11.1618 27.3787 12.8251C27.3787 14.4716 28.5855 15.6263 29.9861 15.6263C31.3868 15.6263 32.5936 14.4696 32.5936 12.8251Z"
                  fill="#FDCA40"
                />
                <path
                  d="M44.211 8.48144V16.781C44.211 20.1951 42.1976 21.5894 39.8173 21.5894C37.5767 21.5894 36.2282 20.0908 35.7196 18.8653L37.4871 18.1295C37.8018 18.882 38.573 19.7699 39.8152 19.7699C41.3388 19.7699 42.283 18.8299 42.283 17.0603V16.3954H42.2121C41.7578 16.9561 40.8824 17.4459 39.7777 17.4459C37.4663 17.4459 35.3486 15.4325 35.3486 12.8417C35.3486 10.2322 37.4663 8.20215 39.7777 8.20215C40.8803 8.20215 41.7557 8.69195 42.2121 9.23595H42.283V8.48353H44.211V8.48144ZM42.4268 12.8417C42.4268 11.2139 41.3409 10.0238 39.959 10.0238C38.5584 10.0238 37.385 11.2139 37.385 12.8417C37.385 14.4529 38.5584 15.6263 39.959 15.6263C41.3409 15.6263 42.4268 14.4529 42.4268 12.8417Z"
                  fill="#3062FF"
                />
                <path
                  d="M47.3897 3.61719V17.165H45.4097V3.61719H47.3897Z"
                  fill="#4EBAAD"
                />
                <path
                  d="M55.1057 14.3467L56.6814 15.3972C56.1728 16.1496 54.9473 17.446 52.8297 17.446C50.2035 17.446 48.2422 15.416 48.2422 12.8231C48.2422 10.074 50.2202 8.2002 52.6025 8.2002C55.0015 8.2002 56.1749 10.1094 56.5584 11.1411L56.7689 11.6663L50.5891 14.2258C51.0622 15.1533 51.798 15.6265 52.8297 15.6265C53.8635 15.6265 54.5805 15.1179 55.1057 14.3467ZM50.2556 12.6835L54.3866 10.9681C54.1594 10.3908 53.4758 9.9885 52.6713 9.9885C51.6396 9.9885 50.2035 10.8993 50.2556 12.6835Z"
                  fill="#E83151"
                />
                <path
                  d="M7.84873 11.6229V9.66156H14.458C14.5226 10.0034 14.5559 10.4077 14.5559 10.8454C14.5559 12.3169 14.1537 14.1365 12.8572 15.4329C11.5963 16.746 9.98511 17.4463 7.85081 17.4463C3.89486 17.4463 0.568359 14.224 0.568359 10.2681C0.568359 6.31213 3.89486 3.08984 7.85081 3.08984C10.0393 3.08984 11.5983 3.94856 12.7697 5.06782L11.3857 6.45178C10.5458 5.66392 9.40776 5.05115 7.84873 5.05115C4.95992 5.05115 2.70057 7.37928 2.70057 10.2681C2.70057 13.1569 4.95992 15.485 7.84873 15.485C9.72249 15.485 10.7896 14.7326 11.4733 14.049C12.0277 13.4945 12.3924 12.7025 12.5363 11.6208L7.84873 11.6229Z"
                  fill="#3062FF"
                />
              </g>
              <defs>
                <clipPath id="clip0_1334_17827">
                  <rect
                    width="56.5"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="text-gray-700 text-sm">
              You're using Google as your authentication provider.
            </div>
          </CommonCard>
        )}

        <CommonCard padding="sm">
          <div className="flex flex-row justify-between">
            <AnimatedButton
              buttonState="ready"
              style="destructive"
              title="Delete Account"
              onClick={() => setShowDeleteAccount(true)}
            />
            <AnimatedButton
              leftIcon="close"
              title="Sign Out"
              onClick={() => setShowSignOut(true)}
              buttonState={signOutAnimationState}
              setButtonState={() => console.log()}
              style="normal"
              key="signOut"
            />
          </div>
        </CommonCard>
      </div>
    </CommonContainer>
  );
};

export default EditProfile;

const InputSegment: React.FC<{
  title: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  type?: "password" | "email" | "text";
  placeholder?: string;
}> = ({ title, value, onChange, disabled, type, placeholder }) => {
  return (
    <div className="w-full gap-2 flex flex-col bg-gray-0 rounded-lg">
      <div className="text-gray-700 font-medium text-sm">{title}</div>
      <input
        className={`border border-gray-300 focus:border-blue-500 rounded-md p-2 ${
          disabled ? "text-gray-500" : "text-gray-700"
        } bg-gray-50 font-gooper leading-tight  text-sm focus:ring-0 focus:outline-none`}
        value={value}
        onChange={(value) => onChange(value.target.value)}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};

const SuccessLabel: React.FC<{
  title: string;
  success: boolean;
}> = ({ title, success }) => {
  return (
    <div className="w-full gap-2 flex items-center flex-row rounded-lg">
      <Icon type={success ? "check" : "x"} className="size-3" />
      <div className="text-gray-700 text-sm">{title}</div>
    </div>
  );
};
