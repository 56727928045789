import React from "react";
import { InputTypes } from "../../../models/ChatConfigurationTemplate";
import InputField from "./InputField";

interface InputsSectionProps {
  inputs: { [key: string]: InputTypes };
  onChange: (inputs: { [key: string]: InputTypes }) => void;
}

const InputsSection: React.FC<InputsSectionProps> = ({ inputs, onChange }) => {
  const addInput = () => {
    const newKey = `input${Object.keys(inputs).length + 1}`;
    onChange({
      ...inputs,
      [newKey]: {
        description: "",
        name: "",
        input: "model",
        number: 0,
        shortDescription: "",
        defaultValue: "",
        values: [],
      },
    });
  };

  const updateInput = (
    oldKey: string,
    newKey: string,
    updatedInput: InputTypes
  ) => {
    const newInputs = { ...inputs };
    delete newInputs[oldKey];
    newInputs[newKey] = updatedInput;
    onChange(newInputs);
  };

  const deleteInput = (key: string) => {
    const newInputs = { ...inputs };
    delete newInputs[key];
    onChange(newInputs);
  };

  return (
    <div>
      <h4 className="font-medium flex flex-wrap">Inputs</h4>
      {Object.entries(inputs).map(([key, input]) => (
        <InputField
          key={key}
          inputKey={key}
          input={input}
          onChange={(newKey, updatedInput) =>
            updateInput(key, newKey, updatedInput)
          }
          onDelete={() => deleteInput(key)}
        />
      ))}
      <button
        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={addInput}
      >
        Add Input
      </button>
    </div>
  );
};

export default InputsSection;
