import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import { TeamSettingsSections } from "./TeamSettingsSections";
import { ChatConfigurationTemplate } from "../../models/ChatConfigurationTemplate";
import { usePortalService } from "../../contexts/PortalContext";
import FailureModal from "../FailureModal";
import { AIProviderLogo } from "../Portals/Portal Detail/AIProvider/AiProviderLogo";
import Icon from "../Icon";
import AnimatedButton from "../AnimatedButton";
import { Team } from "../../models/Team";
import { useTeams } from "../../contexts/TeamContext";
import { TeamSettingsAIProviderSetUpModal } from "./TeamSettingsAIProviderSetUpModal";
import { CommonSpinner } from "../Common/CommonLoading";
import { ProviderStatusBadge } from "./ProviderStatusBadge";
import { AppPath } from "../../models/AppPath";

export const TeamSettingsAIProviders: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const [searchParams] = useSearchParams();
  const providerParam = searchParams.get("provider");

  const navigate = useNavigate();
  const portalService = usePortalService();
  const teamService = useTeams();

  const [configs, setConfigs] = useState<ChatConfigurationTemplate[]>([]);
  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(
    providerParam
  );
  const [showSetUp, setShowSetUp] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const getConfigs = portalService.configTemplateRepo.getList(
          portalService.configTemplatePath()
        );
        const getTeam = teamService.teamRepo.get(
          teamService.teamPath(),
          teamId!
        );
        const [fetchedTeam, fetchedConfigs] = await Promise.all([
          getTeam,
          getConfigs,
        ]);
        setConfigs(fetchedConfigs);
        if (fetchedTeam) {
          setTeam(fetchedTeam);

          if (providerParam) {
            const config = fetchedConfigs.find((c) => c.id === providerParam);
            if (config) {
              const isConfigured =
                (fetchedTeam.secretsUsed ?? {})[config.id] !== undefined;
              if (isConfigured) {
                navigate(AppPath.aiProviderDetail(teamId!, config.id), {
                  replace: true,
                });
              } else {
                setShowSetUp(true);
              }
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError("Something went horribly wrong");
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [portalService, teamId, providerParam]);

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    dropIndex: number
  ) => {
    event.preventDefault();
    const dragIndex = Number(event.dataTransfer.getData("text/plain"));

    if (dragIndex !== dropIndex) {
      const reorderedConfigs = [...configs];
      const [movedItem] = reorderedConfigs.splice(dragIndex, 1);
      reorderedConfigs.splice(dropIndex, 0, movedItem);
      setConfigs(reorderedConfigs);
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title="AI Providers"
        subtitle="Effortlessly connect and configure top AI providers to supercharge your apps."
        sections={[
          { name: "Team Settings", link: `/${teamId}/settings` },
          { name: "AI Providers", link: `/${teamId}/settings/providers` },
        ]}
        teamId={teamId!}
        actions={[]}
      />
      <FailureModal
        message={error}
        shows={error !== ""}
        closed={() => setError("")}
      />
      <TeamSettingsSections selectedId="providers" />
      {loading && <CommonSpinner />}
      {team && selectedProvider && (
        <TeamSettingsAIProviderSetUpModal
          setShows={setShowSetUp}
          shows={showSetUp}
          config={configs.find((c) => c.id === selectedProvider)!}
          team={team}
        />
      )}
      {team && (
        <div className="flex flex-col gap-2">
          {configs.map((config, index) => (
            <ProviderRow
              key={index}
              team={team}
              config={config}
              index={index}
              handleDragOver={handleDragOver}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              onSetUpClick={() => {
                setSelectedProvider(config.id);
                setShowSetUp(true);
              }}
            />
          ))}
        </div>
      )}
    </CommonContainer>
  );
};

interface ProviderRowProps {
  team: Team;
  config: ChatConfigurationTemplate;
  index: number;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (
    event: React.DragEvent<HTMLDivElement>,
    dropIndex: number
  ) => void;
  onSetUpClick: () => void;
}

const ProviderRow: React.FC<ProviderRowProps> = ({
  config,
  index,
  team,
  handleDragStart,
  handleDragOver,
  handleDrop,
  onSetUpClick,
}) => {
  const navigate = useNavigate();
  const portalService = usePortalService();

  const [portalCount, setPortalCount] = useState(0);
  const [versionCount, setVersionCount] = useState(0);

  const configured = (team.secretsUsed ?? {})[config.id] !== undefined;

  useEffect(() => {
    const loadData = async () => {
      const portals = await portalService.portalRepo.getList(
        portalService.portalPath(team.id!)
      );
      const getVersions = portals.map(async (portal) => {
        const versions = await portalService.portalVersionRepo.getList(
          portalService.portalVersionPath(team.id!, portal.id!),
          undefined,
          [{ key: "configId", filter: "==", value: config.id }]
        );
        return { portalId: portal.id, versionCount: versions.length };
      });
      const useData = await Promise.all(getVersions);
      const fetchedPortalCount = useData.filter(
        (d) => d.versionCount > 0
      ).length;
      setPortalCount(fetchedPortalCount);
      const fetchedVersionCount = useData
        .map((d) => d.versionCount)
        .reduce((acc, cv) => acc + cv, 0);
      setVersionCount(fetchedVersionCount);
    };
    loadData();
  }, [portalService, team.id, config.id]);

  const handleClick = () => {
    if (configured) {
      navigate(AppPath.aiProviderDetail(team.id!, config.id));
    } else {
      onSetUpClick();
    }
  };

  return (
    <div
      id={`${config.id}Row`}
      key={config.id}
      onDragStart={(event) => handleDragStart(event, index)}
      onDragOver={handleDragOver}
      onDrop={(event) => handleDrop(event, index)}
      className="pl-8 pr-6 bg-white shadow h-28 rounded-lg flex"
    >
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center justify-between gap-10">
          <AIProviderLogo
            providerId={config.id}
            className="text-gray-700 w-28 h-12"
          />
          <ProviderStatusBadge team={team} config={config} />
        </div>
        {configured && (
          <div className="flex flex-row gap-1 text-gray-400">
            <Icon type="portals" />
            <div className="font-gooper">{portalCount}</div>
            <div className="font-sans">Portals </div>
            <Icon type="edit-list" />
            <div className="font-gooper">{versionCount}</div>
            <div className="font-sans">Versions</div>
          </div>
        )}
        <AnimatedButton
          title={configured ? "Manage" : "Set up connection"}
          style="action"
          setButtonState={undefined}
          buttonState="ready"
          onClick={handleClick}
          classNameIn="w-48"
          leftIcon={configured ? "share-nodes" : "edit"}
          font="font-sans"
          id={`${config.id}Button`}
        />
      </div>
    </div>
  );
};
