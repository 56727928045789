import { Edge, Node } from "reactflow";
import {
  WorkflowSteps,
  WorkflowStep,
  WorkflowPortalStep,
  WorkflowVariableInputs,
  WorkflowDecisionStep,
} from "../models/Workflow";
import {
  PortalNodeData,
  PortalNodeType,
  SelectedInputs,
} from "../components/Workflows/Map/Nodes/PortalNode";
import { StartNodeType } from "../components/Workflows/Map/Nodes/StartNode";
import {
  DecisionNodeData,
  DecisionNodeType,
  falseNodeHandlerId,
  trueNodeHandlerId,
} from "../components/Workflows/Map/Nodes/DecisionNode";
import { Team } from "../models/Team";
import { SelectedInput } from "../components/Workflows/Sidebar/VariableInputSelector";

export interface WorkflowConverter {
  getSteps(
    nodes: Node[],
    edges: Edge[],
    team: Team,
    isDemo: boolean
  ): ConvertedWorkflow | WorkflowConversionIssues;
}

export type ConvertedWorkflow = {
  type: "conversion";
  steps: WorkflowSteps;
  firstStepId: string;
  startNodeId: string;
};

export type WorkflowConversionIssues = {
  type: "issue";
  problems: WorkflowConversionProblem[];
};

export interface WorkflowConversionProblem {
  nodeId: string;
  message: string;
  id: string;
}

interface InputConversionResult {
  type: "success";
  inputs: WorkflowVariableInputs;
}

interface WorkflowStepConversionResult {
  step: WorkflowStep;
  type: "success";
}

export class ReactFlowWorkflowConverter implements WorkflowConverter {
  getSteps(
    nodes: Node[],
    edges: Edge[],
    team: Team,
    isDemo: boolean
  ): ConvertedWorkflow | WorkflowConversionIssues {
    const steps: WorkflowSteps = {};
    let firstStepId = this.getFirstId(edges);

    let problems: WorkflowConversionProblem[] = [];

    for (const node of nodes) {
      if (
        node.type == StartNodeType ||
        !this.isConnectedToStart(node.id, nodes, edges)
      ) {
        continue; // skip start node and non-connnected nodes
      }

      const result = this.convertNodeToStep(node, edges, team, isDemo);
      if (result?.type == "issue") {
        problems = problems.concat(result.problems);
        continue;
      }
      if (!result) {
        continue;
      }

      steps[result.step.id] = result.step;
    }

    if (!firstStepId) {
      problems.push(
        this.generateProblem(StartNodeType, "Attach something to the start!")
      );
      return { type: "issue", problems: problems };
    }

    if (problems.length > 0) {
      return { type: "issue", problems: problems };
    }

    return {
      steps,
      firstStepId,
      type: "conversion",
      startNodeId: StartNodeType,
    };
  }

  convertNodeToStep(
    node: Node,
    edges: Edge[],
    team: Team,
    isDemo: boolean
  ): WorkflowStepConversionResult | WorkflowConversionIssues | undefined {
    let problems: WorkflowConversionProblem[] = [];

    if (node.type == PortalNodeType) {
      const data = node.data as PortalNodeData;

      if (!data.currentVersion) {
        problems.push(this.generateProblem(node.id, "Needs a version"));
      }

      const nextStep = edges.filter((edge) => edge.source === node.id)[0]
        ?.target;

      const inputs = this.convertVariableInputs(
        data.selectedInputs,
        node.id,
        data.title
      );

      if (
        !isDemo &&
        (team.secretsUsed ?? {})[data.currentVersion!.configId] != true
      ) {
        problems.push(
          this.generateProblem(
            node.id,
            `Needs keys for ${data.currentVersion!.configId}`
          )
        );
      }
      if (inputs.type == "issue") {
        problems = problems.concat(inputs.problems);
        return { type: "issue", problems };
      } else if (problems.length > 0) {
        return { type: "issue", problems };
      }

      const portalStep: WorkflowPortalStep = {
        id: node.id,
        stepType: "portal",
        version: data.currentVersion!,
        versionId: data.currentVersion!.id!,
        nextStepId: nextStep,
        inputs: inputs.inputs,
        includeMessages: data.includeMessages != false,
        name: data.title,
      };
      return {
        type: "success",
        step: portalStep,
      };
    } else if (node.type == DecisionNodeType) {
      const data = node.data as DecisionNodeData;
      data.selectedOperand;

      const inputs = this.convertVariableInputs(
        { input: data.selectedInput, operand: data.selectedOperand },
        node.id,
        data.title
      );

      if (!this.inputConfigured(data.selectedOperand)) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs something to compare`
          )
        );
      }

      if (!data.selectedOperator) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs a logical operator`
          )
        );
      }

      if (!this.inputConfigured(data.selectedInput)) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs something to compare against`
          )
        );
      }

      const trueStepId = edges.filter(
        (e) => e.sourceHandle == trueNodeHandlerId(node.id)
      )[0]?.target;
      if (!trueStepId) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs a true beam connected`
          )
        );
      }

      const falseStepId = edges.filter(
        (e) => e.sourceHandle == falseNodeHandlerId(node.id)
      )[0]?.target;

      if (!falseStepId) {
        problems.push(
          this.generateProblem(
            node.id,
            `${data.title} needs a false beam connected`
          )
        );
      }

      if (inputs.type == "issue") {
        problems = problems.concat(inputs.problems);
        return { type: "issue", problems };
      } else if (problems.length > 0) {
        return { type: "issue", problems };
      }

      const decisionStep: WorkflowDecisionStep = {
        stepType: "decision",
        id: node.id,
        input: inputs.inputs.input,
        operand: inputs.inputs.operand,
        operator: data.selectedOperator!,
        trueStepId,
        falseStepId,
        name: data.title,
      };
      return {
        type: "success",
        step: decisionStep,
      };
    }

    // not supported node type (i.e. start node)
    return undefined;
  }

  inputConfigured(input: SelectedInput | undefined): boolean {
    if (input == undefined) {
      return false;
    }
    if (input.type == "output") {
      return !!input.nodeId;
    } else if (input.type == "variable") {
      return !!input.variableId;
    } else {
      return input.value != undefined;
    }
  }

  convertVariableInputs(
    selectedInputs: SelectedInputs | undefined,
    nodeId: string,
    title: string
  ): InputConversionResult | WorkflowConversionIssues {
    const inputs: WorkflowVariableInputs = {};
    const problems: WorkflowConversionProblem[] = [];
    Object.keys(selectedInputs ?? {}).map((v) => {
      const value = (selectedInputs ?? {})[v];

      if (value?.type == "text") {
        inputs[v] = { inputType: "static", value: value.value ?? "" };
      } else if (value?.type == "variable") {
        if (!value.variableId) {
          problems.push(
            this.generateProblem(nodeId, `Missing variable input in ${title}`)
          );
        }
        inputs[v] = { inputType: "variable", value: value.variableId! };
      } else if (value?.type == "output") {
        if (!value.nodeId) {
          problems.push(
            this.generateProblem(
              nodeId,
              `Missing beam output as input for in ${title}`
            )
          );
        }
        inputs[v] = { inputType: "output", value: value.nodeId! };
      } else if (value == undefined) {
        problems.push(
          this.generateProblem(nodeId, `Missing variable input for in ${title}`)
        );
      }
    });
    if (problems.length != 0) {
      return { type: "issue", problems: problems };
    }
    return { type: "success", inputs };
  }

  getFirstId(edges: Edge[]): string | undefined {
    return edges.filter((e) => e.source == StartNodeType)[0]?.target;
  }

  isConnectedToStart(
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    visited: Set<string> = new Set()
  ): boolean {
    if (nodeId === StartNodeType) {
      return true;
    }

    if (visited.has(nodeId)) {
      return false;
    }

    visited.add(nodeId);

    const incomingEdges = edges.filter((edge) => edge.target === nodeId);

    for (const edge of incomingEdges) {
      if (this.isConnectedToStart(edge.source, nodes, edges, visited)) {
        return true;
      }
    }

    return false;
  }

  generateProblem(nodeId: string, message: string): WorkflowConversionProblem {
    const timestamp = Date.now().toString(36);
    const randomPart = Math.random().toString(36).substring(2, 15);
    return {
      nodeId,
      message,
      id: `${timestamp}-${randomPart}`,
    };
  }
}
