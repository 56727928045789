import React from "react";

import { CommonModal } from "../../../Common/CommonModal";
import {
  ChatConfigurationModelInput,
  ChatConfigurationTemplate,
  InputTypes,
} from "../../../../models/ChatConfigurationTemplate";
import ConfigSlider from "./ConfigSlider";
import { ArrayInput } from "./ArrayInput";
import { ModelSelector } from "./ModelSelector";
import { PortalConfig, PortalVersion } from "../../../../models/PortalVersion";
import Icon from "../../../Icon";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";
import { AppPath } from "../../../../models/AppPath";

interface AIProviderConfigModalProps {
  setShows: (shows: boolean) => void;
  shows: boolean;
  template: ChatConfigurationTemplate;
  version: PortalVersion;
  setVersion: (version: PortalVersion) => void;
  teamId: string;
}

export const AIProviderConfigModal: React.FC<AIProviderConfigModalProps> = ({
  setShows,
  shows,
  template,
  version,
  setVersion,
  teamId,
}) => {
  const handleDismiss = () => {
    setShows(false);
  };

  const handleConfigUpdate = (
    id: string,
    value: number | string | string[]
  ) => {
    const config = version?.config;
    if (!config || !version) {
      return;
    }
    config[id] = value;
    setVersion({ ...version, config });
  };

  if (!shows) return null;

  return (
    <CommonModal isOpen={shows} onDismiss={handleDismiss}>
      <div
        className={`bg-gray-0 transition-all duration-300 rounded flex flex-col p-4 overflow-y-scroll max-h-[90vh]`}
      >
        <div
          className={` px-2 py-3 flex flex-row items-center justify-between w-full text-left font-gooper text-gray-700 text-md font-medium `}
        >
          {`${template.name} Provider Settings`}
          <button onClick={() => setShows(false)}>
            <Icon type="x" className="text-gray-200" />
          </button>
        </div>
        <div className="flex px-2">
          <CommonUnderlineButton
            title="Manage provider connection"
            onClick={() =>
              window.open(AppPath.aiProviders(teamId, template.id), "_blank")
            }
          />
        </div>
        <div className={`w-full border-b border-gray-200 h-1`} />
        <div
          className={`transition-all duration-300 ease-in-out opacity-100 max-h-fit`}
          style={{ transitionProperty: "max-height, opacity" }}
        >
          <div className="p-4 flex flex-col gap-6 rounded-b-lg">
            {Object.entries(template.inputs)
              .sort(
                ([_, inputA], [__, inputB]) => inputA.number - inputB.number
              )
              .map(([key, input]) => (
                <InputField
                  key={key}
                  input={input}
                  updated={handleConfigUpdate}
                  inputKey={key}
                  value={(version.config ?? {})[key]}
                  config={version.config ?? {}}
                  inputs={template.inputs}
                />
              ))}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

const InputField: React.FC<{
  inputKey: string;
  input: InputTypes;
  updated: (id: string, value: number | string | string[]) => void;
  value: number | string | string[];
  config: PortalConfig;
  inputs: {
    [key: string]: InputTypes;
  };
}> = ({ input, updated, inputKey, value, config, inputs }) => {
  if (input.input == "slider") {
    return (
      <ConfigSlider
        description={input.description}
        min={input.minValue}
        max={input.maxValue}
        onChange={(v) => updated(inputKey, v)}
        rangeLabels={input.rangeLabels}
        value={value as number}
        name={input.name}
        shortDescription={input.shortDescription}
      />
    );
  } else if (input.input == "model") {
    return (
      <ModelSelector
        input={input}
        value={value as string}
        update={(v) => updated(inputKey, v)}
      />
    );
  } else if (input.input == "maxLengthSlider") {
    const seletedModel = config["model"] as string;
    const modelInput = inputs["model"] as ChatConfigurationModelInput;
    const models = modelInput.values.filter((m) => m.id == seletedModel);
    if (models.length < 1) return;
    const model = models[0];

    return (
      <ConfigSlider
        description={input.description}
        min={10}
        max={model.maxLength}
        onChange={(v) => updated(inputKey, v)}
        rangeLabels={model.rangeLabels}
        value={value as number}
        name={input.name}
        shortDescription={input.shortDescription}
      />
    );
  } else if (input.input == "array" && Array.isArray(value)) {
    return (
      <ArrayInput
        input={input}
        values={value as string[]}
        update={(v) => updated(inputKey, v)}
      />
    );
  }
};
