import React from "react";
import { ExternalPath } from "../../models/ExternalPath";

interface NavigationFooterProps {
  toggleCollapse: () => void;
  isCollapsed: boolean;
}

const NavigationFooter: React.FC<NavigationFooterProps> = ({
  toggleCollapse,
  isCollapsed,
}) => {
  return (
    <>
      {isCollapsed ? (
        <div className="flex w-full h-8 pr-4">
          <button
            id="toggleMenuButton"
            onClick={() => toggleCollapse()}
            className="flex w-full pr-2 rounded-br-full rounded-tr-full bg-blue-50 justify-end items-center"
          >
            <svg
              className="w-4 h-4 text-blue-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
                d="m9 5 7 7-7 7"
              />
            </svg>
          </button>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-2">
            <div className="flex justify-start items-center gap-2">
              <a
                href={ExternalPath.docs()}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 text-xs font-normal leading-3 hover:text-gray-500"
              >
                Documentation
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={ExternalPath.docs()}
                className="text-gray-400 text-xs font-normal leading-3 hover:text-gray-500"
              >
                API
              </a>
            </div>
            <div className="flex justify-start items-center">
              <a
                onClick={() => {
                  if (window.Intercom) {
                    window.Intercom("showNewMessage", `Hey Easybeam team...`);
                  } else {
                    console.warn("Intercom is not available");
                  }
                }}
                className="text-gray-400 text-xs font-normal leading-3 hover:text-gray-500 hover:cursor-pointer"
              >
                Contact us
              </a>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <button
              id="toggleMenuButton"
              onClick={() => toggleCollapse()}
              className="text-gray-400 hover:text-gray-500"
            >
              <div className="flex rounded-full items-center justify-center bg-gray-200 h-7 w-7">
                <svg
                  className="w-5 h-5 text-gray-100 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="m15 19-7-7 7-7"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NavigationFooter;
