import React, { useState, useEffect, useRef } from "react";

interface ErrorToastProps {
  message: string;
  onClose: () => void;
}

export const CommonErrorToast: React.FC<ErrorToastProps> = ({
  message,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const toastRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShouldRender(true);
    const showTimer = setTimeout(() => setIsVisible(true), 100);
    const hideTimer = setTimeout(() => setIsVisible(false), 2700);
    const removeTimer = setTimeout(() => {
      setShouldRender(false);
      onClose();
    }, 5000);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
      clearTimeout(removeTimer);
    };
  }, [onClose]);

  if (!shouldRender) return null;

  return (
    <div
      ref={toastRef}
      className={`absolute min-w-72 z-50 transition-all duration-300 ease-in-out transform ${
        isVisible ? "translate-y-0 opacity-100" : "-translate-y-2 opacity-0"
      }`}
    >
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-md flex items-center justify-between">
        <div className="flex items-center gap-1">
          <span>{message}</span>
        </div>
      </div>
    </div>
  );
};
