import React from "react";
import {
  ChatConfigurationModelInput,
  ChatConfigurationModel,
} from "../../../models/ChatConfigurationTemplate";

interface ModelInputProps {
  input: ChatConfigurationModelInput;
  onChange: (updatedInput: ChatConfigurationModelInput) => void;
}

const ModelInput: React.FC<ModelInputProps> = ({ input, onChange }) => {
  const renderInput = (
    label: string,
    value: string | number,
    onChange: (value: string) => void,
    type: string = "text",
    placeholder: string = ""
  ) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        className="w-full p-2 border rounded"
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  const updateModel = (
    modelIndex: number,
    updatedModel: ChatConfigurationModel
  ) => {
    const updatedValues = [...input.values];
    updatedValues[modelIndex] = updatedModel;
    onChange({ ...input, values: updatedValues });
  };

  const addModel = () => {
    onChange({
      ...input,
      values: [
        ...input.values,
        { id: "", name: "", maxLength: 0, rangeLabels: [] },
      ],
    });
  };

  return (
    <>
      {renderInput("Input Name", input.name, (value) =>
        onChange({ ...input, name: value })
      )}
      {renderInput("Description", input.description, (value) =>
        onChange({ ...input, description: value })
      )}
      {renderInput("Short Description", input.shortDescription, (value) =>
        onChange({ ...input, shortDescription: value })
      )}
      {renderInput(
        "Number",
        input.number,
        (value) => onChange({ ...input, number: parseInt(value) }),
        "number"
      )}
      {renderInput("Default Value", input.defaultValue, (value) =>
        onChange({ ...input, defaultValue: value })
      )}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Models
        </label>
        {input.values.map((model, index) => (
          <div key={index} className="mb-4 p-4 border rounded">
            {renderInput("Model ID", model.id, (value) =>
              updateModel(index, { ...model, id: value })
            )}
            {renderInput("Model Name", model.name, (value) =>
              updateModel(index, { ...model, name: value })
            )}
            {renderInput(
              "Max Length",
              model.maxLength,
              (value) =>
                updateModel(index, { ...model, maxLength: parseInt(value) }),
              "number"
            )}
            {renderInput(
              "Range Labels (comma-separated)",
              model.rangeLabels.join(", "),
              (value) =>
                updateModel(index, {
                  ...model,
                  rangeLabels: value.split(",").map((s) => s.trim()),
                })
            )}
          </div>
        ))}
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={addModel}
        >
          Add Model
        </button>
      </div>
    </>
  );
};

export default ModelInput;
