import { WorkflowVersion } from "../../../../models/Workflow";
import { Team } from "../../../../models/Team";
import { SidebarWorkflowMetaDetail } from "./SidebarWorkflowMetaDetail";
import { StartNodeData } from "../../Map/Nodes/StartNode";

export const SidebarWorkflowMetaContainer: React.FC<{
  version: WorkflowVersion;
  setVersion: (workflow: WorkflowVersion) => void;
  setTeam: (team: Team) => void;
  team: Team;
  data: StartNodeData;
}> = ({ version, team, setVersion, setTeam, data }) => {
  return (
    <div className="flex flex-col gap-2">
      <SidebarWorkflowMetaDetail
        team={team}
        version={version}
        setTeam={setTeam}
        data={data}
        setVersion={setVersion}
      />
    </div>
  );
};
