import { Portal } from "../models/Portal";
import { PortalConfig, PortalVersion } from "../models/PortalVersion";
import { ChatConfigurationTemplate } from "../models/ChatConfigurationTemplate";
import { Review } from "../models/Review";
import { ChatMessage } from "../models/ChatMessage";
import { Repository } from "../repos/Repository";
import { Networking } from "../utils/NetworkHelper";
import { FunctionRepository } from "../repos/FunctionRepository";
import { parseDates } from "../utils/DateUtils";

export interface DemoResponse {
  newMessage: ChatMessage;
  totalCost: number;
}

export interface PortalService {
  portalStatsRepo: Repository<PortalDailyStats>;
  portalStatsPath(teamId: string, portalId: string): string;
  portalRepo: Repository<Portal>;
  portalPath(teamId: string): string;
  examplePortalPath(): string;
  portalVersionRepo: Repository<PortalVersion>;
  portalVersionPath(teamId: string, portalId: string): string;
  reviewRepo: Repository<Review>;
  reviewPath(teamId: string, portalId: string, versionId: string): string;
  abTestPath(teamId: string, portalId: string): string;
  portalTestPath(teamId: string, portalId: string): string;
  portalTestRunPath(teamId: string, portalId: string): string;
  configTemplateRepo: Repository<ChatConfigurationTemplate>;
  configTemplatePath(): string;
  updateVersion(
    portalVersion: PortalVersion | undefined,
    versionId: string,
    teamId: string,
    portalId: string
  ): Promise<void>;
  publishVersion(
    versionId: string,
    teamId: string,
    portalId: string
  ): Promise<PortalVersion>;
  unpublishVersion(teamId: string, portalId: string): Promise<void>;
  createPortal(
    teamId: string,
    configId?: string
  ): Promise<{ portal: Portal; version: PortalVersion }>;
  deletePortal(teamId: string, portalId: string): Promise<void>;
  runDemo(
    teamId: string,
    prompt: string,
    filledVariables: FilledVariables,
    messages: ChatMessage[],
    config: PortalConfig,
    configId: string
  ): Promise<ChatMessage>;
  streamDemo(
    teamId: string,
    prompt: string,
    filledVariables: FilledVariables,
    messages: ChatMessage[],
    config: PortalConfig,
    configId: string,
    onNewMessage: (demoResponse: DemoResponse) => void,
    onClose: () => void,
    onError: (error: Error) => void
  ): Promise<void>;
  cancelCurrentStream(): void;
  createVersion(teamId: string, portalId?: string): Promise<PortalVersion>;
  publishTemplate(templateId: string): Promise<void>;
  duplicateVersion(
    teamId: string,
    portalId: string,
    versionId: string
  ): Promise<PortalVersion>;
  copyPortalToExamples(teamId: string, portalId: string): Promise<void>;
  deletePortalExample(portalId: string): Promise<void>;
}

export interface FilledVariables {
  [key: string]: string;
}

export class FirestorePortalService implements PortalService {
  constructor(
    public portalRepo: Repository<Portal>,
    public portalStatsRepo: Repository<PortalDailyStats>,
    public portalVersionRepo: Repository<PortalVersion>,
    public reviewRepo: Repository<Review>,
    public configTemplateRepo: Repository<ChatConfigurationTemplate>,
    private networkHelper: Networking,
    private functionsWrapper: FunctionRepository
  ) {}

  portalPath(teamId: string): string {
    return `teams/${teamId}/portals`;
  }

  portalStatsPath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/stats`;
  }

  examplePortalPath(): string {
    return `examplePortals`;
  }

  portalVersionPath(teamId: string, portalId: string): string {
    return `${this.portalPath(teamId)}/${portalId}/versions`;
  }

  reviewPath(teamId: string, portalId: string, versionId: string): string {
    return `${this.portalVersionPath(teamId, portalId)}/${versionId}/reviews`;
  }

  abTestPath(teamId: string, portalId: string): string {
    return `${this.portalPath(teamId)}/${portalId}/abTests`;
  }

  portalTestPath(teamId: string, portalId: string): string {
    return `${this.portalPath(teamId)}/${portalId}/tests`;
  }

  portalTestRunPath(teamId: string, portalId: string): string {
    return `${this.portalPath(teamId)}/${portalId}/test-runs`;
  }

  configTemplatePath(): string {
    return `chatConfigurations`;
  }

  async updateVersion(
    portalVersion: PortalVersion,
    versionId: string,
    teamId: string,
    portalId: string
  ) {
    if (process.env.NODE_ENV === "development") {
      console.log(`UPDATING ${versionId}`);
    }
    await this.functionsWrapper.callFunction("updatePortalVersion", {
      teamId,
      portalId,
      portalVersion,
      versionId,
    });
  }

  async publishVersion(versionId: string, teamId: string, portalId: string) {
    const result = await this.functionsWrapper.callFunction("publishVersion", {
      teamId,
      portalId,
      versionId,
    });
    const formatted = parseDates(result);
    return formatted as PortalVersion;
  }

  async unpublishVersion(teamId: string, portalId: string) {
    await this.functionsWrapper.callFunction("unpublishVersion", {
      teamId,
      portalId,
    });
  }

  async createPortal(
    teamId: string,
    configId?: string
  ): Promise<{ portal: Portal; version: PortalVersion }> {
    const result = await this.functionsWrapper.callFunction("createPortal", {
      teamId,
      configId,
    });
    const data = result as { portal: Portal; version: PortalVersion };
    const formatted = parseDates(data);
    return formatted;
  }

  async createVersion(
    teamId: string,
    portalId?: string
  ): Promise<PortalVersion> {
    const result = await this.functionsWrapper.callFunction(
      "createPortalVersion",
      { teamId, portalId }
    );
    const formatted = parseDates(result);
    return formatted as PortalVersion;
  }

  async duplicateVersion(
    teamId: string,
    portalId: string,
    versionId: string
  ): Promise<PortalVersion> {
    const result = await this.functionsWrapper.callFunction(
      "duplicatePortalVersion",
      { teamId, portalId, versionId }
    );
    const formatted = parseDates(result);
    return formatted as PortalVersion;
  }

  async deletePortal(teamId: string, portalId: string): Promise<void> {
    await this.functionsWrapper.callFunction("deletePortal", {
      teamId,
      portalId,
    });
  }

  async runDemo(
    teamId: string,
    prompt: string,
    filledVariables: FilledVariables,
    messages: ChatMessage[],
    config: PortalConfig,
    configId: string
  ): Promise<ChatMessage> {
    const body = {
      teamId,
      prompt,
      messages,
      config,
      configId,
      parameters: filledVariables,
      stream: false,
    };
    const response = await this.networkHelper.sendRequest(
      "/portal-demo/run",
      "POST",
      body
    );
    const json = await response.json();
    const data = json as { newMessage: ChatMessage };
    return data.newMessage;
  }

  async streamDemo(
    teamId: string,
    prompt: string,
    filledVariables: FilledVariables,
    messages: ChatMessage[],
    config: PortalConfig,
    configId: string,
    onNewMessage: (demoResponse: DemoResponse) => void,
    onClose: () => void,
    onError: (error: Error) => void
  ): Promise<void> {
    const params = {
      teamId,
      prompt,
      parameters: filledVariables,
      messages: messages,
      config: config,
      configId,
      stream: "true",
    };

    const handleError = (error: Error) => {
      onError(error);
    };

    let buffer = "";
    const handleMessage = (chunk: string) => {
      buffer += chunk;
      try {
        while (buffer.includes("\n\n")) {
          const end = buffer.indexOf("\n\n");
          const message = buffer.substring(0, end);
          buffer = buffer.substring(end + 2);

          if (message.startsWith("data: ")) {
            const dataJson = message.slice(6);
            const data = JSON.parse(dataJson);
            if (data.error) {
              throw new Error(
                `Stream error: ${data.error} status: ${data.status ?? 0}`
              );
            }
            const chat = data as DemoResponse;
            onNewMessage(chat);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          handleError(error);
        } else {
          handleError(new Error("Unknown json processing error"));
        }
      }
    };

    const handleClose = () => {
      onClose();
    };

    await this.networkHelper.sendStream(
      "/portal-demo/run",
      "POST",
      params,
      handleMessage,
      handleClose,
      handleError
    );
  }

  cancelCurrentStream() {
    this.networkHelper.cancelStream();
  }

  async publishTemplate(templateId: string): Promise<void> {
    await this.functionsWrapper.callFunction("copyTemplateToProd", {
      templateId,
    });
  }

  async copyPortalToExamples(teamId: string, portalId: string): Promise<void> {
    await this.functionsWrapper.callFunction("copyPortalToExamples", {
      teamId,
      portalId,
    });
  }
  async deletePortalExample(portalId: string): Promise<void> {
    await this.functionsWrapper.callFunction("deletePortalExample", {
      portalId,
    });
  }
}
