export const CredentialInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}> = ({ value, onChange, disabled, placeholder }) => {
  const handleOnChange = (newValue: string) => {
    onChange(newValue);
  };
  return (
    <div className="w-full gap-2 flex flex-col bg-gray-0 rounded-lg">
      <div className="relative items-center justify-center flex w-full">
        <input
          className={`border border-gray-200 rounded-md p-2 leading-tight  text-sm focus:ring-0 focus:outline-none h-10 w-full`}
          value={value}
          onChange={(value) => handleOnChange(value.target.value)}
          disabled={disabled}
          type={"text"}
          placeholder={placeholder}
          id={"credentialInput"}
        />
      </div>
    </div>
  );
};
