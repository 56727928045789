export const InputStyle = {
  control: {
    backgroundColor: "",
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "20px",
    fontFamily: "inherit", // Ensure font family is consistent
    boxSizing: "border-box", // Add box-sizing
  },
  "&multiLine": {
    control: {
      fontFamily: "inherit",
      minHeight: 63,
      lineHeight: "20px",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      fontFamily: "inherit",
      fontSize: 14,
      lineHeight: "20px",
      boxSizing: "border-box",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    input: {
      padding: 9,
      border: "none",
      outline: "none",
      fontFamily: "inherit",
      fontSize: 14,
      lineHeight: "20px",
      boxSizing: "border-box",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "1px solid transparent", // Consistent transparency
    },
    input: {
      padding: 1,
      border: "none", // Removed border
      outline: "none", // Remove focus outline
      lineHeight: "20px",
    },
  },

  suggestions: {
    backgroundColor: "transparent",
    list: {
      fontSize: 14,
      borderRadius: "8px",
    },
    item: {
      borderBottom: "none", // Removed border on items
      color: "#374151",
      "&focused": {
        backgroundColor: "#eaefff",
        outline: "none", // No outline on focus
        border: "none", // No border on focus
      },
    },
  },
};
