import React, { useEffect, useRef, useState } from "react";
import CommonHeader from "../../Common/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import { checkForErrors, Portal } from "../../../models/Portal";
import { usePortalService } from "../../../contexts/PortalContext";
import CommonContainer from "../../Common/CommonContainer";
import { CommonLoading } from "../../Common/CommonLoading";
import FailureModal from "../../FailureModal";
import { PortalVersion } from "../../../models/PortalVersion";
import { ChatConfigurationTemplate } from "../../../models/ChatConfigurationTemplate";
import { VersionDemoer } from "./VersionDemor";
import { Team, TeamVariables } from "../../../models/Team";
import { useTeams } from "../../../contexts/TeamContext";
import { VersionList } from "./VersionList";
import { VersionDetailCard } from "./VersionDetailCard";
import { deepCopy } from "../../../utils/DeepClone";
import { PortalDetailActions } from "./PortalDetalActions";
import { extractVariableValues } from "../../../utils/PromptUtil";
import { PortalNameModal } from "./PortalNameModal";
import { NoVersionsModal } from "./NoVersionsModal";
import { useDebounce } from "../../../utils/Debounce";

const PortalDetail: React.FC = () => {
  const { teamId, portalId, versionId } = useParams<{
    teamId: string;
    portalId: string;
    versionId: string;
  }>();

  const teamService = useTeams();
  const portalService = usePortalService();
  const navigate = useNavigate();

  const [team, setTeam] = useState<Team>();
  const [portal, setPortal] = useState<Portal>();
  const [version, setVersion] = useState<PortalVersion>();
  const [versions, setVersions] = useState<PortalVersion[]>();
  const fetchedVerionRef = useRef<PortalVersion>();

  const [templates, setTemplates] = useState<ChatConfigurationTemplate[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [variables, setVariables] = useState<string[]>([]);

  const queryParams = new URLSearchParams(location.search);
  const showPortalModal = queryParams.get("showPortalModal") == "true";
  const setShowPortalModal = (shows: boolean) => {
    if (!shows) {
      queryParams.delete("showPortalModal");
    }
    navigate({ search: queryParams.toString() }, { replace: true });
  };

  const [nameError, setNameError] = useState("");

  const [showNoVersionsModal, setShowNoVersionsModal] = useState(false);

  const [demoConfigVariables, setDemoConfigVariables] = useState<{
    [key: string]: string;
  }>(extractVariableValues(version?.prompt ?? ""));

  const loadData = async () => {
    const getTeam = teamService.teamRepo.get(teamService.teamPath(), teamId!);

    const getPortal = portalService.portalRepo.get(
      portalService.portalPath(teamId!),
      portalId!
    );

    if (!versionId) {
      const versions = await portalService.portalVersionRepo.getList(
        portalService.portalVersionPath(teamId!, portalId!),
        { name: "modifiedAt", descending: true },
        undefined,
        1
      );
      const portal = await getPortal;
      const first = versions[0];
      if (!first) {
        setShowNoVersionsModal(true);
        setPortal(portal ?? undefined);
        return;
      }
      navigate(`/teams/${teamId}/portals/${portalId}/version/${first.id}`, {
        replace: true,
      });
      return;
    }
    const getVersions = await portalService.portalVersionRepo.getList(
      portalService.portalVersionPath(teamId!, portalId!),
      { name: "modifiedAt", descending: true },
      undefined,
      20
    );
    const getVersion = portalService.portalVersionRepo.get(
      portalService.portalVersionPath(teamId!, portalId!),
      versionId!
    );
    const getTempaltes = portalService.configTemplateRepo.getList(
      portalService.configTemplatePath()
    );
    await Promise.all([
      getPortal,
      getVersion,
      getTempaltes,
      getTeam,
      getVersions,
    ])
      .then(
        ([
          fetchedPortal,
          fetchedVersion,
          fetchedTemplates,
          fetchedTeam,
          fetchedVersions,
        ]) => {
          if (!fetchedPortal) {
            setError("No portal found!");
            return;
          }
          setPortal(fetchedPortal);
          if (!fetchedVersion) {
            setError("No version found!");
            return;
          }
          setVersion(fetchedVersion);

          fetchedVerionRef.current = deepCopy(fetchedVersion);

          if (!fetchedTemplates) {
            setError("No chat config templates found!");
            return;
          }
          setTemplates(fetchedTemplates);
          setTeam(fetchedTeam!);
          const fetchedVariables = fetchedTeam?.variables ?? {};
          const variableArray = Object.keys(fetchedVariables).map(
            (key) => fetchedVariables[key].id
          );
          setVariables(variableArray);
          setDemoConfigVariables(
            extractVariableValues(fetchedVersion?.prompt ?? "")
          );
          let organizedVersions = fetchedVersions.filter(
            (v) => v.id != fetchedPortal.currentVersionId
          );
          if (fetchedPortal.currentVersionData) {
            organizedVersions = [
              fetchedPortal.currentVersionData,
              ...organizedVersions,
            ];
          }
          setVersions(organizedVersions);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, [teamId, versionId, portalId]);

  const portalName = portal?.name ?? "Portal";
  const versionName = version?.name ?? "Version";

  const handleSelectedVersion = async (selectedVersion: PortalVersion) => {
    if (version?.id == selectedVersion.id) {
      return;
    }
    setLoading(true);
    navigate(
      `/teams/${teamId!}/portals/${portalId!}/version/${selectedVersion.id}`
    );
  };

  const handleVersionHistory = async () => {
    navigate(`/teams/${teamId!}/portals/${portalId}/versions`);
  };

  const handleSave = useDebounce(async (version: PortalVersion) => {
    const errors = checkForErrors(portal!, version!);
    setNameError(errors?.versionNameError ?? "");
    const updateVersion = portalService.updateVersion(
      version!,
      version!.id!!,
      teamId!,
      portal!.id!
    );
    setVersion({ ...version!, modifiedAt: new Date() });
    try {
      await Promise.all([updateVersion]);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("failed to save");
      }
    }
  }, 2000);

  const handleVersionUpdate = (newVersion: PortalVersion) => {
    setVersion(newVersion);
    if (version && versions) {
      const updatedVersions = versions.map((v) =>
        v.id === newVersion.id ? { ...v, ...newVersion } : v
      );
      setVersions(updatedVersions);
    }
    handleSave(newVersion);
  };

  const handledAddedVariable = async (newVariable: string) => {
    setVariables([...variables, newVariable]);
    const newVariables = team?.variables ?? {};
    newVariables[newVariable] = { id: newVariable };
    await teamService.teamRepo.update(
      { variables: newVariables },
      teamService.teamPath(),
      teamId!
    );
    setTeam({ ...team!, variables: newVariables });
  };

  const handleUpdatedPrompt = async (prompt: string) => {
    const usedVariablesIds = extractVariableValues(prompt);
    const usedVariables: { [id: string]: TeamVariables } = {};
    Object.keys(usedVariablesIds).forEach((key) => {
      usedVariables[key] = { id: key };
    });
    const updatedVersion: PortalVersion = {
      ...version!,
      prompt,
      usedVariables,
    };
    setVersion(updatedVersion);
    handleSave(updatedVersion);
    const newDemoValues = extractVariableValues(prompt);
    Object.keys(demoConfigVariables).forEach((key) => {
      if (Object.keys(newDemoValues).includes(key)) {
        newDemoValues[key] = demoConfigVariables[key];
      }
    });
    setDemoConfigVariables(newDemoValues);
  };

  // TODO: Clean up height hack
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const leftContainer = leftContainerRef.current;
    const rightContainer = rightContainerRef.current;

    if (!leftContainer || !rightContainer) return;

    const resizeObserver = new ResizeObserver(() => {
      rightContainer.style.height = `${leftContainer.offsetHeight}px`;
    });

    resizeObserver.observe(leftContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, [version, portal]);

  return (
    <CommonContainer>
      {loading && <CommonLoading />}
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to Portals"}
        shows={error != ""}
        closed={() => setError("")}
      />
      {portal && (
        <PortalNameModal
          portal={portal}
          shows={showPortalModal}
          setShows={setShowPortalModal}
          setPortal={setPortal}
          teamId={teamId!}
        />
      )}
      {portal && (
        <NoVersionsModal
          portal={portal}
          shows={showNoVersionsModal}
          teamId={teamId!}
          setShows={setShowNoVersionsModal}
        />
      )}
      <CommonHeader
        title={`${portalName}:  ${versionName}`}
        subtitle={`This is where you create, iterate, and publish versions. Preview them here or compare them in the test center.`}
        sections={[
          { name: "Portals", link: `/teams/${teamId}/portals` },
          {
            name: `${portalName}`,
            link: `/teams/${teamId}/portals/${portalId!}/version`,
          },
          {
            name: `${versionName}`,
            link: ``,
          },
        ]}
        teamId={teamId!}
        actions={[
          <PortalDetailActions
            version={version}
            portal={portal}
            team={team}
            updatedVersion={handleVersionUpdate}
            loadData={loadData}
            key={"actions-col"}
          />,
        ]}
      />

      <div className="flex flex-col md:flex-row gap-6">
        {version && portal && templates && team && (
          <div
            className="md:w-7/12 flex flex-col gap-4 h-fit"
            ref={leftContainerRef}
          >
            <VersionDetailCard
              version={version}
              portal={portal}
              updatedVersion={handleVersionUpdate}
              variables={
                Object.keys(team?.variables ?? {}).map(
                  (key) => (team?.variables ?? {})[key].id
                ) ?? []
              }
              templates={templates}
              setIsLoading={setLoading}
              setNameError={setNameError}
              nameError={nameError}
              addedVariable={handledAddedVariable}
              teamId={teamId!}
              updatePrompt={handleUpdatedPrompt}
              team={team}
            />
          </div>
        )}

        {version && team && (
          <div
            className="md:w-5/12 flex-grow flex flex-col max-h-fit overflow-hidden"
            ref={rightContainerRef}
          >
            <VersionDemoer
              data={{ version: version, type: "portal" }}
              teamId={teamId!}
              demoConfigVariables={demoConfigVariables}
              setDemoConfigVariables={setDemoConfigVariables}
              teamName={team.name}
            />
          </div>
        )}
      </div>

      {versions && portal && version && (
        <VersionList
          versions={versions}
          selectedVersion={(version) => handleSelectedVersion(version)}
          portal={portal}
          currentlySelected={version}
          clickedVersionHistory={handleVersionHistory}
          teamId={teamId!}
        />
      )}
    </CommonContainer>
  );
};

export default PortalDetail;
