import React from "react";
import { ChatConfigurationArrayInput } from "../../../models/ChatConfigurationTemplate";

interface ArrayInputProps {
  input: ChatConfigurationArrayInput;
  onChange: (updatedInput: ChatConfigurationArrayInput) => void;
}

const ArrayInput: React.FC<ArrayInputProps> = ({ input, onChange }) => {
  const renderInput = (
    label: string,
    value: string | number,
    onChange: (value: string) => void,
    type: string = "text",
    placeholder: string = ""
  ) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        className="w-full p-2 border rounded"
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  const updateArrayValue = (index: number, value: string) => {
    const updatedDefaultValue = [...input.defaultValue];
    updatedDefaultValue[index] = value;
    onChange({ ...input, defaultValue: updatedDefaultValue });
  };

  const addArrayValue = () => {
    onChange({ ...input, defaultValue: [...input.defaultValue, ""] });
  };

  const removeArrayValue = (index: number) => {
    const updatedDefaultValue = input.defaultValue.filter(
      (_, i) => i !== index
    );
    onChange({ ...input, defaultValue: updatedDefaultValue });
  };

  return (
    <>
      {renderInput("Input Name", input.name, (value) =>
        onChange({ ...input, name: value })
      )}
      {renderInput("Description", input.description, (value) =>
        onChange({ ...input, description: value })
      )}
      {renderInput("Short Description", input.shortDescription, (value) =>
        onChange({ ...input, shortDescription: value })
      )}
      {renderInput(
        "Number",
        input.number,
        (value) => onChange({ ...input, number: parseInt(value) }),
        "number"
      )}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Default Values
        </label>
        {input.defaultValue.map((value, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              className="flex-grow p-2 border rounded mr-2"
              value={value}
              onChange={(e) => updateArrayValue(index, e.target.value)}
              placeholder={`Value ${index + 1}`}
            />
            <button
              className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => removeArrayValue(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={addArrayValue}
        >
          Add Value
        </button>
      </div>
    </>
  );
};

export default ArrayInput;
