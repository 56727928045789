import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { TeamSettingsSections } from "./TeamSettingsSections";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import FailureModal from "../FailureModal";
import { CommonSpinner } from "../Common/CommonLoading";
import Icon from "../Icon";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { CommonConfirmationModal } from "../Common/CommonConfirmationModal";
import { TeamSettingsVariableInput } from "./TeamSettingsVariableInput";

export const TeamSettingsVariables: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const teamService = useTeams();

  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [createState, setCreateState] = useState<AnimationState>("ready");
  const [showCreateVariable, setShowCreateVariable] = useState(false);

  const [deletionModalIndex, setDeletionModalIndex] = useState<number>();

  const loadData = async () => {
    try {
      const fetchedTeam = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      if (fetchedTeam) {
        setTeam(fetchedTeam);
      }
    } catch (e) {
      setError("Failed to load team");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [teamService]);

  const variables = team?.variables ?? {};
  const listData = Object.keys(variables).sort((a, b) => a.localeCompare(b));

  const handleDeleteVariable = async (variable: string) => {
    setDeletionModalIndex(undefined);
    try {
      delete variables[variable];
      await teamService.teamRepo.update(
        { variables },
        teamService.teamPath(),
        teamId!
      );
      setCreateState("success");
      loadData();
    } catch {
      setCreateState("error");
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title={`Variables`}
        subtitle="Variables are how data gets inject from the API into prompts & workflows. They can be created here or directly in prompts."
        sections={[
          { name: "Team Settings", link: `/${teamId}/settings` },
          { name: "Variables", link: `/${teamId}/settings/variables` },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title={`Create Variable`}
            onClick={() => setShowCreateVariable(true)}
            buttonState={createState}
            setButtonState={setCreateState}
            style={"action"}
            key="create"
            id="createButton"
          />,
        ]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      <TeamSettingsSections selectedId="variables" />
      {team && (
        <TeamSettingsVariableInput
          shows={showCreateVariable}
          team={team}
          setShows={setShowCreateVariable}
        />
      )}

      <table className="border  rounded-3xl border-gray-200 w-full bg-gray-200">
        <thead className="border-b border-gray-200">
          <tr className="">
            <Header title="Variable" />
            <Header title="Action" className="w-1" padding="p-2" />
          </tr>
          {loading && listData.length == 0 && (
            <tr className="bg-gray-0">
              <td className="border-b border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
              <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
            </tr>
          )}
        </thead>
        {listData.map((variable, index) => {
          return (
            <tbody key={index} id={`variableCell${index}`}>
              <tr className="bg-gray-0">
                <td className="border-b text-gray-500 border-gray-200 p-3 font-normal text-sm">
                  {variable}
                </td>
                <td className="relative border-b border-gray-200 pl-6 font-medium text-sm">
                  <CommonConfirmationModal
                    title="Are you sure?"
                    message="Your portals using this variable won't be affected, this variable won't show up in the suggestions when making edits to the prompt of a portal version."
                    onCancel={() => setDeletionModalIndex(undefined)}
                    isOpen={deletionModalIndex == index}
                    onConfirm={() => handleDeleteVariable(variable)}
                    confirmStyle="destructive"
                  />
                  <button
                    id={`deleteButton${index}`}
                    onClick={() => setDeletionModalIndex(index)}
                    className="size-7 rounded-full bg-red-50 hover:bg-blue-100 items-center flex justify-center"
                  >
                    <Icon type="trash" className="size-5 text-red-500" />
                  </button>
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </CommonContainer>
  );
};

const Header: React.FC<{
  title: string;
  className?: string;
  padding?: string;
}> = ({ title, className, padding }) => {
  return (
    <th
      className={`${
        padding ?? "p-3"
      } bg-gray-50 text-left p4 text-xs font-medium text-gray-500 uppercase tracking-widest ${className}`}
    >
      {title}
    </th>
  );
};
