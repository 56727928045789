import React from "react";
import { ChatConfigurationSliderNumberInput } from "../../../models/ChatConfigurationTemplate";

interface SliderInputProps {
  input: ChatConfigurationSliderNumberInput;
  onChange: (updatedInput: ChatConfigurationSliderNumberInput) => void;
}

const SliderInput: React.FC<SliderInputProps> = ({ input, onChange }) => {
  const renderInput = (
    label: string,
    value: string | number,
    onChange: (value: string) => void,
    type: string = "text",
    placeholder: string = ""
  ) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        className="w-full p-2 border rounded"
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <>
      {renderInput("Input Name", input.name, (value) =>
        onChange({ ...input, name: value })
      )}
      {renderInput("Description", input.description, (value) =>
        onChange({ ...input, description: value })
      )}
      {renderInput("Short Description", input.shortDescription, (value) =>
        onChange({ ...input, shortDescription: value })
      )}
      {renderInput(
        "Number",
        input.number,
        (value) => onChange({ ...input, number: parseInt(value) }),
        "number"
      )}
      {renderInput(
        "Min Value",
        input.minValue,
        (value) => onChange({ ...input, minValue: parseInt(value) }),
        "number"
      )}
      {renderInput(
        "Max Value",
        input.maxValue,
        (value) => onChange({ ...input, maxValue: parseInt(value) }),
        "number"
      )}
      {renderInput(
        "Default Value",
        input.defaultValue,
        (value) => onChange({ ...input, defaultValue: parseInt(value) }),
        "number"
      )}
      {renderInput(
        "Range Labels (comma-separated)",
        input.rangeLabels.join(", "),
        (value) =>
          onChange({
            ...input,
            rangeLabels: value.split(",").map((s) => s.trim()),
          })
      )}
    </>
  );
};

export default SliderInput;
