import React, { useState } from "react";
import { ChatConfigurationTemplate } from "../../../models/ChatConfigurationTemplate";
import InputsSection from "./InputsSection";
import SecretsSection from "./SecretsSection";
import SetupStepsSection from "./SetupStepsSection";

interface ChatConfigurationTemplateFormProps {
  template?: ChatConfigurationTemplate;
  onSave: (template: ChatConfigurationTemplate) => void;
  onClose: () => void;
}

const ChatConfigurationTemplateForm: React.FC<
  ChatConfigurationTemplateFormProps
> = ({ template, onSave, onClose }) => {
  const [formState, setFormState] = useState<ChatConfigurationTemplate>(
    template || {
      id: "",
      name: "",
      description: "",
      inputs: {},
      secrets: {},
      setupSteps: [],
      setupOverview: "",
    }
  );

  const handleInputChange = (
    field: keyof ChatConfigurationTemplate,
    value: any
  ) => {
    setFormState({ ...formState, [field]: value });
  };

  const handleSubmit = () => {
    if (!formState.id.trim()) {
      alert("Please provide a valid ID for the template.");
      return;
    }
    onSave(formState);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-3/4 max-w-4xl shadow-lg rounded-md bg-white">
        <h2 className="text-2xl font-bold mb-4">
          {template ? "Edit" : "Create"} Configuration Template
        </h2>
        <div className="space-y-4 max-h-[calc(100vh-200px)] overflow-y-auto">
          <div>
            <label
              htmlFor="template-id"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Template ID
            </label>
            <input
              id="template-id"
              className="w-full p-2 border rounded"
              value={formState.id}
              onChange={(e) => handleInputChange("id", e.target.value)}
              placeholder="Template ID"
            />
          </div>
          <div>
            <label
              htmlFor="template-name"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Template Name
            </label>
            <input
              id="template-name"
              className="w-full p-2 border rounded"
              value={formState.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              placeholder="Template Name"
            />
          </div>
          <div>
            <label
              htmlFor="template-description"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Description
            </label>
            <textarea
              id="template-description"
              className="w-full p-2 border rounded"
              value={formState.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              placeholder="Description"
            />
          </div>
          <div>
            <label
              htmlFor="template-setup-overview"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Setup Overview
            </label>
            <textarea
              id="template-setup-overview"
              className="w-full p-2 border rounded"
              value={formState.setupOverview}
              onChange={(e) =>
                handleInputChange("setupOverview", e.target.value)
              }
              placeholder="Setup Overview"
            />
          </div>

          <InputsSection
            inputs={formState.inputs}
            onChange={(inputs) => handleInputChange("inputs", inputs)}
          />

          <SecretsSection
            secrets={formState.secrets}
            onChange={(secrets) => handleInputChange("secrets", secrets)}
          />

          <SetupStepsSection
            setupSteps={formState.setupSteps}
            onChange={(setupSteps) =>
              handleInputChange("setupSteps", setupSteps)
            }
          />
        </div>

        <div className="mt-4 flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatConfigurationTemplateForm;
