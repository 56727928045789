import React, { useState } from "react";

import { Portal } from "../../../models/Portal";
import { CommonModal } from "../../Common/CommonModal";
import { formatDate, formatTime } from "../../../utils/DateUtils";
import Icon from "../../Icon";
import { CommonInputSegment } from "../../Common/CommonInput";
import { ExternalPath } from "../../../models/ExternalPath";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { usePortalService } from "../../../contexts/PortalContext";

interface PortalNameModalProps {
  teamId: string;
  shows: boolean;
  setShows: (shows: boolean) => void;
  portal: Portal;
  setPortal: (portal: Portal) => void;
}

export const PortalNameModal: React.FC<PortalNameModalProps> = ({
  teamId,
  shows,
  setShows,
  portal,
  setPortal,
}) => {
  const [savePortalState, setSavePortalState] =
    useState<AnimationState>("ready");
  const [portalName, setPortalName] = useState(portal.name);
  const portalSerice = usePortalService();

  const handleSavePortal = async () => {
    setSavePortalState("loading");
    try {
      const newPortal: Portal = { ...portal, name: portalName };
      portalSerice.portalRepo.update(
        newPortal,
        portalSerice.portalPath(teamId),
        portal.id
      );
      setPortal(newPortal);
      setSavePortalState("success");
    } catch {
      setSavePortalState("error");
    }
  };

  const handleDismiss = () => {
    setPortalName(portal.name);
    setShows(false);
  };

  if (!shows) return null;

  return (
    <CommonModal isOpen={shows} onDismiss={handleDismiss}>
      <div className="bg-white rounded-lg gap-4 flex flex-col shadow-lg p-6 max-w-[544px]">
        <div className="flex justify-end text-gray-200 hover:text-gray-300">
          <button onClick={handleDismiss}>
            <Icon type="x" />
          </button>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex justify-between text-xs text-gray-500 gap-2">
            <span> Created</span>
            <span>{formatDate(portal.createdAt)}</span>
            <span>{formatTime(portal.createdAt)}</span>
          </div>
          <div
            id="portalId"
            className="flex justify-between text-xs text-gray-500 gap-2"
          >
            {`Portal ID: ${portal.id}`}
          </div>
        </div>
        <div className="text-center flex flex-col gap-1 mt-4 mb-4">
          <div className="font-gooper text-2xl font-semibold text-gray-800">
            Give your portal a name
          </div>
          <div className="font-normal text-gray-500">
            This is your home to manage the different versions of your prompts.
            Each portal can be integrated into your app and power an Ai feature.
          </div>
        </div>
        <CommonInputSegment
          title=""
          onChange={setPortalName}
          value={portalName}
          placeholder="Name your portal here"
          id="portalName"
        />
        <div className="flex flex-row justify-between">
          <div className="text-gray-500 text-xs">You can change this later</div>

          <a
            href={ExternalPath.portalDocs()}
            target="_blank"
            className="text-blue-400 hover:text-blue-500 text-xs underline"
          >
            Learn more about portals here
          </a>
        </div>
        <div className="flex flex-row justify-between">
          <AnimatedButton
            title={`Cancel`}
            onClick={handleDismiss}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
            classNameIn="w-40"
          />
          <AnimatedButton
            title={"Save Portal"}
            onClick={() => handleSavePortal()}
            buttonState={savePortalState}
            setButtonState={setSavePortalState}
            style={"action"}
            key="action"
            leftIcon={"portals"}
            font="font-sans"
            id="saveButton"
            classNameIn="w-40"
            after={(success) => {
              if (success) {
                handleDismiss();
              }
            }}
          />
        </div>
      </div>
    </CommonModal>
  );
};
