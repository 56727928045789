import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { TeamSettingsSections } from "./TeamSettingsSections";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import FailureModal from "../FailureModal";
import { CommonSpinner } from "../Common/CommonLoading";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { CommonCard } from "../Common/CommonCard";
import { CommonConfirmationModal } from "../Common/CommonConfirmationModal";
import { AppPath } from "../../models/AppPath";
import CommonSwitch from "../Common/CommonSwitch";

export const TeamSettingsTeamProfile: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const teamService = useTeams();
  const navigate = useNavigate();

  const [team, setTeam] = useState<Team>();
  const [deletePassword, setDeletePassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [newTeamState, setNewTeamState] = useState<AnimationState>("ready");
  const [updateState, setUpdateState] = useState<AnimationState>("ready");
  const [deleteState, setDeleteState] = useState<AnimationState>("ready");
  const [showDeleteConfirmation, setSHowDeleteConfirmation] = useState(false);

  const loadData = async () => {
    try {
      const fetchedTeam = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      if (fetchedTeam) {
        setTeam(fetchedTeam);
      }
    } catch (e) {
      setError("Failed to load team");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [teamService]);

  const handleUpdateTeam = async () => {
    setUpdateState("loading");
    try {
      await teamService.teamRepo.update(
        { ...team },
        teamService.teamPath(),
        teamId!
      );
      setUpdateState("success");
      loadData();
    } catch {
      setUpdateState("error");
    }
  };

  const handleDeleteTeam = async () => {
    setDeleteState("loading");
    try {
      await teamService.deleteTeam(teamId!);
      setDeleteState("success");
    } catch {
      setDeleteState("error");
    }
  };

  const handleCreateNewTeam = async () => {
    setNewTeamState("loading");
    try {
      navigate(`/newteam`);
      setNewTeamState("success");
    } catch {
      setNewTeamState("error");
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title={`Team Profile`}
        subtitle="Manage the details of your team here."
        sections={[
          { name: "Team Settings", link: AppPath.settings(teamId!) },
          { name: "Team Profile", link: AppPath.teamProfile(teamId!) },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title="Create new team"
            onClick={() => handleCreateNewTeam()}
            buttonState={newTeamState}
            setButtonState={setNewTeamState}
            style="action"
            key="newTeam"
            rightIcon="plus"
          />,
        ]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />

      <CommonConfirmationModal
        title="Are you sure?"
        message="This will delete your team FOREVER. There's no way to undo this."
        onCancel={() => setSHowDeleteConfirmation(false)}
        isOpen={showDeleteConfirmation}
        onConfirm={() => handleDeleteTeam()}
        confirmStyle="destructive"
      />
      <TeamSettingsSections selectedId="team-profile" />

      {loading ? (
        <CommonSpinner />
      ) : (
        <CommonCard padding="md">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-4 w-1/2">
              <InputSegment
                title="Team Name"
                onChange={(value) => setTeam({ ...team!, name: value })}
                value={team?.name ?? ""}
                placeholder="Your Team's name"
              />
              <div className="flex flex-col gap-2 text-sm text-gray-700 font-medium">
                Hide example data
                <CommonSwitch
                  isOn={team?.hideExamples ?? false}
                  onToggle={(n) => setTeam({ ...team!, hideExamples: n })}
                />
              </div>
            </div>
            <div className="items-end flex justify-end">
              <AnimatedButton
                title="Save Changes"
                onClick={() => handleUpdateTeam()}
                buttonState={updateState}
                setButtonState={setUpdateState}
                style="action"
                key="saveChanges"
                classNameIn="w-40"
              />
            </div>
          </div>
        </CommonCard>
      )}

      {loading ? (
        <CommonSpinner />
      ) : (
        <CommonCard padding="md">
          <div className="grid grid-cols-2">
            <InputSegment
              title={`Enter \"delete\" below and click button on the right to delete your team.`}
              onChange={setDeletePassword}
              value={deletePassword}
              placeholder="Deletion confirmation"
              type="text"
            />
            <div className="items-end flex justify-end">
              <AnimatedButton
                title="Delete Team"
                onClick={() => setSHowDeleteConfirmation(true)}
                buttonState={deleteState}
                setButtonState={setDeleteState}
                style="destructive"
                key="delete"
                leftIcon="trash"
                disabled={deletePassword !== "delete"}
                classNameIn="w-40"
              />
            </div>
          </div>
        </CommonCard>
      )}
    </CommonContainer>
  );
};

const InputSegment: React.FC<{
  title: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  type?: "password" | "email" | "text";
  placeholder?: string;
}> = ({ title, value, onChange, disabled, type, placeholder }) => {
  return (
    <div className="w-full gap-2 flex flex-col bg-gray-0 rounded-lg">
      <div className="text-gray-700 font-medium text-sm">{title}</div>
      <input
        className="border border-gray-300 focus:border-blue-500 rounded-md p-2 bg-gray-50 font-gooper leading-tight text-gray-700 text-sm focus:ring-0 focus:outline-none"
        value={value}
        onChange={(value) => onChange(value.target.value)}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};
