export const STORAGE_KEYS = {
  INVITED_TEAM_ID: "invitedTeamId",
  LAST_ACCESSED_TEAM: "lastAccessedTeam",
  SIDEBAR_COLLAPSED: "sidebarCollapsed",
  COMPLETED_API: "completedAPI",
  COMPLETED_PORTALS: "completePortals",
  COMPLETED_MEMBERS: "completeMembers",
  COOKIE_CONSENT: "cookieConsent",
  USER_HASH: "userHash",
};
