import React, { useEffect, useState } from "react";
import GetStartedCard from "./GetStartedCard";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { useNavigate } from "react-router-dom";
import { useTeams } from "../../contexts/TeamContext";

interface GetStartedAPIProps {
  teamId: string;
}

const GetStartedAPI: React.FC<GetStartedAPIProps> = ({ teamId }) => {
  const navigate = useNavigate();
  const teamService = useTeams();

  const [completed, setCompleted] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_API) == "true"
  );
  const [loading, setLoading] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_API) != "true"
  );

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEYS.COMPLETED_API) == "true") {
      return;
    }
    const loadData = async () => {
      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId
      );
      const secrets = team?.secretsUsed ?? {};
      const secretCount = Object.keys(secrets).length;
      const completed =
        secretCount > 1 ||
        localStorage.getItem(STORAGE_KEYS.COMPLETED_API) == "true";
      setCompleted(completed);
      setLoading(false);
      if (completed) {
        localStorage.setItem(STORAGE_KEYS.COMPLETED_API, "true");
      }
    };
    loadData();
  }, [teamService]);

  const handleAPIAction = () => {
    navigate(`/teams/${teamId}/settings/providers`);
    setCompleted(!completed);
  };
  return (
    <GetStartedCard
      sectionTitle="Ai"
      actionTitle="Set up your Ai"
      title="Connect your Ai providers"
      subtitle="This will take a minute and you will only have to do it once."
      number="3"
      iconType="api"
      completed={completed}
      completedMessage="Congrats!"
      action={() => handleAPIAction()}
      loading={loading}
    />
  );
};

export default GetStartedAPI;
