import React from "react";
import { AnalyticsData, Timespan } from "./Analytics";
import { AnalyticsLineChart } from "./AnalyticsLineChart";
import { AnalyticsBarChart } from "./AnalyticsBarChart";

export type Metric =
  | "duration"
  | "tokens"
  | "score"
  | "errors"
  | "cost"
  | "requestCount"
  | "latency";

export interface ChartData {
  date: number;
  [key: string]: number | string | null;
}

export type ChartType = "bar" | "line";

interface AnalyticsChartProps {
  analyticsData: AnalyticsData;
  versionIds: string[];
  metric: Metric;
  timespan: Timespan;
  type: ChartType;
  setType: (type: ChartType) => void;
}

const tailwindColors = ["#ff9800", "#ff99ff", "#A5BE00"];

const AnalyticsChart: React.FC<AnalyticsChartProps> = ({
  analyticsData,
  versionIds,
  metric,
  timespan,
  type,
  setType,
}) => {
  const processChartData = (): ChartData[] => {
    const data: { [timestamp: number]: { [versionId: string]: number[] } } = {};

    Object.entries(analyticsData ?? {}).forEach(([versionId, dailyStats]) => {
      dailyStats.forEach((stat) => {
        Object.entries(stat.stats?.hourlyStats ?? {}).forEach(
          ([hour, hourStats]) => {
            const year = stat.date.getUTCFullYear();
            const month = stat.date.getUTCMonth(); // Months are zero-based in JS
            const day = stat.date.getUTCDate();
            const timestamp = Date.UTC(year, month, day, parseInt(hour));

            if (!data[timestamp]) {
              data[timestamp] = {};
            }
            if (!data[timestamp][versionId]) {
              data[timestamp][versionId] = [];
            }

            switch (metric) {
              case "duration":
                data[timestamp][versionId].push(hourStats.averageDuration);
                break;
              case "tokens":
                data[timestamp][versionId].push(hourStats.tokens);
                break;
              case "score":
                if (hourStats.reviewCount > 0) {
                  data[timestamp][versionId].push(hourStats.score);
                }
                break;
              case "errors":
                const errorRate =
                  hourStats.requests > 0
                    ? ((hourStats.requests - (hourStats.errorCount ?? 0)) /
                        hourStats.requests) *
                      100
                    : 100;
                data[timestamp][versionId].push(errorRate);
                break;
              case "cost":
                data[timestamp][versionId].push(hourStats.cost);
                break;
              case "requestCount":
                data[timestamp][versionId].push(hourStats.requests);
                break;
              case "latency":
                data[timestamp][versionId].push(hourStats.averageLatency);
                break;
            }
          }
        );
      });
    });

    const allTimestamps = Object.keys(data)
      .map((date) => parseInt(date))
      .sort((a, b) => a - b);

    if (allTimestamps.length === 0) return [];

    const minTimestamp = Math.min(...allTimestamps);
    const maxTimestamp = Math.max(...allTimestamps);

    const completeData: ChartData[] = [];
    for (
      let timestamp = minTimestamp;
      timestamp <= maxTimestamp;
      timestamp += 3600000 // 1 hour in milliseconds
    ) {
      const entry: ChartData = { date: timestamp };
      versionIds.forEach((versionId) => {
        const foundData = data[timestamp]?.[versionId]
          ? data[timestamp][versionId].reduce((sum, value) => sum + value, 0) /
            data[timestamp][versionId].length
          : null;
        entry[versionId] = foundData;
      });
      completeData.push(entry);
    }
    return completeData;
  };

  const chartData = processChartData();

  const calculateTimeframeAverage = (): string => {
    const dateMin = new Date();
    const daysAgo = (): number => {
      switch (timespan) {
        case "7-Days":
          return 7;
        case "30-Days":
          return 30;
        case "60-Days":
          return 60;
      }
    };
    dateMin.setDate(dateMin.getDate() - daysAgo());

    const timeframeData = chartData.filter((d) => new Date(d.date) >= dateMin);

    if (timeframeData.length === 0) {
      return "N/A";
    }

    let totalSum = 0;
    let totalCount = 0;

    timeframeData.forEach((hourData) => {
      versionIds.forEach((id) => {
        const value = hourData[id];
        if (typeof value === "number" && !isNaN(value)) {
          totalSum += value;
          totalCount++;
        }
      });
    });

    if (totalCount === 0) {
      if (metric === "errors") {
        return "100%";
      }
      return "N/A";
    }

    // For request count, we want the total sum rather than the average
    const value = metric === "requestCount" ? totalSum : totalSum / totalCount;

    switch (metric) {
      case "duration":
        return `${value.toFixed(0)}ms`;
      case "tokens":
        return `${totalSum.toFixed(0)}`;
      case "errors":
        return `${value.toFixed(0)}%`;
      case "cost":
        return `$${totalSum.toFixed(4)}`;
      case "score":
        return value.toFixed(2);
      case "requestCount":
        return value.toLocaleString();
      case "latency":
        return `${value.toFixed(0)}ms`;
      default:
        return "N/A";
    }
  };

  const calculatePretitle = (): string => {
    if (metric === "tokens" || metric === "requestCount" || metric == "cost") {
      return `${timespan} total`;
    }
    return `${timespan} average`;
  };

  const calculateTitle = (): string => {
    switch (metric) {
      case "duration":
        return "Duration";
      case "tokens":
        return "Tokens";
      case "errors":
        return "Error Free";
      case "cost":
        return "Cost";
      case "score":
        return "Score";
      case "requestCount":
        return "Requests";
      case "latency":
        return "Latency";
      default:
        return "";
    }
  };

  const average = calculateTimeframeAverage();
  const pretitle = calculatePretitle();

  return (
    <div
      id={`${metric}Chart`}
      className="flex flex-col bg-gray-0 rounded-lg py-4 px-6 border border-gray-200 gap-4"
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1">
          <div className="text-sm text-gray-500">{pretitle}</div>
          <div className="text-3xl font-gooper text-gray-800">
            {calculateTitle()}
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-blue-500 text-4xl font-medium font-gooper">
            {average}
          </div>
        </div>
      </div>
      {type === "bar" ? (
        <AnalyticsBarChart
          chartData={chartData}
          hexColors={tailwindColors}
          metric={metric}
          versionIds={versionIds}
          timespan={timespan}
        />
      ) : (
        <AnalyticsLineChart
          chartData={chartData}
          hexColors={tailwindColors}
          metric={metric}
          versionIds={versionIds}
        />
      )}
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-2">
          {versionIds.map((v, index) => (
            <div key={index} className="flex flex-row items-center gap-1">
              <div
                style={{
                  backgroundColor:
                    tailwindColors[index % tailwindColors.length],
                }}
                className="rounded-full size-2"
              />
              <div className="text-sm text-gray-600">{`Version ${v}`}</div>
            </div>
          ))}
        </div>
        <select
          value={type}
          onChange={(e) => setType(e.target.value as ChartType)}
          className="bg-gray-100 border-none text-gray-500 py-1 px-2 rounded leading-tight"
        >
          <option value="bar">Bar Chart</option>
          <option value="line">Line Chart</option>
        </select>
      </div>
    </div>
  );
};

export default AnalyticsChart;
