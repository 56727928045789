import React from "react";
import { TestRun } from "../../models/TestRun";
import Pagination from "./Pagination";
import { CommonSpinner } from "../Common/CommonLoading";
import { StatusBadge } from "./StatusBadge";

export const TestRunTable: React.FC<{
  testRuns: TestRun[];
  handleTestRunClick: (testRun: TestRun) => void;
  currentPage: number;
  selectedPage: (direction: "prev" | "next") => void;
  hasMore: boolean;
  loading: boolean;
}> = ({
  testRuns,
  handleTestRunClick,
  currentPage,
  selectedPage,
  hasMore,
  loading,
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="font-gooper text-xl text-gray-700">Test Results</div>
        <div className="text-sm text-gray-500">
          Results stream in live. Click to see more information.
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center">
          <CommonSpinner />
        </div>
      ) : (
        <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
          <tbody>
            {testRuns?.map((testRun) => {
              return (
                <tr className="bg-gray-0 " key={testRun.id}>
                  <td className="border border-gray-200">
                    <div className="p-3 flex">
                      <div className="text-sm text-gray-700 font-medium flex lg:w-1/2 w-1/3">
                        {testRun.testName}
                      </div>
                      <div className="grid grid-cols-3 w-2/3 lg:w-1/2">
                        <div className="text-xs text-gray-500 flex items-center">
                          {testRun.versionName}
                        </div>
                        <div className="flex justify-center">
                          <StatusBadge status={testRun.status} />
                        </div>

                        <button
                          onClick={() => handleTestRunClick(testRun)}
                          className="font-gooper text-xs text-blue-500 hover:text-blue-700 font-medium flex justify-end items-center"
                        >
                          Results
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Pagination
        currentPage={currentPage}
        hasMore={hasMore}
        onPageChange={selectedPage}
      />
    </div>
  );
};
