import React, { useEffect, useState } from "react";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { Team, TeamRole } from "../../models/Team";
import { capitalizeFirstLetter } from "../../utils/StringUtils";
import { useTeams } from "../../contexts/TeamContext";
import { useAuth } from "../../contexts/AuthContext";
import { ExternalPath } from "../../models/ExternalPath";

interface TeamSettingsMemberChangeRoleModalInput {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  role: TeamRole;
  setRole: (role: TeamRole) => void;
  memberName: string;
  teamId: string;
  memberId: string;
}

export const TeamSettingsMemberChangeRoleModal: React.FC<
  TeamSettingsMemberChangeRoleModalInput
> = ({
  isOpen,
  onConfirm,
  onCancel,
  role,
  setRole,
  memberName,
  teamId,
  memberId,
}) => {
  const teamService = useTeams();
  const authService = useAuth();

  const [confirmState, setConfirmState] = useState<AnimationState>("ready");
  const [team, setTeam] = useState<Team>();
  const [currentUserRole, setCurrentUserRole] = useState<TeamRole>();

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedTeam = await teamService.teamRepo.get(
          teamService.teamPath(),
          teamId
        );
        if (!fetchedTeam) {
          return;
        }
        setTeam(fetchedTeam);
        setCurrentUserRole(
          (fetchedTeam.members ?? {})[authService.userId!].role
        );
      } catch (e) {
        setConfirmState("error");
      }
    };
    loadData();
  }, [teamService]);
  const members = team?.members ?? {};
  const hasMultipleAdmins =
    Object.keys(team?.members ?? {})
      .map((key) => members[key].role)
      .filter((role) => role == "admin").length > 1;

  const isCurrentUser = authService.userId == memberId;

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[496px]">
        <h2 className="text-2xl font-bold mb-4 font-gooper text-center text-gray-900">
          Update Role
        </h2>
        <p className="mb-6 text-center text-gray-500 text-base font-normal">
          {`Update ${memberName}'s role to something more fitting.`}
        </p>
        <div className="flex flex-col gap-4">
          <RoleInput
            role="admin"
            description="Full control over settings, user permissions, and content management."
            setRole={setRole}
            currentRole={role}
            disabled={
              currentUserRole != "admin" ||
              (!hasMultipleAdmins && isCurrentUser)
            }
          />
          <RoleInput
            role="editor"
            description="Edit and refine content for accuracy and clarity."
            setRole={setRole}
            currentRole={role}
            disabled={
              currentUserRole != "admin" ||
              (!hasMultipleAdmins && isCurrentUser)
            }
          />
          <RoleInput
            role="tester"
            description="Create tests and non-destructive actions."
            setRole={setRole}
            currentRole={role}
            disabled={
              currentUserRole != "admin" ||
              (!hasMultipleAdmins && isCurrentUser)
            }
          />
          <RoleInput
            role="viewer"
            description="Can see test output and view data and statistics."
            setRole={setRole}
            currentRole={role}
            disabled={
              currentUserRole != "admin" ||
              (!hasMultipleAdmins && isCurrentUser)
            }
          />
        </div>
        <p className="text-xs text-gray-500 font-normal pt-4 pb-4">
          Read more about roles and restrictions on {``}
          <a
            href={ExternalPath.blog()}
            className="text-blue-500 hover:underline"
            target="_blank"
          >
            the blog here.
          </a>
        </p>
        <div className="grid grid-cols-2 w-full gap-2 pt-4">
          <AnimatedButton
            title={`Cancel`}
            onClick={() => onCancel()}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
          />
          <AnimatedButton
            title={`Save`}
            onClick={() => onConfirm()}
            buttonState={confirmState}
            setButtonState={setConfirmState}
            style={"action"}
            key="action"
            font="font-sans"
            id="confirmButton"
          />
        </div>
      </div>
    </div>
  );
};

const RoleInput: React.FC<{
  currentRole: TeamRole;
  role: TeamRole;
  description: string;
  disabled: boolean;
  setRole: (role: TeamRole) => void;
}> = ({ currentRole, role, description, setRole, disabled }) => {
  const isSelected = currentRole == role;
  return (
    <div className="flex flex-row gap-2 items-top">
      <div>
        <input
          type="radio"
          checked={isSelected}
          onChange={() => setRole(role)}
          disabled={disabled}
          className="size-4 bg-gray-100 border border-gray-300 checked:bg-blue-500"
          id={`${role}Button`}
        />
      </div>
      <div className="flex flex-col">
        <div
          className={`${
            isSelected
              ? "text-blue-500"
              : disabled
              ? "text-gray-300"
              : "text-gray-900"
          } font-gooper text-sm`}
        >
          {capitalizeFirstLetter(role)}
        </div>
        <div
          className={`${
            disabled ? "text-gray-300" : "text-gray-500"
          } font-normal text-xs`}
        >
          {description}
        </div>
      </div>
    </div>
  );
};
