import React, { ReactNode, useEffect, useState } from "react";
import CommonHeader from "../../Common/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Portal } from "../../../models/Portal";
import { usePortalService } from "../../../contexts/PortalContext";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import CommonContainer from "../../Common/CommonContainer";
import { CommonLoading } from "../../Common/CommonLoading";
import FailureModal from "../../FailureModal";
import { PortalVersion } from "../../../models/PortalVersion";
import { VersionCard } from "./VersionCard";

interface VersionHistoryProps {}

const VersionHistory: React.FC<VersionHistoryProps> = ({}) => {
  const { teamId, portalId } = useParams<{
    teamId: string;
    portalId: string;
  }>();

  const portalService = usePortalService();
  const navigate = useNavigate();

  const [portal, setPortal] = useState<Portal>();
  const [versions, setVersions] = useState<PortalVersion[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [newVersionState, setnewVersionState] =
    useState<AnimationState>("ready");

  const loadData = async () => {
    const getPortal = portalService.portalRepo.get(
      portalService.portalPath(teamId!),
      portalId!
    );
    const getVersions = await portalService.portalVersionRepo.getList(
      portalService.portalVersionPath(teamId!, portalId!),
      { name: "modifiedAt", descending: true },
      undefined,
      200
    );

    await Promise.all([getPortal, getVersions])
      .then(([fetchedPortal, fetchedVersions]) => {
        if (!fetchedPortal) {
          setError("No portal found!");
          return;
        }
        setPortal(fetchedPortal);
        let organizedVersions = fetchedVersions.filter(
          (v) => v.id != fetchedPortal.currentVersionId
        );
        if (fetchedPortal.currentVersionData) {
          organizedVersions = [
            fetchedPortal.currentVersionData,
            ...organizedVersions,
          ];
        }
        setVersions(organizedVersions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, [teamId, portalId]);

  const handleNewVersion = async () => {
    setnewVersionState("loading");
    const newVersion = await portalService.createVersion(teamId!, portalId!);
    setnewVersionState("success");
    navigate(`/teams/${teamId!}/portals/${portalId!}/version/${newVersion.id}`);
  };

  const handleSelectedVersion = async (version: PortalVersion) => {
    navigate(`/teams/${teamId!}/portals/${portalId!}/version/${version.id}`);
  };

  const actions: ReactNode[] = [
    <AnimatedButton
      title={`New Version`}
      onClick={() => handleNewVersion()}
      buttonState={newVersionState}
      setButtonState={setnewVersionState}
      style={"action"}
      key="version"
    />,
  ];

  return (
    <CommonContainer>
      {loading && <CommonLoading />}
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to Portals"}
        shows={error != ""}
        closed={() => setError("")}
      />
      <CommonHeader
        title={`${portal?.name}: Version History`}
        subtitle="Where all your versions live. We put your live version first so you don't have to dig."
        sections={[
          { name: "Portals", link: `/teams/${teamId}/portals` },
          {
            name: `${portal?.name}`,
            link: `/teams/${teamId}/portals/${portalId!}/versions`,
          },
          { name: `Version History`, link: `` },
        ]}
        teamId={teamId!}
        actions={actions}
      />
      {portal && (
        <div className="flex justify-center">
          <div className="flex flex-wrap gap-4">
            {versions.map((version) => (
              <VersionCard
                key={version.id}
                version={version}
                selectedVersion={handleSelectedVersion}
                portal={portal}
                teamId={teamId!}
              />
            ))}
          </div>
        </div>
      )}
    </CommonContainer>
  );
};

export default VersionHistory;
