import React from "react";

interface CommonUnderlineButtonProps {
  title: string;
  onClick: () => void;
}

export const CommonUnderlineButton: React.FC<CommonUnderlineButtonProps> = ({
  title,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className="text-blue-400 text-xs underline font-normal hover:text-blue-500"
    >
      {title}
    </button>
  );
};
