export const AwaitingResultCard: React.FC<{}> = ({}) => {
  return (
    <div className="h-24 flex flex-col rounded-lg bg-gray-50 p-2 gap-2 border border-gray-200">
      <div className="flex flex-row justify-between"></div>
      <div className="text-2xl font-gooper text-gray-900">
        {"Click preview to get started"}
      </div>
    </div>
  );
};
