import React, { useState } from "react";
import AnimatedButton, {
  AnimatedButtonStyle,
  AnimationState,
} from "../AnimatedButton";
import { IconType } from "../Icon";
import { CommonModal } from "./CommonModal";

interface CommonTypedConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: (password: string) => void;
  onCancel: () => void;
  confirmStyle?: AnimatedButtonStyle;
  confirmIcon?: IconType;
  confirmationPhrase: string;
  requireMatch?: boolean;
  isSecure?: boolean;
  hideText?: boolean;
  confirmationState?: AnimationState;
}

export const CommonTypedConfirmationModal: React.FC<
  CommonTypedConfirmationModalProps
> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  confirmStyle,
  confirmIcon,
  confirmationPhrase,
  requireMatch,
  isSecure,
  hideText,
  confirmationState,
}) => {
  if (!isOpen) return null;

  const [confirmState, setConfirmState] = useState<AnimationState>("ready");
  const [text, setText] = useState("");

  return (
    <CommonModal isOpen={isOpen} onDismiss={onCancel}>
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-2xl font-bold mb-4 font-gooper text-center text-gray-900">
          {title}
        </h2>
        <p className="mb-6 text-center text-gray-500">{message}</p>

        {hideText != true && (
          <input
            className={`border-gray-300 focus:border-blue-500 bg-gray-50 text-gray-700 w-full mb-4 border rounded-md p-2 leading-tight text-sm focus:ring-0 focus:outline-none h-10`}
            value={text}
            onChange={(value) => setText(value.target.value)}
            placeholder={`Type ${confirmationPhrase} & click confirm if you're sure.`}
            id={"confirmationInput"}
            type={isSecure ? "password" : "text"}
          />
        )}
        <div className="grid grid-cols-2 w-full gap-2">
          <AnimatedButton
            title={`Cancel`}
            onClick={() => onCancel()}
            buttonState={confirmationState ?? "ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
          />
          <AnimatedButton
            title={`Confirm`}
            onClick={() => onConfirm(text)}
            buttonState={confirmState}
            setButtonState={setConfirmState}
            style={confirmStyle ?? "action"}
            key="action"
            leftIcon={confirmIcon}
            font="font-sans"
            disabled={!requireMatch ? false : text != confirmationPhrase}
            id="confirmButton"
          />
        </div>
      </div>
    </CommonModal>
  );
};
