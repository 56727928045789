import React from "react";
import { Tooltip } from "./Tooltip";
import Icon from "../../../Icon";

interface ConfigSliderProps {
  name: string;
  description: string;
  shortDescription: string;
  min: number;
  max: number;
  rangeLabels: string[];
  value: number;
  onChange: (value: number) => void;
}

const ConfigSlider: React.FC<ConfigSliderProps> = ({
  name,
  description,
  shortDescription,
  min,
  max,
  value,
  rangeLabels,
  onChange,
}) => {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    onChange(newValue);
  };

  const thumbStyle = {
    left: `calc(${((value - min) / (max - min)) * 96}% )`, // Adjusting by half the width of the thumb label for centering
  };

  const rounding = max > 10 ? 0 : 2;

  return (
    <div className="flex flex-col justify-start items-start gap-1">
      <div className="flex flex-row gap-1 items-center">
        <div className={`text-sm font-gooper text-gray-700`}>{name}</div>
        <Tooltip title={name} description={description}>
          <svg
            data-tooltip-target="tooltip-default"
            className="w-3 h-3 fill-current text-gray-300"
            viewBox="0 0 24 24"
          >
            <Icon type={"info"} />
          </svg>
        </Tooltip>
      </div>
      <div className="text-gray-500 text-xs">{shortDescription}</div>
      <div className="relative w-full">
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={handleSliderChange}
          className="w-full h-2 bg-gray-100 rounded appearance-none cursor-pointer"
          style={{
            backgroundSize: `${((value - min) / (max - min)) * 100}% 100%`,
          }}
          step="any"
        />
        <div
          className="absolute font-gooper pointer-events-none top-0 text-center text-white text-xs font-normal bg-blue-600 rounded-full w-8 h-8 flex items-center justify-center shadow"
          style={thumbStyle}
        >
          {value.toFixed(rounding)}
        </div>
      </div>
      <div className="w-full flex justify-between items-center text-gray-400 text-xs">
        {rangeLabels.map((label, index) => (
          <div key={index} className="text-center">
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConfigSlider;
