import React, { useState, useEffect } from "react";
import { PortalLog } from "../../models/PortalLog";
import { usePortalLogService } from "../../contexts/PortalLogContext";
import CommonHeader from "../Common/CommonHeader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { CommonSpinner } from "../Common/CommonLoading";
import Icon from "../Icon";
import FailureModal from "../FailureModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { LogModal } from "./LogModal";
import { useTeams } from "../../contexts/TeamContext";
import { CommonNoData } from "../Common/CommonNoData";
import { filterExampleLogs } from "../../utils/FilterUtils";

interface PortalLogsProps {}

const PortalLogs: React.FC<PortalLogsProps> = ({}) => {
  const { teamId, logId } = useParams<{ teamId: string; logId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const timestamp = searchParams.get("time")
    ? Number(searchParams.get("time"))
    : undefined;

  const portalLogService = usePortalLogService();
  const teamService = useTeams();
  const navigate = useNavigate();

  const [logs, setLogs] = useState<PortalLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [nextState, setNextState] = useState<AnimationState>("ready");
  const [backState, setBackState] = useState<AnimationState>("ready");
  const [error, setError] = useState("");

  const limit = 50;

  const loadData = async () => {
    try {
      setLoading(true);
      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      const logs = await portalLogService.portalLogRepo.getList(
        portalLogService.portalLogPath(teamId!),
        { name: "createdAt", descending: true },
        undefined,
        limit,
        timestamp ? new Date(timestamp) : undefined
      );
      setLogs(logs.filter((log) => filterExampleLogs(team ?? undefined, log)));
    } catch (e) {
      setError(e instanceof Error ? e.message : "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [timestamp]);

  const handleNextPage = () => {
    const startAfter = logs[logs.length - 1].createdAt;
    setSearchParams({
      time: `${startAfter.getTime()}`,
    });
    setLogs([]);
  };
  const handleBack = () => {
    setLogs([]);
    navigate(-1);
  };

  const handleRowClick = (log: PortalLog) => {
    navigate(`${log.id}`);
  };

  return (
    <CommonContainer>
      <CommonHeader
        title={`Logs`}
        subtitle="Observe everything that your users see. Ai that's not a black box."
        sections={[{ name: "Logs", link: "" }]}
        teamId={teamId!}
        actions={[]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      <LogModal
        teamId={teamId!}
        logId={logId}
        onClose={() => navigate(`/teams/${teamId}/logs`)}
      />
      <div className="">
        <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
          <thead className="border-b border-gray-200">
            <tr className="">
              <Header title="Status" className="hidden md:table-cell" />
              <Header title="Portal / Workflow" />
              <Header title="Version Name" className="hidden md:table-cell " />
              <Header title="Created" />
              <Header title="User ID" className="hidden md:table-cell" />
              <Header title="Tokens" className="hidden lg:table-cell" />
              <Header title="Config ID" />
            </tr>
          </thead>
          {loading && (
            <tbody>
              <tr className="bg-gray-0 hover:bg-gray-100">
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b  border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b  border-gray-200 p-3 font-medium text-sm hidden lg:table-cell">
                  <CommonSpinner />
                </td>
                <td className="border-b  border-gray-200 p-3 font-medium text-sm hidden lg:table-cell">
                  <CommonSpinner />
                </td>
                <td className="border-b  border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
                  <CommonSpinner />
                </td>
                <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {logs.map((log) => {
              return (
                <tr
                  key={log.id}
                  className={`hover:bg-gray-100 ${
                    log.status == "ERROR"
                      ? "bg-yellow-50"
                      : log.status == "SECURITY_WARNING"
                      ? "bg-red-50"
                      : "bg-gray-0"
                  }`}
                  onClick={() => handleRowClick(log)}
                >
                  <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
                    <Icon
                      className={`${
                        log.status == "ERROR"
                          ? "text-yellow-300"
                          : log.status == "SECURITY_WARNING"
                          ? "text-red-300"
                          : "text-green-300"
                      }`}
                      type={
                        log.status == "ERROR"
                          ? "alert"
                          : log.status == "SECURITY_WARNING"
                          ? "shield"
                          : "check"
                      }
                    />
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm">
                    {log.portalName ?? log.workflowName}
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
                    {log.versionName}
                  </td>
                  <td className="border-b p-3 text-sm text-gray-500 font-medium">
                    {new Date(log.createdAt)
                      .toLocaleDateString("en-GB", {
                        second: "numeric",
                        minute: "numeric",
                        hour: "numeric",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(",", "")}
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm hidden md:table-cell">
                    {log.userId}
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm hidden lg:table-cell">
                    {log.tokensUsed}
                  </td>
                  <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                    {log.configId}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row justify-between">
        <AnimatedButton
          title="Back"
          onClick={handleBack}
          buttonState={backState}
          setButtonState={setBackState}
          style="action"
          font="font-sans"
          classNameIn={`${timestamp ? "" : "invisible"}`}
        />
        <AnimatedButton
          title="Next"
          onClick={handleNextPage}
          buttonState={nextState}
          setButtonState={setNextState}
          style="action"
          font="font-sans"
          classNameIn={`${logs.length == limit ? "" : "invisible"}`}
        />
      </div>
      {logs.length == 0 && loading == false && (
        <CommonNoData teamId={teamId ?? ""} />
      )}
    </CommonContainer>
  );
};

export default PortalLogs;

const Header: React.FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  return (
    <th
      className={`p-3 text-left bg-gray-50 p4 text-xs font-medium text-gray-500 uppercase tracking-widest ${className}`}
    >
      {title}
    </th>
  );
};
