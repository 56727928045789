import React, { useState, useEffect } from "react";
import FailureModal from "../FailureModal";
import TeamAddName from "./TeamAddName";
import { TeamRole, TeamRoles } from "../../models/Team";
import TeamAddMembers from "./TeamAddMembers";
import {
  PaymentOption,
  SubscriptionData,
  SubscriptionOption,
} from "../../models/SubscriptionOption";
import TeamAddSubscription from "./TeamAddSubscription";
import { useTeams } from "../../contexts/TeamContext";
import { useAuth } from "../../contexts/AuthContext";
import { InProgressTeam, User } from "../../models/User";
import { useUserEvent } from "../../contexts/UserEventContext";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS } from "../../utils/StorageKeys";

export enum TeamOnboardingStep {
  Name,
  Members,
  Subscriptions,
}

export type EnteredMembers = { email: string; role: TeamRole }[];

const TeamOnboarding: React.FC = () => {
  const teamService = useTeams();
  const authService = useAuth();
  const events = useUserEvent();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(TeamOnboardingStep.Name);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [members, setMembers] = useState<EnteredMembers>([]);
  const [subscriptionData, setSubscriptionData] = useState<
    SubscriptionData | undefined
  >(undefined);
  const [user, setUser] = useState<User>();

  const backAction = !user?.teams
    ? undefined
    : async () => {
        await authService.userRepo.update(
          { inProgressTeam: undefined },
          authService.userPath(),
          user.id
        );
        const lastTeamId = localStorage.getItem(
          STORAGE_KEYS.LAST_ACCESSED_TEAM
        );
        if (!lastTeamId) {
          return;
        }
        navigate(`/teams/${lastTeamId}/home`);
      };

  useEffect(() => {
    authService.currentUser().then(async (user) => {
      const inProgress = user?.inProgressTeam;
      if (inProgress) {
        setTeamId(inProgress.id ?? "");
        setTeamName(inProgress.name ?? "");
        setSubscriptionData(inProgress.subscriptionData);
        setUser(user);

        if (!inProgress.name) {
          setCurrentStep(TeamOnboardingStep.Name);
        } else if (!inProgress.subscriptionData) {
          setCurrentStep(TeamOnboardingStep.Subscriptions);
        } else if (inProgress.subscriptionData?.subscription.seats > 1) {
          const foundMembers = membersFromSub(
            inProgress.subscriptionData.subscription!
          );
          setMembers(foundMembers);
          setCurrentStep(TeamOnboardingStep.Members);
        }
      }
    });
  }, [authService, teamService]);

  const processName = async (name: string) => {
    setTeamName(name);
  };

  const processMembers = async (newMembers: EnteredMembers) => {
    setMembers(newMembers);
    const filteredMembers = newMembers.filter((m) => m.email);
    try {
      await startStripe(filteredMembers, subscriptionData!);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went horribly wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const membersFromSub = (sub: SubscriptionOption): EnteredMembers => {
    return Array.from({ length: sub.seats - 1 }, () => ({
      email: "",
      role: TeamRoles.EDITOR,
    }));
  };

  const processSubscription = (
    newSub: SubscriptionOption,
    newPrice: PaymentOption
  ) => {
    setSubscriptionData({ subscription: newSub, price: newPrice });
    const newMembers = membersFromSub(newSub);
    members
      .filter((m) => m.email)
      .slice(0, newMembers.length - 1)
      .forEach((m, index) => {
        newMembers[index] = m;
      });
    setMembers(newMembers);
    if (newSub.seats < 2) {
      try {
        startStripe([], { subscription: newSub, price: newPrice });
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError("Something went horribly wrong");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updateTeam = async () => {
    await teamService.teamRepo.update(
      { name: teamName },
      teamService.teamPath(),
      teamId
    );
  };

  const startStripe = async (
    members: EnteredMembers,
    subscriptionData: SubscriptionData
  ) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      let newTeamId = teamId;
      if (teamId) {
        await updateTeam();
      } else {
        const newTeam = await teamService.createTeam(teamName);
        await authService.userRepo.update(
          { currentTeam: newTeam.id! },
          authService.userPath(),
          authService.userId!
        );
        events.createEvent(authService, "TeamCreated");
        setTeamId(newTeam.id!);
        newTeamId = newTeam.id!;
      }
      const user = await authService.currentUser();
      if (members) {
        const inviteOps = members.map((m) =>
          teamService.invite(newTeamId, m.email, m.role, window.origin)
        );
        await Promise.all(inviteOps);
      }
      const url = await teamService.purchase(
        subscriptionData!.price.priceId,
        newTeamId,
        window.location.origin,
        user!.email!
      );
      await authService.refreshToken();
      const inProgressTeam: InProgressTeam = {
        id: newTeamId,
        subscriptionData: subscriptionData,
        members: members,
        name: teamName,
      };
      await authService.userRepo.update(
        { inProgressTeam },
        authService.userPath(),
        authService.userId!
      );
      window.location.href = url;
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went horribly wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case TeamOnboardingStep.Name:
        return (
          <TeamAddName
            gotName={processName}
            progress={70}
            setStep={setCurrentStep}
            name={teamName}
            setName={setTeamName}
            goBack={backAction}
          />
        );
      case TeamOnboardingStep.Members:
        return (
          <TeamAddMembers
            isLoading={isLoading}
            gotMembers={processMembers}
            progress={80}
            subscription={subscriptionData!.subscription}
            teamMembers={members}
            setTeamMembers={setMembers}
            setStep={setCurrentStep}
          />
        );
      case TeamOnboardingStep.Subscriptions:
        return (
          <TeamAddSubscription
            gotSubscription={processSubscription}
            progress={90}
            setStep={setCurrentStep}
            isTopLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to onboarding"}
        shows={error != ""}
        closed={() => setError("")}
      />
      {renderStep()}
    </>
  );
};

export default TeamOnboarding;
