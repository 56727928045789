import { Portal } from "../models/Portal";
import { PortalLog } from "../models/PortalLog";
import { Team } from "../models/Team";

export const filterExamplePortals = (
  team: Team | undefined,
  portal: Portal
): boolean => {
  if (team?.hideExamples == undefined) {
    return true;
  }
  if (team.hideExamples == true) {
    return !portal.isExample;
  } else {
    return true;
  }
};

export const filterExampleLogs = (
  team: Team | undefined,
  log: PortalLog
): boolean => {
  if (team?.hideExamples == undefined) {
    return true;
  }
  if (team.hideExamples == true) {
    return !log.isExample;
  } else {
    return true;
  }
};
