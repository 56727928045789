import { Mention, MentionsInput } from "react-mentions";
import Icon from "../../../Icon";
import React, { useRef, useCallback, useEffect } from "react";
import { InputStyle } from "./InputStyle";
import { AppPath } from "../../../../models/AppPath";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";

export const PromptInput: React.FC<{
  variables: string[];
  prompt: string;
  updated: (text: string) => void;
  addedVariable: (newVariable: string) => void;
  teamId: string;
}> = ({ variables, prompt, updated, addedVariable, teamId }) => {
  const searchRef = useRef("");
  const mentionsInputRef = useRef<HTMLTextAreaElement>(null);
  const cursorPositionRef = useRef<number | null>(null);
  const displayId = "ADD_MORE_DISPLAY_BOX";

  const handleTextChange = useCallback(
    (_: { target: { value: string } }, newValue: string) => {
      const emptyMarker = `@[](${displayId})`;
      const newVariable = `@[@${searchRef.current}](${searchRef.current})`;

      if (newValue.includes(emptyMarker)) {
        addedVariable(searchRef.current);
        newValue = newValue.replace(emptyMarker, newVariable);
      }

      // Store the current cursor position
      const inputElement = mentionsInputRef.current;
      if (inputElement) {
        cursorPositionRef.current = inputElement.selectionStart;
      }

      updated(newValue);
    },
    [addedVariable, updated]
  );

  useEffect(() => {
    // Restore the cursor position after the component updates
    if (mentionsInputRef.current && cursorPositionRef.current !== null) {
      mentionsInputRef.current.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }
  });

  const handleAddVariable = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    addedVariable(searchRef.current);
    const newTag = ` @[@${searchRef.current}](${searchRef.current}) `;
    const newText = prompt.replace(` @${searchRef.current!}`, newTag);
    updated(newText);
    mentionsInputRef.current?.focus();
  };

  const handleMentionAdd = useCallback((id: string | number) => {
    if (cursorPositionRef.current !== null) {
      const idLength = id.toString().length;
      cursorPositionRef.current += idLength;
    }
  }, []);

  const data = variables.map((v) => {
    return { id: v, display: `@${v}` };
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-gooper font-medium text-gray-500">
          Prompt
        </div>
        <CommonUnderlineButton
          title="Go to Variables"
          onClick={() => window.open(AppPath.variables(teamId), "_blank")}
        />
      </div>
      <div className={`border-gray-300 bg-gray-50 rounded-lg border`}>
        <MentionsInput
          id="promptInput"
          style={InputStyle}
          value={prompt}
          onChange={handleTextChange}
          placeholder="Start writing your first prompt. Use @variable to create variables that you can fill in with your user data."
          customSuggestionsContainer={(children: React.ReactNode) => {
            return (
              <div
                id="suggestionsList"
                className="bg-gray-0 rounded-lg shadow-md overflow-hidden"
              >
                {children}
              </div>
            );
          }}
          inputRef={mentionsInputRef}
          className="min-h-96"
        >
          <Mention
            style={{ backgroundColor: "#E3EBB0", borderRadius: 4 }}
            appendSpaceOnAdd={true}
            trigger="@"
            data={(search) => {
              const filtered = data.filter((d) =>
                d.id.toLowerCase().startsWith(search.toLowerCase())
              );
              if (search.trim() != "") {
                filtered.push({ id: displayId, display: "" });
              }
              searchRef.current = search;
              return filtered;
            }}
            onAdd={handleMentionAdd}
            renderSuggestion={(data) => {
              if (data.id == displayId) {
                return (
                  <button
                    onClick={handleAddVariable}
                    className="flex hover:bg-blue-50 flex-row items-center gap-2 text-blue-600 px-4 py-2 fill-blue-600 border-t border-gray-200"
                  >
                    <Icon type="puzzle-piece" className="size-3" />
                    <div className="text-blue-600 text-sm">
                      {`Add variable ${searchRef.current}`}
                    </div>
                  </button>
                );
              }
              return <div className="px-4 py-2">{data.display}</div>;
            }}
          />
        </MentionsInput>
      </div>
    </div>
  );
};
