import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { CommonConfirmationModal } from "../../Common/CommonConfirmationModal";
import FailureModal from "../../FailureModal";
import { AppPath } from "../../../models/AppPath";
import { Workflow, WorkflowVersion } from "../../../models/Workflow";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { WorkflowVersionsModal } from "./WorkflowVersionsModal";

export const WorkflowActionMenu: React.FC<{
  teamId: string;
  workflow: Workflow;
  version: WorkflowVersion;
  shows: boolean;
  setShows: (show: boolean) => void;
  setWorkflowVersion: (version: WorkflowVersion) => void;
  setWorkflow: (workflow: Workflow) => void;
  isLive: boolean;
  setShowNameModal: (shows: boolean) => void;
}> = ({
  workflow,
  version,
  teamId,
  setShows,
  shows,
  isLive,
  setWorkflowVersion,
  setShowNameModal,
}) => {
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const workflowService = useWorkflowService();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [deleteVersionState, setDeleteVersionState] =
    useState<AnimationState>("ready");
  const [deleteWorkflowState, setDeleteWorkflowState] =
    useState<AnimationState>("ready");

  const [deleteVersionModal, setDeleteVersionModal] = useState(false);
  const [deleteWorkflowModal, setDeleteWorkflowModal] = useState(false);
  const [versionsModal, setVersionsModal] = useState(false);

  const [duplicateState, setDuplicateState] = useState<AnimationState>("ready");

  const [error, setError] = useState("");

  // const showNameModal = searchParams.get("showNameModal") === "true";

  // const handleSetShowNameModal = (show: boolean) => {
  //   const newSearchParams = new URLSearchParams(searchParams);
  //   if (show) {
  //     newSearchParams.set("showNameModal", "true");
  //   } else {
  //     newSearchParams.delete("showNameModal");
  //   }
  //   setSearchParams(newSearchParams, { replace: true });
  // };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShows(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleVersionSelect = (selectedVersion: WorkflowVersion) => {
    setWorkflowVersion(selectedVersion);
    setVersionsModal(false);
  };

  const handleDeleteVersion = async () => {
    setDeleteVersionState("loading");
    try {
      await workflowService.deleteVersion(teamId, workflow.id!, version.id);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("failed to delete");
      }
    } finally {
      setDeleteVersionModal(false);
    }
    setDeleteVersionState("success");
  };

  const handleDeleteWorkflow = async () => {
    setDeleteWorkflowState("loading");
    try {
      await workflowService.deleteWorkflow(teamId, workflow.id!);
      setDeleteWorkflowState("success");
      navigate(AppPath.workflows(teamId), {
        replace: true,
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("failed to delete");
      }
    } finally {
      setDeleteWorkflowModal(false);
    }
  };

  const handleDuplication = async () => {
    setDuplicateState("loading");
    try {
      const newVersion = await workflowService.duplicateVersion(
        teamId!,
        workflow.id!,
        version.id!
      );
      setDuplicateState("ready");
      setWorkflowVersion(newVersion);
    } catch {
      setDuplicateState("error");
    } finally {
      setShows(false);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={`flex flex-col gap-1 w-full absolute top-0 z-50 shadow-lg bg-gray-0 rounded-lg transition-all duration-200 overflow-clip ${
        shows ? "" : "hidden"
      }`}
    >
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to Portals"}
        shows={error != ""}
        closed={() => setError("")}
      />

      <CommonConfirmationModal
        isOpen={deleteVersionModal}
        title="Are you sure?"
        message="Deleting this version will be permanent. Use the archive function if you want to store your version."
        onCancel={() => setDeleteVersionModal(false)}
        onConfirm={() => handleDeleteVersion()}
        confirmStyle={"destructive"}
        confirmIcon="trash"
        confirmState={deleteVersionState}
      />

      <CommonConfirmationModal
        isOpen={deleteWorkflowModal}
        title="Are you sure?"
        message="Deleting this workflow will be permanent, and all versions and tests will be deleted along with it!"
        onCancel={() => setDeleteWorkflowModal(false)}
        onConfirm={() => handleDeleteWorkflow()}
        confirmStyle={"destructive"}
        confirmIcon="trash"
        confirmState={deleteWorkflowState}
      />

      <WorkflowVersionsModal
        workflow={workflow}
        currentVersion={version}
        teamId={teamId}
        shows={versionsModal}
        setShows={setVersionsModal}
        onVersionSelect={handleVersionSelect}
      />

      {/* <WorkflowNameModal
        teamId={teamId}
        workflow={workflow}
        shows={showNameModal}
        setShows={handleSetShowNameModal}
        setWorkflow={setWorkflow}
      /> */}

      <AnimatedButton
        title={"Edit Workflow Name"}
        onClick={() => setShowNameModal(true)}
        buttonState={"ready"}
        setButtonState={() => console.log("")}
        style={"transparent-black"}
        key="editName"
        leftIcon="edit"
        id="nameButton"
      />

      <AnimatedButton
        title={"See Versions"}
        onClick={() => setVersionsModal(true)}
        buttonState={"ready"}
        setButtonState={() => console.log("")}
        style={"transparent-black"}
        key="versions"
        leftIcon="workflows"
        id="versionsButton"
      />

      <AnimatedButton
        title={"Duplicate Version"}
        onClick={() => handleDuplication()}
        buttonState={duplicateState}
        setButtonState={setDuplicateState}
        style={"transparent-black"}
        key="duplicate"
        id="duplicateButton"
        leftIcon="versions"
      />

      <AnimatedButton
        title={"Delete Version"}
        onClick={() => setDeleteVersionModal(true)}
        buttonState={deleteVersionState}
        setButtonState={setDeleteVersionState}
        style={"transparent-destructive"}
        key="delete"
        leftIcon="trash"
        disabled={isLive}
        id="deleteButton"
      />
      <AnimatedButton
        title={"Delete Workflow"}
        onClick={() => setDeleteWorkflowModal(true)}
        buttonState={deleteWorkflowState}
        setButtonState={setDeleteWorkflowState}
        style={"transparent-destructive"}
        key="deleteWorkflow"
        leftIcon="alert"
        id="deleteWorkflowButton"
      />
    </div>
  );
};
