import { AppPath } from "../../models/AppPath";

export const CommonNoData: React.FC<{ teamId: string }> = ({ teamId }) => {
  return (
    <div className="flex flex-col">
      <div className="font-gooper text-gray-700 text-lg">No data?</div>
      <div className="">
        <span className="text-gray-700">
          Try turning on the example data in the{" "}
        </span>
        <a
          href={AppPath.teamProfile(teamId!)}
          className="text-blue-500 hover:text-blue-700 underline"
        >
          Team Settings
        </a>
      </div>
    </div>
  );
};
