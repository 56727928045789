import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import { ChatConfigurationTemplate } from "../../models/ChatConfigurationTemplate";
import { usePortalService } from "../../contexts/PortalContext";
import FailureModal from "../FailureModal";
import { useTeams } from "../../contexts/TeamContext";
import { CommonSpinner } from "../Common/CommonLoading";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { CommonCard } from "../Common/CommonCard";
import { CredentialInput } from "./CredentialInput";
import { ProviderStatusBadge } from "./ProviderStatusBadge";
import { Team } from "../../models/Team";
import PortalsCard from "../Home/PortalsCard";
import { usePortalLogService } from "../../contexts/PortalLogContext";
import Icon from "../Icon";
import { CommonTypedConfirmationModal } from "../Common/CommonTypedConfirmationModal";
import { ExternalPath } from "../../models/ExternalPath";

export const TeamSettingsAIProviderDetail: React.FC = () => {
  const { teamId, providerId } = useParams<{
    teamId: string;
    providerId: string;
  }>();

  const portalLogService = usePortalLogService();
  const portalService = usePortalService();
  const teamService = useTeams();
  const navigate = useNavigate();

  const [team, setTeam] = useState<Team>();
  const [config, setConfig] = useState<ChatConfigurationTemplate>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [secrets, setSecrets] = useState<{ [key: string]: string }>({});
  const [saveState, setSaveState] = useState<AnimationState>("ready");
  const [disconnectState, setDisconnectState] =
    useState<AnimationState>("ready");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tokensUsed, setTokensUsed] = useState<number>();
  const [logLimitExceeded, setLogLimitExceeded] = useState<boolean>(false);

  const handleSave = async () => {
    setSaveState("loading");
    try {
      await teamService.addSecrets(teamId!, secrets, providerId!);
      setSecrets({});
      setSaveState("success");
    } catch (e) {
      setSaveState("error");
    }
  };

  const handleDisconnect = async () => {
    setShowConfirmation(false);
    setDisconnectState("loading");
    try {
      await teamService.removeSecret(teamId!, providerId!);
      setSecrets({});
      setDisconnectState("success");
    } catch (e) {
      setDisconnectState("error");
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const getConfig = portalService.configTemplateRepo.get(
          portalService.configTemplatePath(),
          providerId!
        );
        const getTeam = teamService.teamRepo.get(
          teamService.teamPath(),
          teamId!
        );
        const [fetchedConfig, fetchedTeam] = await Promise.all([
          getConfig,
          getTeam,
        ]);

        if (fetchedTeam?.secretsUsed?.[providerId!] != true) {
          // not set up
          navigate(`/teams/${teamId}/settings/providers`);
        }

        if (!fetchedConfig || !fetchedTeam) {
          throw Error("Couldn't get data");
        }

        setConfig(fetchedConfig);

        setTeam(fetchedTeam);
        setLoading(false);
        // load logs

        const today = new Date();
        const thirtyDaysAgo = new Date(
          new Date().setDate(today.getDate() - 90)
        );
        const logs = await portalLogService.portalLogRepo.getList(
          portalLogService.portalLogPath(teamId!),
          { name: "createdAt", descending: true },
          [
            { key: "configId", filter: "==", value: fetchedConfig.id },
            {
              key: "createdAt",
              filter: ">",
              value: thirtyDaysAgo,
            },
          ],
          9999
        );
        const tokensUsed =
          logs
            .map((log) => log.tokensUsed)
            .reduce((acc, current) => acc ?? 0 + (current ?? 0), 0) ?? 0;
        setLogLimitExceeded(logs.length == 9999);
        setTokensUsed(tokensUsed);
      } catch (e) {
        setLoading(false);
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError("Something went horribly wrong");
        }
      }
    };
    loadData();
  }, [portalService, teamId, providerId]);

  const providerName = config?.name ?? "";

  const tokenLabel = (
    <div id="tokenLabel" key="tokenLabel" className="flex flex-col gap-2">
      <div className="w-full flex justify-end flex-row gap-1">
        <div className={`text-gray-400 text-md font-normal justify-end`}>
          30 Day Token Use
        </div>
        <Icon type="chart-pie" className="text-gray-200" />
      </div>

      <div className="w-full flex justify-end">
        <div
          className={`text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-blue-500 text-4xl font-medium font-gooper`}
        >
          {logLimitExceeded ? `${tokensUsed}+` : `${tokensUsed}`}
        </div>
      </div>
    </div>
  );

  return (
    <CommonContainer>
      <CommonHeader
        title={providerName}
        subtitle="Use this space to manage your Ai provider settings."
        sections={[
          { name: "Team Settings", link: `/teams/${teamId}/settings` },
          { name: "Ai Providers", link: `/teams/${teamId}/settings/providers` },
          {
            name: providerName,
            link: `/${teamId}/settings/providers/${providerId}`,
          },
        ]}
        teamId={teamId!}
        actions={[
          tokensUsed == undefined ? (
            <CommonSpinner key="tokenSpinner" />
          ) : (
            tokenLabel
          ),
        ]}
      />
      <FailureModal
        message={error}
        shows={error !== ""}
        closed={() => setError("")}
      />
      <CommonTypedConfirmationModal
        title="Are you sure you want to disconnect this provider?"
        isOpen={showConfirmation}
        message={`This will break any portals that are using ${config?.name} in their live version. Are you sure you wanna do this?`}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={() => handleDisconnect()}
        confirmStyle="destructive"
        confirmationPhrase="disconnect"
      />
      {loading || !config || !team ? (
        <CommonSpinner />
      ) : (
        <>
          <div className="flex flex-col gap-6 ">
            <div className="grid grid-cols-1 md:grid-cols-2 md:pt-6 gap-6">
              <div className="flex flex-col gap-2">
                <div className="font-gooper font-semibold text-gray-700 text-xl">
                  Your API key
                </div>
                <div className="text-gray-500 text-sm font-normal">
                  Your system is currently up and running.
                </div>
                <div className="text-gray-500 text-sm font-normal">
                  You can update your credentials or even revoke them, but that
                  will break all currently live versions.
                </div>
                <a
                  href={ExternalPath.blog()}
                  className={`text-blue-500 hover:text-blue-700 text-sm font-normal underline`}
                  target="_blank"
                >
                  Read more about the implications here.
                </a>
              </div>
              <CommonCard className="">
                <div className="flex flex-row justify-between">
                  <div className="font-gooper font-medium text-gray-700">
                    {" "}
                    Live Credentials{" "}
                  </div>

                  <div className="w-fit">
                    <ProviderStatusBadge team={team} config={config} />
                  </div>
                </div>

                {Object.keys(config.secrets).map((key) => {
                  return (
                    <CredentialInput
                      key="credentialInput"
                      value={secrets[key] ?? ""}
                      placeholder={`Replace live credentials here`}
                      onChange={(e) => setSecrets({ ...secrets, [key]: e })}
                    />
                  );
                })}
                <div className="flex flex-row justify-between">
                  <AnimatedButton
                    title="Disconnect Provider"
                    buttonState={disconnectState}
                    setButtonState={setDisconnectState}
                    onClick={() => setShowConfirmation(true)}
                    style="transparent-destructive"
                    font="font-sans"
                    leftIcon="disconnect"
                    after={(success) => {
                      if (success) {
                        navigate(`/teams/${teamId}/settings/providers`);
                      }
                    }}
                    classNameIn="w-full"
                    id="disconnectButton"
                  />
                  <AnimatedButton
                    title="Update Credentials"
                    buttonState={saveState}
                    setButtonState={setSaveState}
                    onClick={handleSave}
                    style="action"
                    classNameIn="w-full"
                    disabled={Object.values(secrets).join("") == ""}
                    id="updateButton"
                  />
                </div>
              </CommonCard>
            </div>
            <div className="md:w-1/2">
              <div className="font-gooper font-semibold text-gray-700 text-xl pt-6 pb-4">
                Portals using this provider
              </div>
              <PortalsCard teamId={teamId!} providerId={config.id} />
            </div>
          </div>
        </>
      )}
    </CommonContainer>
  );
};
