import React, { useState, useEffect, useRef } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams, useSearchParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import FailureModal from "../FailureModal";
import { usePortalService } from "../../contexts/PortalContext";
import { Portal } from "../../models/Portal";
import CheckFilter, { CheckFilterItem } from "../Notifications/CheckFilter";
import { TestRun } from "../../models/TestRun";
import { PortalTest } from "../../models/PortalTest";
import { useTestService } from "../../contexts/TestContext";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { TestTable } from "./TestTable";
import { TestRunTable } from "./TestRunTable";
import { PortalVersion } from "../../models/PortalVersion";
import { TestRunDetailModal } from "./TestRunDetailModal";
import { TestDetailModal } from "./TestDetailModal";
import { useTeams } from "../../contexts/TeamContext";
import { CommonNoData } from "../Common/CommonNoData";
import { filterExamplePortals } from "../../utils/FilterUtils";
import { Team } from "../../models/Team";

export const TestCenter: React.FC<{}> = ({}) => {
  const { teamId } = useParams<{
    teamId: string;
  }>();

  const portalService = usePortalService();
  const testService = useTestService();
  const teamService = useTeams();

  const [searchParams, setSearchParams] = useSearchParams();
  const portalId = searchParams.get("portal");
  const versionIds = searchParams.getAll("versions");
  const testRunId = searchParams.get("testRunId");
  const testId = searchParams.get("testId");

  const [error, setError] = useState("");

  const [portals, setPortals] = useState<Portal[]>([]);
  const [portalTests, setPortalTests] = useState<PortalTest[]>();
  const [testRuns, setTestRuns] = useState<TestRun[]>();
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [versions, setVersions] = useState<PortalVersion[]>([]);

  const [portalFilterItems, setPortalFilterItems] = useState<CheckFilterItem[]>(
    []
  );
  const [versionFilterItems, setVersionFilterItems] = useState<
    CheckFilterItem[]
  >([]);

  const [runTestState, setRunTestState] = useState<AnimationState>("ready");
  const [newTestState, setNewTestState] = useState<AnimationState>("ready");

  const [loadingPortals, setLoadingPortals] = useState(false);
  const [loadingTests, setLoadingTests] = useState(false);

  const [currentTestRunPage, setCurrentTestRunPage] = useState(1);
  const [testRunPageDates, setTestRunPageDates] = useState<Date[]>([]);

  const [team, setTeam] = useState<Team>();

  const [currentTestPage, setCurrentTestPage] = useState(1);
  const [testPageDates, setTestPageDates] = useState<Date[]>([]);

  const unsubRef = useRef<() => void>();
  const unsubTestRef = useRef<() => void>();

  const pageLimit = 10;

  // Fetch portals once when the component mounts
  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const team = await teamService.teamRepo.get(
          teamService.teamPath(),
          teamId!
        );
        setTeam(team ?? undefined);
        const fetchedPortals = await portalService.portalRepo.getList(
          portalService.portalPath(teamId!),
          { name: "modifiedAt", descending: true }
        );
        const portals = fetchedPortals.filter((p) =>
          filterExamplePortals(team ?? undefined, p)
        );

        setPortals(portals);
        if (!portalId && portals.length > 0) {
          const mostRecentPortal = portals[0];
          setSearchParams({ portal: mostRecentPortal.id });
          setPortalFilterItems(
            portals.map((p) => ({
              name: p.name,
              id: p.id,
              selected: p.id === mostRecentPortal.id,
            }))
          );
        } else {
          setPortalFilterItems(
            portals.map((p) => ({
              name: p.name,
              id: p.id,
              selected: portalId === p.id,
            }))
          );
        }
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      } finally {
        setLoadingPortals(false);
      }
    };

    fetchPortals();
  }, [teamId, portalService]);

  // Fetch versions when portalId changes
  useEffect(() => {
    const fetchVersions = async () => {
      if (!portalId || portals.length === 0) return;

      try {
        const versions = await portalService.portalVersionRepo.getList(
          portalService.portalVersionPath(teamId!, portalId),
          { name: "modifiedAt", descending: true }
        );

        let selectedVersionIds = versionIds;

        if (versionIds.length < 1) {
          const portal = portals.filter((p) => p.id == portalId)[0];
          let defaultVersions = versions.filter(
            (v) => v.id != portal.currentVersionId
          );
          if (portal.currentVersionData) {
            defaultVersions = [portal.currentVersionData]
              .concat(defaultVersions)
              .slice(0, 1);
          }
          selectedVersionIds = defaultVersions.map((v) => v.id ?? "");

          setSearchParams({
            portal: portalId,
            versions: selectedVersionIds,
          });
        }

        setVersionFilterItems(
          versions.map((v) => {
            return {
              name: v.name,
              id: v.id ?? "",
              selected: selectedVersionIds.includes(v.id ?? ""),
            };
          })
        );
        setVersions(versions);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };

    fetchVersions();
  }, [portalId, teamId, portalService, portals]);

  useEffect(() => {
    const fetchTests = async () => {
      if (
        !portalId ||
        versionIds.length === 0 ||
        loadingTests ||
        portalTests !== undefined
      )
        return;

      setLoadingTests(true);
      try {
        const startAfterDate =
          currentTestPage == 1 ? undefined : testPageDates[currentTestPage - 1];

        unsubTestRef.current?.();
        unsubTestRef.current = testService.testRepo.observeList(
          testService.testPath(teamId!, portalId),
          async (tests) => {
            setPortalTests(tests);
            if (tests.length > 0) {
              const lastTestDate = tests[tests.length - 1].createdAt!;
              setTestPageDates((prevDates) => {
                const newDates = [...prevDates];
                newDates[currentTestPage] = lastTestDate;
                return newDates;
              });
            }
          },
          { name: "createdAt", descending: true },
          pageLimit,
          startAfterDate
        );
      } catch (e) {
        setPortalTests([]);
        setError(e instanceof Error ? e.message : "Something went wrong");
      } finally {
        setLoadingTests(false);
      }
    };

    fetchTests();
  }, [
    portalId,
    versionIds,
    teamId,
    testService,
    currentTestPage,
    testPageDates,
  ]);

  useEffect(() => {
    const fetchTestRuns = async () => {
      if (!portalId || versionIds.length === 0 || testRuns !== undefined) {
        return;
      }
      try {
        const startAfterDate =
          currentTestRunPage == 1
            ? undefined
            : testRunPageDates[currentTestRunPage - 1];
        unsubRef.current?.();
        unsubRef.current = testService.testRunRepo.observeList(
          testService.testRunPath(teamId!, portalId!),
          async (runs) => {
            setTestRuns(runs);

            if (runs.length > 0) {
              const lastRunDate = runs[runs.length - 1].createdAt!;
              setTestRunPageDates((prevDates) => {
                const newDates = [...prevDates];
                newDates[currentTestRunPage] = lastRunDate;
                return newDates;
              });
            }
          },
          { name: "createdAt", descending: true },
          pageLimit,
          startAfterDate
        );
      } catch (e) {
        setTestRuns([]);
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };

    fetchTestRuns();
  }, [
    portalId,
    versionIds,
    teamId,
    testService,
    currentTestRunPage,
    testRunPageDates,
  ]);

  const updatePortalFilter = (item: CheckFilterItem) => {
    setSearchParams({ portal: item.id });
    portalFilterItems.forEach((i) => (i.selected = false));
    item.selected = !item.selected;
    setPortalFilterItems([...portalFilterItems]);
    setTestRuns(undefined);
    setPortalTests(undefined);
  };

  const updateVersionFilter = (item: CheckFilterItem) => {
    item.selected = !item.selected;
    const allSelected = versionFilterItems
      .filter((i) => i.selected)
      .map((i) => i.id);
    setVersionFilterItems([...versionFilterItems]);
    setSearchParams(() => ({
      portal: portalId!,
      versions: allSelected,
    }));
    setTestRuns(undefined);
    setPortalTests(undefined);
  };

  const handleNewTest = async () => {
    setNewTestState("loading");
    let newTest: PortalTest = {
      name: "New Test",
      parameterValues: {},
      createdAt: new Date(),
      messages: [],
    };
    try {
      newTest = await testService.testRepo.create(
        newTest,
        testService.testPath(teamId!, portalId!)
      );
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("testId", newTest.id!);
      setSearchParams(newSearchParams);
      setNewTestState("success");
    } catch (e) {
      setNewTestState("error");
    }
  };

  const handleRunTest = async () => {
    setRunTestState("loading");
    const matchingTests: PortalTest[] = (portalTests ?? []).filter((pt) =>
      selectedTests.includes(pt.id ?? "")
    );
    const matchingVersions = versions.filter((version) =>
      versionFilterItems.find(
        (vItem) => vItem.id == version.id && vItem.selected
      )
    );
    const testRuns = matchingTests.flatMap((portalTest) => {
      return matchingVersions.map((version) => {
        const testRun: TestRun = {
          createdAt: new Date(),
          prompt: version.prompt,
          status: "IN_PROGRESS",
          portalId: portalId!,
          versionId: version.id!,
          config: version.config,
          configId: version.configId,
          parameterValues: portalTest.parameterValues,
          messages: portalTest.messages,
          testId: portalTest.id ?? "",
          testName: portalTest.name,
          versionName: version.name,
        };
        return testRun;
      });
    });

    const testRunPromises = testRuns.map((testRun) =>
      testService.runTest(testRun, portalId!, teamId!)
    );

    try {
      Promise.all(testRunPromises);
      setRunTestState("success");
    } catch (e) {
      setRunTestState("error");
    }
  };

  const handleTestClick = (test: PortalTest) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("testId", test.id!);
    setSearchParams(newSearchParams);
  };

  const handleTestRunClick = (testRun: TestRun) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("testRunId", testRun.id!);
    setSearchParams(newSearchParams);
  };

  const calculateTestCount = () => {
    const versionCount = versionFilterItems.reduce(
      (a, b) => a + (b.selected ? 1 : 0),
      0
    );
    return versionCount * selectedTests.length;
  };

  return (
    <CommonContainer>
      <CommonHeader
        title={`Test Center`}
        subtitle={`Create and run tests for your portals. Catch the edge cases before your users do.`}
        sections={[
          { name: "Portals", link: `/teams/${teamId}/test-center` },
          { name: "Test Center", link: `` },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            buttonState={runTestState}
            setButtonState={setRunTestState}
            style="action"
            title={
              selectedTests.length == 0
                ? "Select tests"
                : `Run ${calculateTestCount()} tests`
            }
            onClick={handleRunTest}
            key="runTestButton"
            disabled={selectedTests.length == 0}
          />,
          <AnimatedButton
            buttonState={newTestState}
            setButtonState={setNewTestState}
            style="secondary"
            title="New Test"
            onClick={handleNewTest}
            leftIcon="plus"
            key="newTestButton"
          />,
        ]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      {testRunId && portalId && (
        <TestRunDetailModal
          testRunId={testRunId}
          teamId={teamId!}
          portalId={portalId}
          closed={() => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete("testRunId");
            setSearchParams(newSearchParams);
          }}
        />
      )}
      {testId && portalId && team && (
        <TestDetailModal
          testId={testId}
          teamId={teamId!}
          portalId={portalId}
          closed={() => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete("testId");
            setSearchParams(newSearchParams);
          }}
          team={team}
        />
      )}
      <div className="grid grid-cols-2 gap-4 pt-2">
        <CheckFilter
          typeName="Portal"
          typeNamePlural="Select Portals"
          items={portalFilterItems}
          updatedItem={updatePortalFilter}
          leftIcon="portals"
        />
        <CheckFilter
          typeName="Version"
          typeNamePlural="Select Version"
          items={versionFilterItems}
          updatedItem={updateVersionFilter}
          leftIcon="portals"
        />
      </div>

      {!loadingPortals && !portalId && (
        <div className="font-gooper text-lg text-gray-700">
          Select a portal to get started. Every test is unique to its portal.
        </div>
      )}
      {versionIds.length < 1 && portalId && (
        <div className="font-gooper text-lg text-gray-700">
          Please select a version
        </div>
      )}
      {!loadingPortals && portalId && versionIds.length > 0 && (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <TestTable
              tests={portalTests ?? []}
              handleTestClick={handleTestClick}
              selectedTests={selectedTests}
              setSelectedTests={setSelectedTests}
              currentPage={currentTestPage}
              loading={portalTests == undefined}
              hasMore={(portalTests ?? []).length === pageLimit}
              selectedPage={(direction) => {
                setPortalTests(undefined);
                if (direction === "next") {
                  setCurrentTestPage((prev) => prev + 1);
                } else if (direction === "prev" && currentTestPage > 1) {
                  setCurrentTestPage((prev) => prev - 1);
                }
              }}
            />
            <TestRunTable
              testRuns={testRuns ?? []}
              handleTestRunClick={handleTestRunClick}
              currentPage={currentTestRunPage}
              hasMore={(testRuns ?? []).length === pageLimit}
              loading={testRuns == undefined}
              selectedPage={(direction) => {
                setTestRuns(undefined);
                if (direction === "next") {
                  setCurrentTestRunPage((prev) => prev + 1);
                } else if (direction === "prev" && currentTestRunPage > 1) {
                  setCurrentTestRunPage((prev) => prev - 1);
                }
              }}
            />
          </div>
        </div>
      )}
      {portals.length == 0 && loadingPortals && (
        <CommonNoData teamId={teamId ?? ""} />
      )}
    </CommonContainer>
  );
};
