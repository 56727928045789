import React, { useEffect, useState } from "react";

import { CommonModal } from "../../Common/CommonModal";
import Icon from "../../Icon";
import { Workflow, WorkflowVersion } from "../../../models/Workflow";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { WorkflowVersionCard } from "./WorkflowVersionCard";

interface WorkflowVersionsModalProps {
  workflow: Workflow;
  currentVersion: WorkflowVersion;
  teamId: string;
  setShows: (shows: boolean) => void;
  shows: boolean;
  onVersionSelect: (version: WorkflowVersion) => void;
}

export const WorkflowVersionsModal: React.FC<WorkflowVersionsModalProps> = ({
  workflow,
  currentVersion,
  teamId,
  setShows,
  shows,
  onVersionSelect,
}) => {
  const workflowService = useWorkflowService();
  const [versions, setVersions] = useState<WorkflowVersion[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const fetchedVersions = await workflowService.workflowVersionRepo.getList(
        workflowService.workflowVersionPath(teamId, workflow.id!),
        { name: "number", descending: true }
      );
      setVersions(fetchedVersions);
    };
    if (shows) {
      loadData();
    }
  }, [workflowService, shows, teamId, workflow.id]);

  const handleDismiss = () => {
    setShows(false);
  };

  const handleVersionSelect = (version: WorkflowVersion) => {
    onVersionSelect?.(version);
    setShows(false);
  };

  if (!shows) return null;

  return (
    <CommonModal isOpen={shows} onDismiss={handleDismiss}>
      <div
        className="bg-white rounded-lg shadow-lg p-6"
        style={{ width: "90vw", maxWidth: "1200px" }}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900">
            Workflow Versions
          </h2>
          <button
            onClick={handleDismiss}
            className="text-gray-400 hover:text-gray-500"
          >
            <svg className="w-6 h-6" viewBox="0 0 24 24">
              <Icon type="x" />
            </svg>
          </button>
        </div>

        {versions.length === 0 ? (
          <div className="text-center text-gray-500 py-8">
            No versions found
          </div>
        ) : (
          <div className="overflow-x-auto pb-4">
            <div className="flex gap-4 p-4 min-w-min">
              {versions.map((version) => (
                <WorkflowVersionCard
                  key={version.id}
                  workflow={workflow}
                  version={version}
                  currentlySelected={currentVersion}
                  selectedVersion={handleVersionSelect}
                  teamId={teamId}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </CommonModal>
  );
};
