import React from "react";
import { Team } from "../../models/Team";
import { formatDate } from "../../utils/DateUtils";
import { TeamSubscription } from "../../models/TeamSubscription";

interface TeamDetailModalProps {
  team: Team | null;
  subscription: TeamSubscription | null;
  onClose: () => void;
}

const TeamDetailModal: React.FC<TeamDetailModalProps> = ({
  team,
  subscription,
  onClose,
}) => {
  if (!team || !subscription) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      onClick={onClose}
    >
      <div
        className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {team.name}
          </h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              Created: {formatDate(team.createdAt)}
            </p>
            <p className="text-sm text-gray-500">
              Plan: {subscription.currentEvent?.name || "No active plan"}
            </p>
            <p className="text-sm text-gray-500">
              Status: {subscription.currentEvent?.status || "N/A"}
            </p>
            <p className="text-sm text-gray-500">
              Members: {Object.keys(team.members).length}
            </p>
            {subscription.currentEvent && (
              <>
                <p className="text-sm text-gray-500">
                  Daily Requests: {subscription.currentEvent.dailyRequests}
                </p>
                <p className="text-sm text-gray-500">
                  Period:{" "}
                  {formatDate(subscription.currentEvent.currentPeriodStart)} -{" "}
                  {formatDate(subscription.currentEvent.currentPeriodEnd)}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="items-center px-4 py-3">
          <button
            id="ok-btn"
            className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamDetailModal;
