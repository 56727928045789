import { ReactNode } from "react";

export const CommonInputSegment: React.FC<{
  title: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  type?: "password" | "email" | "text";
  placeholder?: string;
  error?: string;
  setError?: (error: string) => void;
  rightItem?: ReactNode;
  id?: string;
  className?: string;
  leftItem?: ReactNode;
}> = ({
  title,
  value,
  onChange,
  disabled,
  type,
  placeholder,
  error,
  setError,
  rightItem,
  id,
  className,
  leftItem,
}) => {
  const handleOnChange = (newValue: string) => {
    onChange(newValue);
    setError?.("");
  };
  return (
    <div className="w-full gap-2 flex flex-col bg-gray-0 rounded-lg">
      <div className="flex flex-row justify-between items-center">
        <div className={`flex gap-1 items-center flex-row ${className}`}>
          <div
            className={`text-gray-500 font-medium text-sm font-gooper ${className}`}
          >
            {title}
          </div>
          {leftItem && leftItem}
        </div>
        {rightItem && rightItem}
      </div>
      <input
        className={`${
          error
            ? "border-red-400 focus:border-red-600 bg-red-50 text-red-900"
            : "border-gray-300 focus:border-blue-500 bg-gray-50 text-gray-700"
        } border rounded-md p-2 leading-tight  text-sm focus:ring-0 focus:outline-none h-10`}
        value={value}
        onChange={(value) => handleOnChange(value.target.value)}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        id={id ?? "commonInput"}
      />
      {error && <div className="text-red-500 text-sm font-gooper">{error}</div>}
    </div>
  );
};
