import React from "react";
import { SetupStep } from "../../../models/ChatConfigurationTemplate";

interface SetupStepsSectionProps {
  setupSteps: SetupStep[];
  onChange: (setupSteps: SetupStep[]) => void;
}

const SetupStepsSection: React.FC<SetupStepsSectionProps> = ({
  setupSteps,
  onChange,
}) => {
  const addSetupStep = () => {
    onChange([...setupSteps, { title: "", text: "" }]);
  };

  const updateSetupStep = (index: number, updatedStep: SetupStep) => {
    const newSteps = [...setupSteps];
    newSteps[index] = updatedStep;
    onChange(newSteps);
  };

  return (
    <div>
      <h4 className="font-medium mb-2">Setup Steps</h4>
      {setupSteps.map((step, index) => (
        <div key={index} className="mb-2">
          <input
            className="w-full p-2 border rounded mb-1"
            value={step.title}
            onChange={(e) =>
              updateSetupStep(index, { ...step, title: e.target.value })
            }
            placeholder="Step Title"
          />
          <textarea
            className="w-full p-2 border rounded"
            value={step.text}
            onChange={(e) =>
              updateSetupStep(index, { ...step, text: e.target.value })
            }
            placeholder="Step Text"
          />
        </div>
      ))}
      <button
        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={addSetupStep}
      >
        Add Setup Step
      </button>
    </div>
  );
};

export default SetupStepsSection;
