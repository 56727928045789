import React from "react";
import { ChatConfigurationSliderMaxLengthInput } from "../../../models/ChatConfigurationTemplate";

interface MaxLengthSliderInputProps {
  input: ChatConfigurationSliderMaxLengthInput;
  onChange: (updatedInput: ChatConfigurationSliderMaxLengthInput) => void;
}

const MaxLengthSliderInput: React.FC<MaxLengthSliderInputProps> = ({
  input,
  onChange,
}) => {
  const renderInput = (
    label: string,
    value: string | number,
    onChange: (value: string) => void,
    type: string = "text",
    placeholder: string = ""
  ) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        className="w-full p-2 border rounded"
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <>
      {renderInput("Input Name", input.name, (value) =>
        onChange({ ...input, name: value })
      )}
      {renderInput("Description", input.description, (value) =>
        onChange({ ...input, description: value })
      )}
      {renderInput("Short Description", input.shortDescription, (value) =>
        onChange({ ...input, shortDescription: value })
      )}
      {renderInput(
        "Number",
        input.number,
        (value) => onChange({ ...input, number: parseInt(value) }),
        "number"
      )}
      <p className="text-sm text-gray-600 mt-2">
        Note: This slider's values are derived from the selected model.
      </p>
    </>
  );
};

export default MaxLengthSliderInput;
