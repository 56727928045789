import React, { useState, useEffect } from "react";
import { ChatConfigurationTemplate } from "../../../models/ChatConfigurationTemplate";
import ChatConfigurationTemplateForm from "./ChatConfigurationTemplateForm";
import { usePortalService } from "../../../contexts/PortalContext";
import Icon from "../../Icon";
import Toast from "./Toast";

interface ChatTemplatesSectionProps {}

const ChatTemplatesSection: React.FC<ChatTemplatesSectionProps> = () => {
  const portalService = usePortalService();
  const [templates, setTemplates] = useState<ChatConfigurationTemplate[]>([]);
  const [editingTemplate, setEditingTemplate] = useState<
    ChatConfigurationTemplate | undefined
  >();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState<
    ChatConfigurationTemplate | undefined
  >();
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  useEffect(() => {
    const loadTemplates = async () => {
      const loadedTemplates = await portalService.configTemplateRepo.getList(
        portalService.configTemplatePath()
      );
      setTemplates(loadedTemplates);
    };
    loadTemplates();
  }, []);

  const handleEdit = (template: ChatConfigurationTemplate) => {
    setEditingTemplate(template);
  };

  const handleCreate = async (template: ChatConfigurationTemplate) => {
    await portalService.configTemplateRepo.set(
      template,
      portalService.configTemplatePath(),
      template.id
    );
    setTemplates([...templates, template]);
    setShowCreateForm(false);
  };

  const handleUpdate = async (updatedTemplate: ChatConfigurationTemplate) => {
    if (!editingTemplate) return;

    if (updatedTemplate.id !== editingTemplate.id) {
      await portalService.configTemplateRepo.delete(
        portalService.configTemplatePath(),
        editingTemplate.id
      );
    }
    await portalService.configTemplateRepo.update(
      updatedTemplate,
      portalService.configTemplatePath(),
      updatedTemplate.id
    );

    setTemplates((prevTemplates) =>
      prevTemplates
        .map((t) => (t.id === editingTemplate.id ? updatedTemplate : t))
        .filter(
          (t) => t.id !== editingTemplate.id || t.id === updatedTemplate.id
        )
    );
    setEditingTemplate(undefined);
  };

  const handleDelete = async (template: ChatConfigurationTemplate) => {
    setDeletingTemplate(template);
  };

  const handlePublish = async (template: ChatConfigurationTemplate) => {
    try {
      await portalService.publishTemplate(template.id!);
      setNotification({
        message: `Template "${template.name}" published successfully!`,
        type: "success",
      });
    } catch (error) {
      error instanceof Error
        ? setNotification({
            message: `Failed to publish template: ${error.message}`,
            type: "error",
          })
        : console.error("Failed to push");
    }
    // Clear the notification after 3 seconds
    setTimeout(() => setNotification(null), 3000);
  };

  const confirmDelete = async () => {
    if (deletingTemplate) {
      await portalService.configTemplateRepo.delete(
        portalService.configTemplatePath(),
        deletingTemplate.id
      );
      setTemplates(templates.filter((t) => t.id !== deletingTemplate.id));
      setDeletingTemplate(undefined);
    }
  };

  return (
    <>
      <div className="flex justify-end mb-4">
        {notification && (
          <Toast message={notification.message} type={notification.type} />
        )}
        <button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center"
          onClick={() => setShowCreateForm(true)}
        >
          <Icon type="simple-plus" className="h-5 w-5 mr-2" />
          Create Template
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Description
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {templates.map((template) => (
            <tr key={template.id}>
              <td className="px-6 py-4 whitespace-nowrap">{template.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {template.description}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex flex-row justify-between">
                  <button
                    className="text-indigo-600 hover:text-indigo-900"
                    onClick={() => handlePublish(template)}
                  >
                    <Icon type="chevron-up" className="h-5 w-5" />
                  </button>
                  <button
                    className="text-indigo-600 hover:text-indigo-900"
                    onClick={() => handleEdit(template)}
                  >
                    <Icon type="edit" className="h-5 w-5" />
                  </button>
                  <button
                    className="text-red-600 hover:text-red-900"
                    onClick={() => handleDelete(template)}
                  >
                    <Icon type="trash" className="h-5 w-5" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showCreateForm && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          onClick={() => setShowCreateForm(false)}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-3/4 max-w-4xl shadow-lg rounded-md bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
              Create New Template
            </h3>
            <ChatConfigurationTemplateForm
              onClose={() => setShowCreateForm(false)}
              onSave={handleCreate}
            />
          </div>
        </div>
      )}
      {editingTemplate && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          onClick={() => setEditingTemplate(undefined)}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-3/4 max-w-4xl shadow-lg rounded-md bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
              Edit Template
            </h3>
            <ChatConfigurationTemplateForm
              template={editingTemplate}
              onClose={() => setEditingTemplate(undefined)}
              onSave={handleUpdate}
            />
          </div>
        </div>
      )}
      {deletingTemplate && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          onClick={() => setDeletingTemplate(undefined)}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
              Confirm Deletion
            </h3>
            <p className="mb-4">
              Are you sure you want to delete the template "
              {deletingTemplate.name}"? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
                onClick={() => setDeletingTemplate(undefined)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatTemplatesSection;
