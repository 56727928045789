import {
  getAuth,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
  sendEmailVerification,
  reauthenticateWithPopup,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { FirestoreRepository } from "../repos/FirestoreRepository";
import { User } from "../models/User";
import { getFirestore } from "firebase/firestore";
import firebaseApp from "../Firebase";
import { Repository } from "../repos/Repository";
import { getFunctions, httpsCallable } from "firebase/functions";
import { TeamAlert } from "../models/TeamAlert";
import { isProd } from "../utils/EnvironmentUtil";

export interface AuthService {
  deleteAccount(password?: string): Promise<void>;
  signOut(): Promise<void>;
  currentUser(): Promise<User | null>;
  userStateChanged(callback: (user: User | null) => void): () => void;
  signInWithGoogle(): Promise<void>;
  isOnboarded(user: User | null): boolean;
  isLoggedIn(): boolean;
  authToken(): Promise<string | undefined>;
  userId: string | undefined;
  userRepo: Repository<User>;
  alertRepo: Repository<TeamAlert>;
  userPath(): string;
  alertsPath(userId: string): string;
  refreshToken(): Promise<void>;
  checkCode(inviteCode: string): Promise<void>;
  signIn(email: string, password: string): Promise<void>;
  signUp(email: string, password: string, baseUrl: string): Promise<void>;
  resetPassword(email: string): Promise<void>;
  setPersistence(saved: boolean): Promise<void>;
  validatePassword(password: string): Promise<string[]>;
  sendValidationEmail(baseUrl: string): Promise<void>;
  reloadAuth(): Promise<void>;
  changePassword(oldPassword: string, newPassword: string): Promise<void>;
  getHMAC(): Promise<string>;
  isAdmin(): Promise<boolean>;
}

export class FirebaseAuthService implements AuthService {
  private auth = getAuth();
  userRepo = new FirestoreRepository<User>(getFirestore(firebaseApp));
  alertRepo = new FirestoreRepository<TeamAlert>(getFirestore(firebaseApp));

  userPath() {
    return "users";
  }

  alertsPath(userId: string) {
    return `${this.userPath()}/${userId}/alerts`;
  }

  async signOut(): Promise<void> {
    localStorage.clear();
    await signOut(this.auth);
  }

  async deleteAccount(password?: string): Promise<void> {
    const user = await this.currentUser();
    try {
      if (user!.authProvider == "email" && password) {
        const user = this.auth.currentUser!;
        const credential = EmailAuthProvider.credential(user.email!, password);
        await reauthenticateWithCredential(user, credential);
      } else {
        await reauthenticateWithPopup(
          this.auth.currentUser!,
          new GoogleAuthProvider()
        );
      }
    } catch (e) {
      this.processAuthError(e);
    }
    await this.userRepo.delete(this.userPath(), this.auth.currentUser!.uid);
    await this.auth.currentUser!.delete();
    localStorage.clear();
  }

  async currentUser(): Promise<User | null> {
    const uid = this.auth.currentUser?.uid;
    if (!uid) {
      return null;
    }

    return this.userRepo.get(this.userPath(), uid);
  }

  userStateChanged(callback: (user: User | null) => void): () => void {
    let userRepoUnsubscribe: (() => void) | null = null;

    const authUnsubscribe = this.auth.onAuthStateChanged((authUser) => {
      if (process.env.NODE_ENV === "development") {
        console.log(`AUTH UPDATE`, authUser);
      }
      if (userRepoUnsubscribe) {
        userRepoUnsubscribe();
        userRepoUnsubscribe = null;
      }

      if (!authUser) {
        callback(null);
        return;
      }

      userRepoUnsubscribe = this.userRepo.observe(
        this.userPath(),
        authUser.uid,
        (user) => {
          if (
            user.verifiedEmail != authUser.emailVerified &&
            authUser.emailVerified
          ) {
            this.userRepo
              .update(
                { verifiedEmail: authUser.emailVerified },
                this.userPath(),
                authUser.uid
              )
              .then(() => {
                callback({ ...user, verifiedEmail: authUser.emailVerified });
              });
          } else {
            if (process.env.NODE_ENV === "development") {
              console.log(`USER UPDATE`, user);
            }
            callback(user);
          }
        }
      );
    });

    return () => {
      authUnsubscribe();
      if (userRepoUnsubscribe) {
        userRepoUnsubscribe();
      }
    };
  }

  async signInWithGoogle(): Promise<void> {
    const provider = new GoogleAuthProvider();
    const authUser = await signInWithPopup(this.auth, provider);
    const email = authUser.user.email;
    const verifiedEmail = authUser.user.emailVerified;
    if (!email) {
      throw Error("No email with google account");
    }
    const existingDoc = await this.userRepo.get(
      this.userPath(),
      authUser.user.uid
    );
    if (existingDoc) {
      await this.userRepo.update(
        { email },
        this.userPath(),
        authUser.user.uid,
        true
      );
    } else {
      const newUser: User = {
        id: authUser.user.uid,
        email,
        verifiedEmail,
        authProvider: "google",
        createdAt: new Date(authUser.user.metadata.creationTime!),
      };
      await this.userRepo.set(newUser, this.userPath(), authUser.user.uid);
    }
  }

  isOnboarded(user: User | null): boolean {
    return (
      !!user?.name &&
      !!user.email &&
      (isProd ? true : !!user.inviteCode) &&
      !!user.role &&
      !!user.orgSize &&
      !!user.referral &&
      !!user.aiExp
    );
  }

  isLoggedIn(): boolean {
    return !!this.auth.currentUser;
  }

  async authToken(): Promise<string | undefined> {
    const currentUser = this.auth.currentUser;
    if (!currentUser) {
      return undefined;
    }
    return currentUser.getIdToken();
  }

  get userId() {
    return this.auth.currentUser?.uid;
  }

  async refreshToken(): Promise<void> {
    await this.auth.currentUser!.getIdToken(true);
  }

  async checkCode(inviteCode: string): Promise<void> {
    const functions = getFunctions(firebaseApp);
    const checkCodeFn = httpsCallable(functions, "checkInviteCode");
    await checkCodeFn({ inviteCode });
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      const authUser = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      await this.userRepo.update(
        { email },
        this.userPath(),
        authUser.user.uid,
        true
      );
    } catch (e) {
      this.processAuthError(e);
    }
  }

  async signUp(
    email: string,
    password: string,
    baseUrl: string
  ): Promise<void> {
    try {
      const authUser = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const newUser: User = {
        id: authUser.user.uid,
        email,
        authProvider: "email",
        createdAt: new Date(),
      };
      await this.sendValidationEmail(baseUrl);
      await this.userRepo.set(newUser, this.userPath(), authUser.user.uid);
    } catch (e) {
      this.processAuthError(e);
    }
  }

  processAuthError(e: any) {
    if (e instanceof Error) {
      if (e.message === "Firebase: Error (auth/invalid-login-credentials).") {
        throw Error("Wrong email or password");
      } else if (e.message == "Firebase: Error (auth/invalid-email).") {
        throw Error("Invalid email");
      } else if (e.message == "Firebase: Error (auth/missing-password).") {
        throw Error("Missing password");
      } else if ((e.message = "Firebase: Error (auth/missing-email).")) {
        throw Error("Please enter an email");
      } else {
        throw e;
      }
    } else {
      throw Error("Unknown error");
    }
  }

  async sendValidationEmail(baseUrl: string): Promise<void> {
    try {
      const currentUser = this.auth.currentUser;
      await sendEmailVerification(currentUser!, {
        url: `${baseUrl}/onboarding`,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (e) {
      this.processAuthError(e);
    }
  }

  async setPersistence(saved: boolean): Promise<void> {
    const persistenceType = saved
      ? browserLocalPersistence
      : inMemoryPersistence;
    await setPersistence(this.auth, persistenceType);
  }

  async validatePassword(password: string): Promise<string[]> {
    const problems = [];
    if (password.length < 8) {
      problems.push("Password too short");
    }

    if (password.length > 120) {
      problems.push("Password too long");
    }

    if (!/[A-Z]/.test(password)) {
      problems.push("Password must contain at least one uppercase letter");
    }

    if (!/[a-z]/.test(password)) {
      problems.push("Password must contain at least one lowercase letter");
    }

    if (!/[0-9]/.test(password)) {
      problems.push("Password must contain at least one number");
    }

    if (!/[^A-Za-z0-9]/.test(password)) {
      problems.push("Password must contain at least one special character");
    }

    return problems;
  }

  async reloadAuth(): Promise<void> {
    await this.auth.currentUser?.reload();
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    const user = this.auth.currentUser!;
    const credential = EmailAuthProvider.credential(user.email!, oldPassword);
    try {
      await reauthenticateWithCredential(user, credential);
    } catch (e) {
      this.processAuthError(e);
    }
    updatePassword(user, newPassword);
  }

  async getHMAC(): Promise<string> {
    const functions = getFunctions(firebaseApp);
    const deleteTeamFn = httpsCallable(functions, "getHMAC");
    const response = await deleteTeamFn();
    const hmacData = response.data as { hmac: string };
    return hmacData.hmac;
  }

  async isAdmin(): Promise<boolean> {
    try {
      const user = this.auth.currentUser;
      if (!user) {
        return false;
      }
      const idTokenResult = await user.getIdTokenResult(true);
      return idTokenResult.claims.admin === true;
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    }
  }
}
