import React, { useState, useEffect } from "react";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import { TeamSubscription } from "../../models/TeamSubscription";
import { formatDate } from "../../utils/DateUtils";
import TeamDetailModal from "./TeamDetail";

interface TeamsSectionProps {}

export const TeamsSection: React.FC<TeamsSectionProps> = () => {
  const teamService = useTeams();
  const [teams, setTeams] = useState<Team[]>([]);
  const [subscriptions, setSubscriptions] = useState<TeamSubscription[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const [fetchedTeams, fetchedSubscriptions] = await Promise.all([
        teamService.teamRepo.getList(teamService.teamPath(), {
          name: "createdAt",
          descending: true,
        }),
        teamService.teamSubscriptionRepo.getList(
          teamService.teamSubscriptionPath()
        ),
      ]);
      setTeams(fetchedTeams);
      setSubscriptions(fetchedSubscriptions);
    };
    loadData();
  }, []);

  const subscription = (team: Team): TeamSubscription | undefined => {
    return subscriptions.find((sub) => sub.id === team.id);
  };

  const handleRowClick = (team: Team) => {
    setSelectedTeam(team);
  };

  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Plan
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Created at
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {teams.map((team) => (
            <tr
              key={team.id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleRowClick(team)}
            >
              <td className="px-6 py-4 whitespace-nowrap">{team.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {subscription(team)?.currentEvent?.name || "No active plan"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{`${formatDate(
                team.createdAt
              )}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <TeamDetailModal
        team={selectedTeam}
        subscription={selectedTeam ? subscription(selectedTeam) ?? null : null}
        onClose={() => setSelectedTeam(null)}
      />
    </>
  );
};
