import React from "react";

import { Portal } from "../../../models/Portal";
import { CommonModal } from "../../Common/CommonModal";
import { formatDate, formatTime } from "../../../utils/DateUtils";
import Icon from "../../Icon";
import { ExternalPath } from "../../../models/ExternalPath";
import AnimatedButton from "../../AnimatedButton";

interface APIModalProps {
  portal: Portal;
  setShows: (shows: boolean) => void;
  shows: boolean;
}

export const APIModal: React.FC<APIModalProps> = ({
  portal,
  setShows,
  shows,
}) => {
  const handleDismiss = () => {
    setShows(false);
  };

  if (!shows) return null;

  return (
    <CommonModal isOpen={shows} onDismiss={handleDismiss}>
      <div className="bg-white rounded-lg gap-4 flex flex-col shadow-lg p-6 max-w-[544px]">
        <div className="flex justify-end text-gray-200 hover:text-gray-300">
          <button onClick={handleDismiss}>
            <Icon type="x" />
          </button>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex justify-between text-xs text-gray-500 gap-2">
            <span> Published</span>
            <span>{formatDate(portal.currentVersionData?.modifiedAt)}</span>
            <span>{formatTime(portal.currentVersionData?.modifiedAt)}</span>
          </div>
          <div
            id="portalId"
            className="flex justify-between text-xs text-gray-500 gap-2"
          >
            {`Portal ID: ${portal.id}`}
          </div>
        </div>
        <div className="text-center flex flex-col gap-4 mt-4 mb-4">
          {portal.currentVersionData ? (
            <>
              <div className="flex flex-col gap-1 justify-center items-center">
                <div className="text-gray-500 text-xs ">{portal.name}</div>
              </div>
              <div className="font-gooper text-2xl font-semibold text-gray-800">
                <span>{`${portal.currentVersionData.name} is `}</span>{" "}
                <span className="text-green-500 underline">live</span>
              </div>
              <div className="font-normal text-gray-500">
                <span>This version is now avaiable via the</span>{" "}
                <span className="text-gray-500 underline">Portal API</span>
                <span>
                  , and will be powering all new requests made to Portal ID
                </span>{" "}
                <span className="text-gray-500 underline">{portal.id}</span>
                <span>.</span>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-1 justify-center items-center">
                <div className="text-gray-500 text-xs ">{portal.name}</div>
              </div>
              <div className="font-gooper text-2xl font-semibold text-gray-800">
                <span>{`Almost there!`}</span>{" "}
              </div>
              <div className="font-normal text-gray-500">
                <span>
                  Publish a version to make this portal reachable via the
                </span>{" "}
                <span className="text-gray-500 underline">Portal API </span>
                <span> and the Portal ID </span>{" "}
                <span className="text-gray-500 underline">{portal.id}</span>
                <span>.</span>
              </div>
            </>
          )}

          <a
            href={ExternalPath.portalDocs()}
            target="_blank"
            className="text-blue-400 hover:text-blue-500 underline mt-2"
          >
            Click here to learn how to integrate Easybeam into your apps.
          </a>
        </div>

        <AnimatedButton
          title={`Dismiss`}
          onClick={handleDismiss}
          buttonState={"ready"}
          style={"normal"}
          key="cancel"
          font="font-sans"
          id="dismissButton"
        />
      </div>
    </CommonModal>
  );
};
