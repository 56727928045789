import { ReactNode, useState } from "react";
import { Workflow, WorkflowVersion } from "../../../models/Workflow";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { SelectableNodeData } from "../Map/WorkflowMap";
import { Team } from "../../../models/Team";
import { ChatConfigurationTemplate } from "../../../models/ChatConfigurationTemplate";
import { useWorkflowConverter } from "../../../contexts/WorkflowConverterContext";
import { ReactFlowState } from "reactflow";
import ProblemList from "./Problems/ProblemList";
import { WorkflowConversionProblem } from "../../../services/WorkflowConverter";
import { StartNodeData, StartNodeType } from "../Map/Nodes/StartNode";
import { PortalNodeType } from "../Map/Nodes/PortalNode";
import { DecisionNodeType } from "../Map/Nodes/DecisionNode";
import { SidebarDecisionDetail } from "./Decision/SidebarDecisionDetail";
import { WebsiteNodeType } from "../Map/Nodes/WebsiteNode";
import { SidebarWebsiteDetail } from "./Detail/SidebarWebsiteDetail";
import { SearchNodeType } from "../Map/Nodes/SearchNode";
import { SidebarSearchDetail } from "./Detail/SidebarSearchDetail";
import { WorkflowNodeType } from "../Map/Nodes/WorkflowNode";
import { SidebarWorkflowDetail } from "./Detail/SidebarWorkflowDetail";
import { SidebarPortalContainer } from "./Portal/SidebarPortalContainer";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { SidebarWorkflowMetaContainer } from "./Meta/SidebarWorkflowMetaContainer";
import { WorkflowDemoModal } from "./Meta/WorkflowDemoModal";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useReactFlow, useStore } from "reactflow";
import Icon from "../../Icon";
import { WorkflowActionMenu } from "./WorkflowActionMenu";
import { WorkflowNameModal } from "./WorkflowNameModal";

export const Sidebar: React.FC<{
  workflow: Workflow;
  setWorkflow: (workflow: Workflow) => void;
  workflowVersion: WorkflowVersion;
  setWorkflowVersion: (workflow: WorkflowVersion) => void;
  team: Team;
  setTeam: (team: Team) => void;
  selectedNodeData: SelectableNodeData | undefined;
  templates: ChatConfigurationTemplate[];
  workflows: Workflow[];
}> = ({
  workflow,
  team,
  setWorkflow,
  setTeam,
  selectedNodeData,
  templates,
  workflows,
  workflowVersion,
  setWorkflowVersion,
}) => {
  const converter = useWorkflowConverter();
  const workflowService = useWorkflowService();
  const [publishState, setPublishState] = useState<AnimationState>("ready");
  const [demoState, setDemoState] = useState<AnimationState>("ready");
  const [problems, setProblems] = useState<WorkflowConversionProblem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const [showMenu, setShowMenu] = useState(false);

  const showNameModal = searchParams.get("showNameModal") === "true";

  const handleSetShowNameModal = (show: boolean) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (show) {
      newSearchParams.set("showNameModal", "true");
    } else {
      newSearchParams.delete("showNameModal");
    }
    setSearchParams(newSearchParams, { replace: true });
  };

  const setSessionId = (sessionId: string | undefined) => {
    setSearchParams(
      sessionId
        ? {
            sessionId,
          }
        : {}
    );
  };

  // Use useStore to subscribe to nodes and edges
  const nodes = useStore((state: ReactFlowState) =>
    Array.from(state.nodeInternals.values())
  );
  const edges = useStore((state: ReactFlowState) =>
    Array.from(state.edges.values())
  );

  // Use useReactFlow to get setNodes and setEdges
  const { setNodes } = useReactFlow();

  const updateNodes = (
    updates: { [id: string]: { data: any; general: any } },
    generalUpdates?: any
  ) => {
    const ids = Object.keys(updates);
    setNodes((nds) =>
      nds.map((node) => {
        if (ids.includes(node.id)) {
          return {
            ...node,
            data: { ...node.data, ...updates[node.id].data },
            ...updates[node.id].general,
          };
        } else {
          return { ...node, ...generalUpdates };
        }
      })
    );
  };

  const handlePublish = async () => {
    setPublishState("loading");
    const result = converter.getSteps(nodes, edges, team, false);
    if (result.type === "issue") {
      const nodeData: { [id: string]: any } = {};
      result.problems.forEach((p) => {
        nodeData[p.nodeId] = { data: { hasProblem: true } };
      });
      updateNodes(nodeData);
      setProblems(result.problems);
      setPublishState("error");
      return;
    }

    try {
      const newWorkflowVersion: WorkflowVersion = {
        ...workflowVersion,
        steps: result.steps,
        firstStepId: result.firstStepId,
        startNodeId: result.startNodeId,
      };
      const newWorkflow: Workflow = {
        ...workflow,
        currentVersionData: newWorkflowVersion,
        currentVersionId: newWorkflowVersion.id,
      };
      await Promise.all([
        workflowService.workflowRepo.set(
          newWorkflow,
          workflowService.workflowPath(team.id!),
          workflow.id!
        ),
        workflowService.workflowVersionRepo.set(
          newWorkflowVersion,
          workflowService.workflowVersionPath(team.id!, workflow.id!),
          workflowVersion.id!
        ),
      ]);
      setWorkflow(newWorkflow);
      setPublishState("success");
    } catch (e) {
      setPublishState("error");
    }
  };

  const currentSection = (): ReactNode => {
    if (selectedNodeData?.type === StartNodeType) {
      return (
        <SidebarWorkflowMetaContainer
          version={workflowVersion}
          setVersion={setWorkflowVersion}
          team={team}
          setTeam={setTeam}
          data={selectedNodeData}
        />
      );
    } else if (selectedNodeData?.type === PortalNodeType) {
      return (
        <SidebarPortalContainer
          workflow={workflow}
          team={team}
          data={selectedNodeData}
          templates={templates}
          setTeam={setTeam}
          version={workflowVersion}
        />
      );
    } else if (selectedNodeData?.type === DecisionNodeType) {
      return (
        <SidebarDecisionDetail
          data={selectedNodeData}
          version={workflowVersion}
        />
      );
    } else if (selectedNodeData?.type === WebsiteNodeType) {
      return <SidebarWebsiteDetail data={selectedNodeData} />;
    } else if (selectedNodeData?.type === SearchNodeType) {
      return <SidebarSearchDetail data={selectedNodeData} />;
    } else if (selectedNodeData?.type === WorkflowNodeType) {
      return (
        <SidebarWorkflowDetail
          data={selectedNodeData}
          workflow={workflow}
          workflows={workflows}
          team={team}
        />
      );
    } else {
      return <div className="font-gooper 2xl">{`Goodbye!`}</div>;
    }
  };

  const collapse = selectedNodeData === undefined;

  const startNodeData = (): StartNodeData | undefined => {
    const start = nodes.find((n) => n.id === StartNodeType);
    return start?.data;
  };

  const updateStartNodeData = (data: StartNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...data } };
        }
        return node;
      })
    );
  };

  const handleRunDemo = async () => {
    const result = converter.getSteps(nodes, edges, team, true);
    if (result.type === "conversion") {
      const newWorkflowVersion: WorkflowVersion = {
        ...workflowVersion,
        demoFirstStepId: result.firstStepId,
        demoSteps: result.steps,
        demoStartNodeId: result.startNodeId,
      };
      setDemoState("loading");
      setWorkflowVersion(newWorkflowVersion);
      await workflowService.workflowVersionRepo.update(
        newWorkflowVersion,
        workflowService.workflowVersionPath(team.id!, workflow.id!),
        workflowVersion.id!
      );
      setDemoState("success");
      setSessionId(uuidv4());
    } else {
      setProblems(result.problems);
    }
  };

  const data = startNodeData();

  return (
    <div
      className={`${
        collapse ? "-mr-[454px]" : ""
      } w-[454px] min-w-[454px] max-w-[454px] h-full  bg-gray-0 border-gray-200 border flex flex-col gap-4 p-4 transition-all overflow-y-scroll`}
    >
      <WorkflowNameModal
        teamId={team.id!}
        workflow={workflow}
        shows={showNameModal}
        setShows={handleSetShowNameModal}
        setWorkflow={setWorkflow}
      />

      {data && (
        <WorkflowDemoModal
          setShows={() => setSessionId(undefined)}
          shows={sessionId != undefined}
          workflow={workflow}
          sessionId={sessionId ?? ""}
          setSessionId={setSessionId}
          team={team}
          variables={data.demoVariables ?? {}}
          version={workflowVersion}
          setVariables={(demoVariables) => {
            updateStartNodeData({ ...data, demoVariables });
            // reload
          }}
        />
      )}

      <div className="flex flex-col gap-2 ">
        <AnimatedButton
          buttonState={publishState}
          setButtonState={setPublishState}
          title="Publish"
          onClick={handlePublish}
          style="action"
          font="font-general-sans font-medium"
          leftIcon="workflows"
        />
        <div className="flex flex-row w-full gap-2 relative">
          <AnimatedButton
            title="Demo Workflow"
            buttonState={demoState}
            setButtonState={setDemoState}
            onClick={handleRunDemo}
            style="secondary"
            font="font-sans font-medium"
            leftIcon="play"
            classNameIn="w-full"
          />
          <button
            id="actionMenuButton"
            className="bg-blue-50 hover:bg-blue-100 transition-all duration-200 w-12 rounded-lg items-center justify-center flex"
            onClick={() => setShowMenu(true)}
          >
            <Icon type="dots-horizontal" />
          </button>
          {workflow && workflowVersion && (
            <WorkflowActionMenu
              setShows={setShowMenu}
              shows={showMenu}
              workflow={workflow}
              version={workflowVersion}
              teamId={team.id!}
              isLive={false}
              setWorkflowVersion={setWorkflowVersion}
              setWorkflow={setWorkflow}
              setShowNameModal={handleSetShowNameModal}
            />
          )}
        </div>
      </div>

      <div className="border-t h-1 w-full border-gray-200 pt-2" />

      {currentSection()}
      <div className="absolute w-96 h-fit left-0 top-0">
        <ProblemList
          problems={problems}
          onDismiss={(problem) =>
            setProblems([...problems.filter((p) => p.id !== problem.id)])
          }
          clearAll={() => {
            setProblems([]);
          }}
          onTap={(problem) => {
            setProblems([...problems.filter((p) => p.id !== problem.id)]);
            updateNodes(
              {
                [problem.nodeId]: {
                  data: { hasProblem: false },
                  general: { selected: true },
                },
              },
              { selected: false }
            );
          }}
        />
      </div>
    </div>
  );
};
