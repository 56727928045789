import React, { useState } from "react";

interface CommonSwitchProps {
  isOn?: boolean;
  onToggle?: (isOn: boolean) => void;
  disabled?: boolean;
  ariaLabel?: string;
}

const CommonSwitch: React.FC<CommonSwitchProps> = ({
  isOn = false,
  onToggle,
  disabled = false,
  ariaLabel = "Toggle switch",
}) => {
  const [isChecked, setIsChecked] = useState(isOn);

  const handleToggle = () => {
    if (!disabled) {
      const newState = !isChecked;
      setIsChecked(newState);
      onToggle?.(newState);
    }
  };

  return (
    <button
      className={`relative w-16 h-8  rounded-full border transition-colors duration-200 ease-in-out ${
        isChecked ? "bg-blue-500 border-blue-600" : "bg-gray-50 border-gray-200"
      } ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
      onClick={handleToggle}
      disabled={disabled}
      aria-checked={isChecked}
      role="switch"
      aria-label={ariaLabel}
    >
      <span
        className={`block w-7 h-7 rounded-full transition-transform duration-200 ease-in-out transform ${
          isChecked ? "translate-x-8 bg-white" : "translate-x-0 bg-gray-200"
        }`}
      />
    </button>
  );
};

export default CommonSwitch;
