import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSave } from "react-icons/ai";
import { ChatMessage } from "../../models/ChatMessage";
import { AIProviderLogo } from "../Portals/Portal Detail/AIProvider/AiProviderLogo";

export const EditableChatBubble: React.FC<{
  teamName: string;
  message: ChatMessage;
  loading: boolean;
  isLast: boolean;
  id?: string;
  onDelete: (id: string) => void;
  onUpdate: (updatedMessage: ChatMessage) => void;
}> = ({ message, teamName, loading, isLast, id, onDelete, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(message.content);
  const [editedRole, setEditedRole] = useState(message.role);

  const isAi = editedRole === "AI";
  const senderName = isAi ? teamName : "User";
  const className = isAi ? "bg-gray-0 shadow" : "";

  const handleSave = () => {
    setIsEditing(false);
    const updatedMessage = {
      ...message,
      content: editedContent,
      role: editedRole,
    };
    onUpdate(updatedMessage);
  };

  return (
    <div
      id={id}
      className={`flex flex-col p-4 gap-2 w-full rounded-2xl ${className}`}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-2 items-center">
          {isEditing ? (
            <select
              value={editedRole}
              onChange={(e) => setEditedRole(e.target.value as "AI" | "USER")}
              className="text-xs font-gooper text-gray-600"
            >
              <option value="User">User</option>
              <option value="AI">AI</option>
            </select>
          ) : (
            <div
              className={`text-xs font-gooper ${
                isAi ? "text-gray-500 font-medium" : "text-gray-600"
              }`}
            >
              {senderName}
            </div>
          )}
          {isAi && message.providerId && (
            <AIProviderLogo
              providerId={message.providerId}
              className="fill-gray-500 bg-gray-100 rounded-lg p-1 h-6 w-12"
              id={`${id}_${message.providerId}`}
            />
          )}
        </div>
        {isAi && (
          <div className={`text-xs text-gray-400`}>
            {`Cost: ${message.cost ?? 0} USD`}
          </div>
        )}
        <div className="flex gap-2">
          {isEditing ? (
            <AiOutlineSave
              className="cursor-pointer text-gray-600"
              onClick={handleSave}
            />
          ) : (
            <AiOutlineEdit
              className="cursor-pointer text-gray-600"
              onClick={() => setIsEditing(true)}
            />
          )}
          <AiOutlineDelete
            className="cursor-pointer text-gray-600"
            onClick={() => onDelete(message.id)}
          />
        </div>
      </div>

      {isEditing ? (
        <textarea
          className="w-full text-sm text-gray-900 border rounded p-2"
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
        />
      ) : (
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <span
            className={`text-sm ${isAi ? "text-slate-900" : "text-gray-900"}`}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {message.content}
            {loading && isLast && <TextAnimation />}
          </span>
        </div>
      )}
    </div>
  );
};

const TextAnimation: React.FC = () => {
  return (
    <svg
      width="45"
      height="10"
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      style={{
        display: "inline",
        verticalAlign: "top",
        marginLeft: "4px",
      }}
    >
      <circle cx="15" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="60" cy="15" r="9" fillOpacity="0.9">
        <animate
          attributeName="r"
          from="9"
          to="9"
          begin="0s"
          dur="0.8s"
          values="9;15;9"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="0.5"
          to="0.5"
          begin="0s"
          dur="0.8s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;9;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};
