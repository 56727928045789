import { Checkbox } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import Icon, { IconType } from "../Icon";

export interface CheckFilterItem {
  name: string;
  id: string;
  selected: boolean;
}
interface CheckFilterParams {
  leftIcon: IconType;
  typeName: string;
  typeNamePlural: string;
  items: CheckFilterItem[];
  updatedItem: (item: CheckFilterItem) => void;
  singleSelect?: boolean;
}

const CheckFilter: React.FC<CheckFilterParams> = ({
  typeName,
  typeNamePlural,
  items,
  updatedItem,
  leftIcon,
  singleSelect,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleClick = () => {
    setShowDropdown(true);
  };

  const filterTitle = `Filter by ${typeName}`;
  let labelTitle = `${typeNamePlural}`;
  const selected = items.filter((i) => i.selected);
  if (selected.length > 0) {
    labelTitle = selected.map((i) => i.name).join(", ");
  }

  return (
    <div className="flex gap-2 flex-col " ref={wrapperRef}>
      <button
        onClick={() => handleClick()}
        className="w-full py-2 px-3 relative bg-gray-100 border border-gray-200 rounded flex justify-between items-center cursor-pointer"
      >
        {showDropdown && (
          <div className="absolute flex flex-col p-2 top-0 w-full text-left border-1 border-gray-300 bg-gray-50 right-0 z-50 rounded-md shadow-lg">
            <div className="text-gray-500 py-2 text-sm font-medium">
              {filterTitle}
            </div>
            {items.map((item) => {
              return (
                <div
                  className="flex gap-2 py-1 flex-row w-f cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    updatedItem(item);
                    if (singleSelect) {
                      setShowDropdown(false);
                      console.log("closing");
                    }
                  }}
                  key={item.id}
                >
                  <Checkbox
                    className="text-blue-500 cursor-pointer focus:ring-0"
                    id="remember-social"
                    required
                    checked={item.selected}
                    readOnly={true}
                  />
                  <div className="text-gray-500 text-sm font-gooper">
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex flex-row gap-1 items-center w-full">
          <Icon type={leftIcon} className="text-gray-500 size-4" />
          <span className="text-gray-500 font-gooper text-sm truncate">
            {labelTitle}
          </span>
        </div>

        <Icon type="chevron" className="rotate-180 text-gray-500" />
      </button>
    </div>
  );
};

export default CheckFilter;
