import React from "react";

interface SecretsSectionProps {
  secrets: { [key: string]: boolean };
  onChange: (secrets: { [key: string]: boolean }) => void;
}

const SecretsSection: React.FC<SecretsSectionProps> = ({
  secrets,
  onChange,
}) => {
  const addSecret = () => {
    onChange({
      ...secrets,
      [`secret${Object.keys(secrets).length + 1}`]: false,
    });
  };

  return (
    <div>
      <h4 className="font-medium mb-2">Secrets</h4>
      {Object.entries(secrets).map(([key, value]) => (
        <div key={key} className="mb-2 flex items-center">
          <input
            className="flex-grow p-2 border rounded mr-2"
            value={key}
            onChange={(e) => {
              const newSecrets = { ...secrets };
              delete newSecrets[key];
              newSecrets[e.target.value] = value;
              onChange(newSecrets);
            }}
            placeholder="Secret Key"
          />
          <input
            type="checkbox"
            checked={value}
            onChange={(e) =>
              onChange({
                ...secrets,
                [key]: e.target.checked,
              })
            }
          />
        </div>
      ))}
      <button
        className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={addSecret}
      >
        Add Secret
      </button>
    </div>
  );
};

export default SecretsSection;
