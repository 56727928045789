import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { ChartData, Metric } from "./AnalyticsChart";

interface AnalyticsLineChartProps {
  chartData: ChartData[];
  versionIds: string[];
  metric: Metric;
  hexColors: string[];
}

export const AnalyticsLineChart: React.FC<AnalyticsLineChartProps> = ({
  chartData,
  versionIds,
  hexColors,
  metric,
}) => {
  const { ticks, tickFormatter, tooltipFormatter } =
    useDateFormatting(chartData);

  return (
    <ResponsiveContainer id={`${metric}LineChart`} width="100%" height={200}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          type="number"
          scale="time"
          domain={["dataMin", "dataMax"]}
          ticks={ticks}
          tickFormatter={tickFormatter}
          tick={<CustomXAxisTick />}
        />
        <YAxis tick={<CustomYAxisTick />} axisLine={false} tickLine={false} />
        <Tooltip labelFormatter={tooltipFormatter} />
        {versionIds.map((versionId, index) => (
          <Line
            key={versionId}
            type="monotone"
            dataKey={versionId}
            stroke={hexColors[index % hexColors.length]}
            dot={{
              r: 2,
              fill: hexColors[index % hexColors.length],
            }}
            strokeWidth={2}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

const useDateFormatting = (data: ChartData[]) => {
  if (data.length < 2) {
    return {
      ticks: [],
      tickFormatter: (_: number) => "",
      tooltipFormatter: (_: number) => "",
    };
  }

  const startDate = new Date(data[0].date);
  const endDate = new Date(data[data.length - 1].date);
  const totalHours =
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
  const MAX_TICKS = 12; // Increased from 6 to allow for more granular display

  // Calculate optimal tick spacing based on time range
  const generateSmartTicks = () => {
    if (totalHours <= 24) {
      // Less than a day - show all hours
      // Create an array of all possible hour timestamps between start and end
      const allHourTicks: number[] = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        allHourTicks.push(currentDate.getTime());
        currentDate.setHours(currentDate.getHours() + 1);
      }

      // Ensure the last timestamp is included
      if (allHourTicks[allHourTicks.length - 1] !== endDate.getTime()) {
        allHourTicks.push(endDate.getTime());
      }

      return allHourTicks;
    } else if (totalHours <= 72) {
      // Less than 3 days - show dates with selected hours
      const step = Math.max(1, Math.floor(totalHours / (MAX_TICKS - 1)));
      const ticks: number[] = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        ticks.push(currentDate.getTime());
        currentDate.setHours(currentDate.getHours() + step);
      }

      // Ensure the last timestamp is included
      if (ticks[ticks.length - 1] !== endDate.getTime()) {
        ticks.push(endDate.getTime());
      }

      return ticks;
    } else {
      // More than 3 days - show only dates
      const uniqueDays = new Set(
        data.map((d) => new Date(d.date).toDateString())
      );
      const step = Math.max(1, Math.floor(uniqueDays.size / (MAX_TICKS - 1)));
      const dailyData = data.filter(
        (d, i) =>
          i === 0 || i === data.length - 1 || new Date(d.date).getHours() === 12
      );

      const ticks = dailyData
        .filter((_, index) => index % step === 0)
        .map((d) => d.date);

      // Ensure the last timestamp is included
      if (ticks[ticks.length - 1] !== endDate.getTime()) {
        ticks.push(endDate.getTime());
      }

      return ticks;
    }
  };

  const formatTick = (timestamp: number) => {
    const date = new Date(timestamp);
    if (totalHours <= 24) {
      // Less than a day - show hour only
      return date.toLocaleTimeString([], {
        hour: "numeric",
        hour12: true,
      });
    } else if (totalHours <= 72) {
      // Less than 3 days - show date and hour
      return `${date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
      })} ${date.toLocaleTimeString([], {
        hour: "numeric",
        hour12: true,
      })}`;
    } else {
      // More than 3 days - show only date
      return date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
      });
    }
  };

  const formatTooltip = (timestamp: number) => {
    const date = new Date(timestamp);
    if (totalHours <= 72) {
      // For shorter time ranges, show full detail
      return date.toLocaleString([], {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else {
      // For longer ranges, show date with hour
      return date.toLocaleString([], {
        month: "short",
        day: "numeric",
        hour: "numeric",
        hour12: true,
      });
    }
  };

  return {
    ticks: generateSmartTicks(),
    tickFormatter: formatTick,
    tooltipFormatter: formatTooltip,
  };
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-10}
        textAnchor="end"
        fill="#666"
        fontSize={14}
        fontFamily="Inter, sans-serif"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomXAxisTick = (props: any) => {
  const { x, y, payload, tickFormatter } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontSize={14}
        fontFamily="Inter, sans-serif"
      >
        {tickFormatter(payload.value)}
      </text>
    </g>
  );
};
