import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import FailureModal from "../FailureModal";
import { usePortalService } from "../../contexts/PortalContext";
import AnalyticsChart, { ChartType } from "./AnalyticsChart";
import CheckFilter, { CheckFilterItem } from "../Notifications/CheckFilter";
import { useTeams } from "../../contexts/TeamContext";
import { CommonSpinner } from "../Common/CommonLoading";
import { filterExamplePortals } from "../../utils/FilterUtils";
import { Portal } from "../../models/Portal";

export type Timespan = "7-Days" | "30-Days" | "60-Days";

export interface AnalyticsData {
  [versionId: string]: { stats: VersionStats | undefined; date: Date }[];
}

export const Analytics: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const portalId = searchParams.get("portal");
  const versionIds = searchParams.getAll("versions");
  const timespan = (searchParams.get("time") as Timespan) || "7-Days";
  const teamService = useTeams();

  const [error, setError] = useState("");
  const portalService = usePortalService();

  const [portals, setPortals] = useState<Portal[]>([]);
  const [portalFilterItems, setPortalFilterItems] = useState<CheckFilterItem[]>(
    []
  );
  const [versionFilterItems, setVersionFilterItems] = useState<
    CheckFilterItem[]
  >([]);

  const timespans: Timespan[] = ["7-Days", "30-Days", "60-Days"];
  const [timespanFilterItems, setTimespanFilterItems] = useState<
    CheckFilterItem[]
  >(
    timespans.map((t) => ({
      id: t,
      name: t,
      selected: t === "7-Days",
    }))
  );

  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>();
  const [loadingPortals, setLoadingPortals] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const [durationType, setDurationType] = useState<ChartType>("line");
  const [scoreType, setScoreType] = useState<ChartType>("line");
  const [errorRateType, setErrorRateType] = useState<ChartType>("line");
  const [costType, setCostType] = useState<ChartType>("line");
  const [latencyType, setLatencyType] = useState<ChartType>("line");
  const [requestCountType, setRequestCountType] = useState<ChartType>("line");

  // Fetch portals once when the component mounts
  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const team = await teamService.teamRepo.get(
          teamService.teamPath(),
          teamId!
        );
        const fetchedPortals = await portalService.portalRepo.getList(
          portalService.portalPath(teamId!),
          { name: "modifiedAt", descending: true }
        );
        const filteredPortals = fetchedPortals.filter((p) =>
          filterExamplePortals(team ?? undefined, p)
        );

        setPortals(filteredPortals);

        if (!portalId && filteredPortals.length > 0) {
          const mostRecentPortal = filteredPortals[0];
          setSearchParams({ portal: mostRecentPortal.id });

          setPortalFilterItems(
            filteredPortals.map((p) => ({
              name: p.name,
              id: p.id,
              selected: p.id === mostRecentPortal.id,
            }))
          );
        } else {
          setPortalFilterItems(
            filteredPortals.map((p) => ({
              name: p.name,
              id: p.id,
              selected: portalId === p.id,
            }))
          );
        }
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      } finally {
        setLoadingPortals(false);
      }
    };

    fetchPortals();
  }, [teamId, portalService]);

  // Fetch versions when portalId changes
  useEffect(() => {
    const fetchVersions = async () => {
      if (!portalId) return;

      try {
        const versions = await portalService.portalVersionRepo.getList(
          portalService.portalVersionPath(teamId!, portalId),
          { name: "modifiedAt", descending: true }
        );

        let selectedVersionIds = versionIds;

        if (versionIds.length < 1) {
          const portal = portals.find((p) => p.id === portalId);

          let defaultVersions = versions.filter(
            (v) => v.id !== portal?.currentVersionId
          );

          if (portal?.currentVersionData) {
            defaultVersions = [portal.currentVersionData]
              .concat(defaultVersions)
              .slice(0, 3);
          } else {
            defaultVersions = versions.slice(0, 3);
          }
          selectedVersionIds = defaultVersions.map((v) => v.id ?? "");

          setSearchParams({
            portal: portalId,
            versions: selectedVersionIds,
          });
        }

        setVersionFilterItems(
          versions.map((v) => ({
            name: v.name,
            id: v.id ?? "",
            selected: selectedVersionIds.includes(v.id ?? ""),
          }))
        );
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };

    fetchVersions();
  }, [portalId, teamId, portalService]);

  // Fetch analytics data
  useEffect(() => {
    const fetchAnalyticsData = async () => {
      if (
        !portalId ||
        versionIds.length === 0 ||
        loadingData ||
        analyticsData
      ) {
        return;
      }

      setLoadingData(true);
      try {
        const daysAgo = Number(timespan.split("-")[0]);
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - daysAgo);

        const stats = await portalService.portalStatsRepo.getList(
          portalService.portalStatsPath(teamId!, portalId),
          { name: "date", descending: true },
          [{ key: "date", filter: ">", value: startDate }]
        );

        const newAnalyticsData: AnalyticsData = versionIds.reduce(
          (acc, versionId) => {
            acc[versionId] = stats.map((s) => {
              return { stats: s.versionStats[versionId], date: s.date };
            });
            return acc;
          },
          {} as AnalyticsData
        );

        setAnalyticsData(newAnalyticsData);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      } finally {
        setLoadingData(false);
      }
    };

    fetchAnalyticsData();
  }, [portalId, versionIds, teamId, portalService, timespan]);

  const updatePortalFilter = (item: CheckFilterItem) => {
    setSearchParams({ portal: item.id });
    portalFilterItems.forEach((i) => (i.selected = false));
    item.selected = !item.selected;
    setPortalFilterItems([...portalFilterItems]);
    setAnalyticsData(undefined);
  };

  const updateVersionFilter = (item: CheckFilterItem) => {
    item.selected = !item.selected;
    const allSelected = versionFilterItems
      .filter((i) => i.selected)
      .map((i) => i.id);
    setVersionFilterItems([...versionFilterItems]);
    setSearchParams(() => ({
      portal: portalId!,
      versions: allSelected,
    }));
  };

  const updateTimespanFilter = (item: CheckFilterItem) => {
    timespanFilterItems.forEach((i) => (i.selected = false));
    item.selected = !item.selected;
    setTimespanFilterItems([...timespanFilterItems]);
    setSearchParams(() => ({
      portal: portalId!,
      versions: versionIds,
      time: item.id,
    }));
    setAnalyticsData(undefined);
  };

  return (
    <CommonContainer>
      <CommonHeader
        title="Analytics"
        subtitle="Insights on your portals based on their logs & reviews. The best version is out there somewhere."
        sections={[{ name: "Analytics", link: `/teams/${teamId}/analytics` }]}
        teamId={teamId!}
        actions={[]}
      />
      <FailureModal
        shows={error !== ""}
        message={error}
        closed={() => setError("")}
      />
      <div className="grid grid-cols-3 gap-4 pt-2">
        <CheckFilter
          typeName="Portal"
          typeNamePlural="All Portals"
          items={portalFilterItems}
          updatedItem={updatePortalFilter}
          leftIcon="portals"
          singleSelect={true}
        />
        <CheckFilter
          typeName="Version"
          typeNamePlural="All Versions"
          items={versionFilterItems}
          updatedItem={updateVersionFilter}
          leftIcon="portals"
        />
        <CheckFilter
          typeName="Date Range"
          typeNamePlural="Dates"
          items={timespanFilterItems}
          updatedItem={updateTimespanFilter}
          leftIcon="clock"
        />
      </div>

      {loadingPortals && <CommonSpinner />}
      {!loadingPortals && !portalId && (
        <div className="font-gooper text-lg text-gray-700">
          Select a portal to get started
        </div>
      )}
      {!loadingPortals && portalId && (
        <div>
          {loadingData && <CommonSpinner />}
          {!loadingData && analyticsData && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4">
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric="requestCount"
                timespan={timespan}
                type={requestCountType}
                setType={setRequestCountType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric="score"
                timespan={timespan}
                type={scoreType}
                setType={setScoreType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric="latency"
                timespan={timespan}
                type={latencyType}
                setType={setLatencyType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric="duration"
                timespan={timespan}
                type={durationType}
                setType={setDurationType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric="cost"
                timespan={timespan}
                type={costType}
                setType={setCostType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric="errors"
                timespan={timespan}
                type={errorRateType}
                setType={setErrorRateType}
              />
            </div>
          )}
        </div>
      )}
    </CommonContainer>
  );
};
