import React, { useState } from "react";
import AnimatedButton, {
  AnimatedButtonStyle,
  AnimationState,
} from "../AnimatedButton";
import { IconType } from "../Icon";

interface CommonConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmStyle?: AnimatedButtonStyle;
  confirmIcon?: IconType;
  confirmTitle?: string;
  confirmState?: AnimationState;
}

export const CommonConfirmationModal: React.FC<
  CommonConfirmationModalProps
> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  confirmStyle,
  confirmIcon,
  confirmTitle,
  confirmState,
}) => {
  if (!isOpen) return null;

  const [confirmStateInternal, setConfirmState] =
    useState<AnimationState>("ready");

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50"
      onClick={(e) => e.stopPropagation()} // Prevent click from propagating to the background
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-2xl font-bold mb-4 font-gooper text-center text-gray-900">
          {title}
        </h2>
        <p className="mb-6 text-center text-gray-500">{message}</p>
        <div className="grid grid-cols-2 w-full gap-2">
          <AnimatedButton
            title={`Cancel`}
            onClick={() => onCancel()}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
          />
          <AnimatedButton
            title={confirmTitle ?? `Confirm`}
            onClick={() => onConfirm()}
            buttonState={confirmState ?? confirmStateInternal}
            setButtonState={setConfirmState}
            style={confirmStyle ?? "action"}
            key="action"
            leftIcon={confirmIcon}
            font="font-sans"
            id="confirmButton"
          />
        </div>
      </div>
    </div>
  );
};
