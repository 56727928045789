import React, { useEffect, useState } from "react";
import GetStartedCard from "./GetStartedCard";
import { useTeams } from "../../contexts/TeamContext";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS } from "../../utils/StorageKeys";

interface GetStartedTeamsProps {
  teamId: string;
}

const GetStartedTeams: React.FC<GetStartedTeamsProps> = ({ teamId }) => {
  const teamService = useTeams();
  const navigate = useNavigate();

  const [completed, setCompleted] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_MEMBERS) == "true"
  );

  const [loading, setLoading] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_MEMBERS) != "true"
  );

  const handleTeamAction = () => {
    setCompleted(!completed);
    localStorage.setItem(STORAGE_KEYS.COMPLETED_MEMBERS, "true");
    navigate(`/teams/${teamId}/settings/members`);
  };

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEYS.COMPLETED_MEMBERS) == "true") {
      return;
    }
    const loadData = async () => {
      const team = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId
      );
      const members = team?.members ?? {};
      const memberCount = Object.keys(members).length;
      const completed =
        memberCount > 1 || localStorage.getItem("completedTeams") == "true";
      setCompleted(completed);
      setLoading(false);
      if (completed) {
        localStorage.setItem(STORAGE_KEYS.COMPLETED_MEMBERS, "true");
      }
    };
    loadData();
  }, [teamService]);

  return (
    <GetStartedCard
      sectionTitle="Team"
      actionTitle="Invite team"
      title="Invite your team"
      subtitle="Don't waste those empty seats"
      number="2"
      iconType="users"
      completed={completed}
      completedMessage="Well Done"
      action={() => handleTeamAction()}
      loading={loading}
    />
  );
};

export default GetStartedTeams;
