import React, { useState } from "react";
import { InputTypes } from "../../../models/ChatConfigurationTemplate";
import ModelInput from "./ModelInput";
import SliderInput from "./SliderInput";
import MaxLengthSliderInput from "./MaxLengthSliderInput";
import ArrayInput from "./ArrayInput";

interface InputFieldProps {
  inputKey: string;
  input: InputTypes;
  onChange: (newKey: string, updatedInput: InputTypes) => void;
  onDelete: () => void;
}

const InputField: React.FC<InputFieldProps> = ({
  inputKey,
  input,
  onChange,
  onDelete,
}) => {
  const [isEditingId, setIsEditingId] = useState(false);
  const [editedId, setEditedId] = useState(inputKey);

  const changeInputType = (newType: InputTypes["input"]) => {
    let newInput: InputTypes;

    switch (newType) {
      case "model":
        newInput = {
          description: input.description,
          defaultValue: "",
          values: [],
          name: input.name,
          input: "model",
          number: input.number,
          shortDescription: input.shortDescription,
        };
        break;
      case "slider":
        newInput = {
          description: input.description,
          minValue: 0,
          maxValue: 100,
          defaultValue: 50,
          name: input.name,
          rangeLabels: [],
          shortDescription: input.shortDescription,
          input: "slider",
          number: input.number,
        };
        break;
      case "maxLengthSlider":
        newInput = {
          description: input.description,
          name: input.name,
          shortDescription: input.shortDescription,
          input: "maxLengthSlider",
          number: input.number,
        };
        break;
      case "array":
        newInput = {
          description: input.description,
          defaultValue: [],
          name: input.name,
          shortDescription: input.shortDescription,
          input: "array",
          number: input.number,
        };
        break;
      default:
        throw new Error(`Unsupported input type: ${newType}`);
    }

    onChange(inputKey, newInput);
  };

  const handleIdChange = () => {
    if (editedId !== inputKey) {
      onChange(editedId, input);
    }
    setIsEditingId(false);
  };

  const renderInputFields = () => {
    switch (input.input) {
      case "model":
        return (
          <ModelInput
            input={input}
            onChange={(updatedInput) => onChange(inputKey, updatedInput)}
          />
        );
      case "slider":
        return (
          <SliderInput
            input={input}
            onChange={(updatedInput) => onChange(inputKey, updatedInput)}
          />
        );
      case "maxLengthSlider":
        return (
          <MaxLengthSliderInput
            input={input}
            onChange={(updatedInput) => onChange(inputKey, updatedInput)}
          />
        );
      case "array":
        return (
          <ArrayInput
            input={input}
            onChange={(updatedInput) => onChange(inputKey, updatedInput)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-4 p-4 border rounded">
      <div className="flex justify-between items-center mb-2">
        {isEditingId ? (
          <input
            className="p-1 border rounded"
            value={editedId}
            onChange={(e) => setEditedId(e.target.value)}
            onBlur={handleIdChange}
            onKeyPress={(e) => e.key === "Enter" && handleIdChange()}
          />
        ) : (
          <span
            onClick={() => setIsEditingId(true)}
            className="cursor-pointer hover:underline"
          >
            ID: {inputKey}
          </span>
        )}
        <button
          className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={onDelete}
        >
          Delete
        </button>
      </div>
      <select
        className="w-full p-2 border rounded mb-2"
        value={input.input}
        onChange={(e) => changeInputType(e.target.value as InputTypes["input"])}
      >
        <option value="model">Model</option>
        <option value="slider">Slider</option>
        <option value="maxLengthSlider">Max Length Slider</option>
        <option value="array">Array</option>
      </select>
      {renderInputFields()}
    </div>
  );
};

export default InputField;
